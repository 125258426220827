import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, CircularProgress } from "@mui/material";
import TabMenu from "./TabMenu.tsx";
import RichTextEditor from "./RichTextEditor.tsx";
import { fetchContent } from "../../../redux/features/contentManagement/contentSlice.tsx";
import { RootState } from "../../../redux/store.tsx";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import EditIcon from "@mui/icons-material/Edit";

const PrivacyPolicy: React.FC = () => {
  const dispatch = useAppDispatch();
  const { content, isLoading } = useSelector(
    (state: RootState) => state.content
  );

  const [isEditing, setIsEditing] = useState(false);
  const [editorValue, setEditorValue] = useState<string>("");

  useEffect(() => {
    dispatch(fetchContent(1)); // Fetch content with type 1 on component mount
  }, [dispatch]);

  useEffect(() => {
    setEditorValue(content); // Update editor value when content changes
  }, [content]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false); // Set isEditing to false when cancel is clicked
  };
  const handleSaveSuccess = () => {
    setIsEditing(false); // Set isEditing to false after successful save
  };

  return (
    <div className="py-6 px-9">
      <TabMenu selectedTab="/static-content/privacy-policy" />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="mt-6 border rounded-lg shadow h-fit">
          <div className="flex justify-between items-center bg-[#E3F7F9] p-4 h-16 rounded-t-lg">
            <h2 className="font-bold text-2xl text-[#004135]">
              Privacy Policy
            </h2>
            {!isEditing && !isLoading && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#3BC1CD",
                  color: "#FFF",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                onClick={handleEditClick}
                disabled={isLoading}
              >
                <EditIcon /> <span className="mx-2">Edit</span>
              </Button>
            )}
          </div>
          <div className="p-4">
            {isEditing ? (
              <RichTextEditor
                value={editorValue}
                onCancel={handleCancel}
                placeholder="Enter privacy policy details"
                type={1}
                onSaveSuccess={handleSaveSuccess}
              />
            ) : (
              <div
                className="text-gray-600 htmlContent"
                dangerouslySetInnerHTML={{ __html: editorValue }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
