import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";

interface ContentState {
  content: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: ContentState = {
  content: "",
  isLoading: false,
  error: null,
};

// Thunk to fetch content based on type
export const fetchContent = createAsyncThunk(
  "content/fetchContent",
  async (type: number, thunkAPI) => {
    try {
      const response = await apiClient.get(`/content-management?type=${type}`);
      return response.data.data.content;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch content");
    }
  }
);

// Thunk to update content
export const updateContent = createAsyncThunk(
  "content/updateContent",
  async ({ type, content }: { type: number; content: string }, thunkAPI) => {
    try {
      const response = await apiClient.patch("/content-management", {
        type,
        content,
      });
      return response.data; // Return the entire response
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to update content");
    }
  }
);

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.content = action.payload;
      })
      .addCase(fetchContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updateContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.statusCode === 200) {
          state.content = action.payload.data.content;
        }
      })
      .addCase(updateContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default contentSlice.reducer;
