import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import {
  addFAQ,
  updateFAQ,
  FAQ,
} from "../../../redux/features/faq/faqSlice.tsx";
import {
  ContentState,
  convertFromHTML,
  EditorState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";

interface FAQEditorProps {
  faq: FAQ | null;
  onCancel: () => void;
}

const FAQEditor: React.FC<FAQEditorProps> = ({ faq, onCancel }) => {
  const dispatch = useAppDispatch();

  // Separate states for question and answer editors
  const [questionEditorState, setQuestionEditorState] = useState(
    EditorState.createEmpty()
  );
  const [answerEditorState, setAnswerEditorState] = useState(
    EditorState.createEmpty()
  );
  // Add these state variables to track initial content
  const [initialQuestionContent, setInitialQuestionContent] = useState("");
  const [initialAnswerContent, setInitialAnswerContent] = useState("");

  useEffect(() => {
    if (faq) {
      const questionContentState = ContentState.createFromBlockArray(
        convertFromHTML(faq.questionEn).contentBlocks
      );
      const answerContentState = ContentState.createFromBlockArray(
        convertFromHTML(faq.answerEn).contentBlocks
      );

      setQuestionEditorState(
        EditorState.createWithContent(questionContentState)
      );
      setAnswerEditorState(EditorState.createWithContent(answerContentState));

      // Set initial content for comparison
      setInitialQuestionContent(
        draftToHtml(convertToRaw(questionContentState))
      );
      setInitialAnswerContent(draftToHtml(convertToRaw(answerContentState)));
    }
  }, [faq]);

  // Determine if content has changed or if fields are empty
  const isQuestionChanged =
    draftToHtml(convertToRaw(questionEditorState.getCurrentContent())) !==
    initialQuestionContent;
  const isAnswerChanged =
    draftToHtml(convertToRaw(answerEditorState.getCurrentContent())) !==
    initialAnswerContent;
  const isAnyFieldEmpty =
    !questionEditorState.getCurrentContent().hasText() ||
    !answerEditorState.getCurrentContent().hasText();

  // Determine if the button should be disabled
  const isButtonDisabled =
    (!isQuestionChanged && !isAnswerChanged) || isAnyFieldEmpty;

  // Handle changes for question editor
  const handleQuestionEditorChange = (editorState: EditorState) => {
    setQuestionEditorState(editorState);
  };

  // Handle changes for answer editor
  const handleAnswerEditorChange = (editorState: EditorState) => {
    setAnswerEditorState(editorState);
  };

  const handlePublish = async () => {
    const questionHtml = draftToHtml(
      convertToRaw(questionEditorState.getCurrentContent())
    );
    const answerHtml = draftToHtml(
      convertToRaw(answerEditorState.getCurrentContent())
    );

    const newFaq = {
      type: 2,
      questionEn: questionHtml,
      answerEn: answerHtml,
    };

    try {
      const resultAction = await dispatch(addFAQ(newFaq));
      if (addFAQ.fulfilled.match(resultAction)) {
        onCancel(); // Close editor on success
        toast.success("FAQ Published successfully");
      }
    } catch (error) {
      console.error("Error adding FAQ:", error);
    }
  };

  const handleUpdate = async () => {
    if (!faq?.contentId) return;

    const questionHtml = draftToHtml(
      convertToRaw(questionEditorState.getCurrentContent())
    );
    const answerHtml = draftToHtml(
      convertToRaw(answerEditorState.getCurrentContent())
    );

    const updatedFaq = {
      ...faq,
      questionEn: questionHtml,
      answerEn: answerHtml,
    };

    try {
      const resultAction = await dispatch(updateFAQ(updatedFaq));
      if (updateFAQ.fulfilled.match(resultAction)) {
        onCancel(); // Close editor on success
        toast.success("FAQ updated successfully");
      }
    } catch (error) {
      console.error("Error updating FAQ:", error);
    }
  };

  return (
    <div className="border rounded-lg shadow">
      <div className="flex justify-between items-center bg-[#E3F7F9] p-4 h-16 rounded-t-lg">
        <h1 className="font-bold text-2xl text-[#004135]">
          {faq?.contentId ? "Edit FAQ" : "Add New FAQ"}
        </h1>
      </div>
      <div className="p-4">
        <div className="mb-4">
          <label className="block mb-2 font-bold">Add Question</label>
          <Editor
            editorState={questionEditorState}
            onEditorStateChange={handleQuestionEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbar={{
              options: ["inline", "list"],
              inline: { options: ["bold", "italic", "underline"] },
              list: { options: ["unordered"] },
            }}
            placeholder={"Enter a question"}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold">Add Answer</label>
          <Editor
            editorState={answerEditorState}
            onEditorStateChange={handleAnswerEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbar={{
              options: ["inline", "list"],
              inline: { options: ["bold", "italic", "underline"] },
              list: { options: ["unordered"] },
            }}
            placeholder={"Enter answer"}
          />
        </div>
        <div className="flex justify-between">
          <Button
            variant="outlined"
            onClick={onCancel}
            style={{
              color: "#111",
              border: "1px solid #C4C4C4",
              borderRadius: "10px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={faq?.contentId ? handleUpdate : handlePublish}
            disabled={isButtonDisabled} // Disable button if no changes or fields are empty
            style={{
              backgroundColor: isButtonDisabled ? "#C4C4C4" : "#3BC1CD", // Change color based on disabled state
              color: "#FFF",
              borderRadius: "10px",
              cursor: isButtonDisabled ? "not-allowed" : "pointer", // Change cursor based on disabled state
            }}
          >
            {faq?.contentId ? "Update" : "Publish"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FAQEditor;
