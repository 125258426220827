import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../dataTable/index.ts"; // Correct import
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import {
  fetchReservationsDetails,
  fetchReservations,
} from "../../../redux/features/reservations/reservationsSlice.tsx";
import { AppDispatch, RootState } from "../../../redux/store";
import { FetchReservationsParams } from "../../../redux/features/reservations/types.tsx";
import dayjs from "dayjs";
import { useAppSelector } from "../../../hooks/useAppSelector.tsx";
import CommonFilter from "../../commonComponents/CommonFilter.tsx";

interface FilterParams {
  selectedStatuses: string[];
  fromDate?: string;
  toDate?: string;
}

const Reservations = () => {
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [sortedColumn, setSortedColumn] = useState<string>("");
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // Fetch reservations from Redux state
  const {
    reservations: reservationData,
    loading,
    totalCount,
    error,
  } = useAppSelector((state: RootState) => state.reservations);

  const reservationListData = reservationData?.data.data;

  const [filterParams, setFilterParams] = useState<FilterParams>({
    selectedStatuses: [],
    fromDate: undefined,
    toDate: undefined,
  });

  const reservationStatusOptions = [
    { value: "Confirmed", label: "Confirmed" },
    { value: "Unconfirmed", label: "Unconfirmed" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  const handlePaginationModelChange = async (model: {
    page: number;
    pageSize: number;
  }) => {
    const newPage = model.page + 1;
    const newPageSize = model.pageSize;

    setPage(newPage);
    setPageSize(newPageSize);

    const fetchParams: FetchReservationsParams = {
      page: newPage,
      limit: newPageSize,
      searchKey: searchKey || undefined,
      status: filterParams.selectedStatuses,
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchReservations(fetchParams));
  };

  const handleFilterSubmit = async (filters: FilterParams) => {
    setFilterParams(filters);

    const fetchParams: FetchReservationsParams = {
      page: 1,
      limit: pageSize,
      searchKey: searchKey || undefined,
      status: filters.selectedStatuses,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchReservations(fetchParams));
    setPage(1);
  };

  // Transform users with proper typing
  const rows = useMemo(() => {
    return reservationListData?.map((reservation, index) => ({
      ...reservation,
      id: reservation.reservationId,
      serialNumber: (page - 1) * pageSize + index + 1, // Calculate the serial number
    }));
  }, [reservationListData, page, pageSize]); // This will update whenever fetchedUsers changes

  useEffect(() => {
    const fetchParams: FetchReservationsParams = {
      page: 1,
      limit: pageSize,
    };
    dispatch(fetchReservations(fetchParams));
  }, []);

  const handleSearch = async (newSearchKey: string) => {
    setSearchKey(newSearchKey);
    setPage(1); // Reset to first page when searching

    // Make API call with current filters and new search key
    const fetchParams: FetchReservationsParams = {
      page: 1,
      limit: pageSize,
      searchKey: newSearchKey || undefined,
      status: filterParams.selectedStatuses,
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchReservations(fetchParams));
  };

  const handleNameClick = (row) => {
    dispatch(fetchReservationsDetails(row.reservationId)).then(() => {
      navigate(`/reservation-details/${row.reservationId}`, {
        state: { reservationId: row.reservationId },
      });
    });
  };

  const handleHeaderIconClick = (columnField: string) => {
    const nextSortOrder =
      columnField === sortedColumn
        ? sortOrder === "ASC"
          ? "DESC"
          : sortOrder === "DESC"
          ? ""
          : "ASC"
        : "ASC";

    setSortedColumn(nextSortOrder === "" ? "" : columnField);
    setSortOrder(nextSortOrder as "ASC" | "DESC" | "");

    // Create properly typed sort parameters
    const sortParams: Partial<FetchReservationsParams> = nextSortOrder
      ? {
          sortBy: columnField,
          sortOrder: nextSortOrder as "ASC" | "DESC",
        }
      : {};

    dispatch(
      fetchReservations({
        page,
        limit: pageSize,
        ...sortParams,
        searchKey: searchKey || undefined,
      })
    );
  };

  const getSortIcon = (
    columnField: string,
    sortedColumn: string,
    sortOrder: string
  ) => {
    if (columnField === sortedColumn) {
      if (sortOrder === "ASC") return <NorthIcon />;
      if (sortOrder === "DESC") return <SouthIcon />;
    }
    return <ImportExportIcon />;
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S.no",
      flex: isMobileScreen ? 1 : 0.5,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 80 : undefined,
      resizable: false,
      valueGetter: (params) => params, // Use the calculated serial number
      renderCell: (params) => <span>{params.row.serialNumber}</span>,
    },
    {
      field: "id",
      headerName: "Reservation Id",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 150 : undefined,
      resizable: false,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Reservation Id</span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleHeaderIconClick("reservationId");
            }}
            aria-label="sort-toggle"
            size="small"
            sx={{
              color:
                sortedColumn === "reservationId" && sortOrder !== ""
                  ? "black"
                  : "black",
              "&:hover": {
                backgroundColor: "#3bc1cd",
                color: "white",
              },
            }}
          >
            {getSortIcon("reservationId", sortedColumn, sortOrder)}
          </IconButton>
        </div>
      ),
    },
    {
      field: "unitTypeCode",
      headerName: "Unit Name",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 200 : undefined,
      resizable: false,
      renderCell: (params) => {
        const value = params.value || "--NA--"; // Show "--NA--" if value is falsy (null, undefined, or empty string)
        return (
          <span
            style={{
              textAlign: "center",
              textDecoration: "underline",
              fontSize: "14px",
              fontWeight: "700",
              cursor: "pointer",
              color: "#3BC1CD",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={() => handleNameClick(params.row)}
          >
            {value}
          </span>
        );
      },
    },
    {
      field: "property",
      headerName: "Property",
      flex: isMobileScreen ? 2 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 150 : undefined,
      resizable: false,
    },
    {
      field: "occupierNameId",
      headerName: "Occupier Name Id",
      flex: isMobileScreen ? 2 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 120 : undefined,
      resizable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 130 : undefined,
      resizable: false,
      renderCell: (params) => {
        const value = params.value || "--NA--"; // Show "--NA--" if value is falsy (null, undefined, or empty string)

        return <span>{value}</span>;
      },
    },
    {
      field: "guests",
      headerName: "Total Guests",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 200 : undefined,
      resizable: false,
      renderCell: (params) => (
        <span>{`${params.row.guests.adults} Adults, ${params.row.guests.children} Children`}</span>
      ),
    },
    {
      field: "reservationStatus",
      headerName: "Status",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 100 : undefined,
      resizable: false,
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "24px",
            padding: "4px 8px",
            backgroundColor: "rgba(169, 237, 243, 0.3)",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "created",
      headerName: "Created Date",
      flex: isMobileScreen ? 2 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 250 : 300,
      valueGetter: (params) => {
        return params;
      },
      renderCell: (params) => {
        try {
          if (!params.value) return "";
          // Format the date using dayjs
          const formattedDate = dayjs(params.value).format("MMM DD, YYYY");
          return formattedDate;
        } catch (error) {
          console.error("Error formatting date:", error);
          return params.value; // Return raw value if formatting fails
        }
      },
    },
  ];

  return (
    <div className="p-6">
   
      <DataTable
        rows={rows || []}
        columns={columns}
        searchPlaceholder={"Search by Reservation Id, Name Id or Property"}
        onSearch={handleSearch}
        filterable
        filterComponent={
          <CommonFilter
            statusOptions={reservationStatusOptions}
            isMobileScreen={isMobileScreen}
            onFilterSubmit={handleFilterSubmit}
            currentFilters={filterParams}
          />
        }
        loading={loading}
        // Pagination props
        paginationModel={{
          page: page - 1, // Convert to 0-based for DataGrid
          pageSize,
        }}
        pageSizeOptions={[10, 25, 50]}
        rowCount={totalCount || 0}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default Reservations;
