// import React from 'react';
// import PhoneInput from 'react-phone-input-2';
// // import { CallIcon } from 'lucide-react';
// import CallIcon from '@mui/icons-material/Call';


// // Note: You'll need to import the CSS in your main stylesheet or component:
// // import 'react-phone-input-2/lib/style.css'

// const PhoneInputField = ({ value, onChange, className }) => {
//   const handleChange = (value, country) => {
//     if (onChange) {
//       // Format to match your existing onChange handler
//       onChange({
//         target: {
//           value: `+${value}`
//         }
//       });
//     }
//   };

//   // Remove the '+' if present for the PhoneInput value
//   const inputValue = value ? value.replace(/^\+/, '') : '';

//   return (
//     <div className="relative">
//       <CallIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400 z-10" />
//       <PhoneInput
//         country={'us'} // Default country
//         value={inputValue}
//         onChange={handleChange}
//         containerClass={`${className} phone-input-container`}
//         inputClass="phone-input-field"
//         buttonClass="phone-input-dropdown"
//         inputProps={{
//           className: "pl-10" // Add padding to accommodate the icon
//         }}
//         // Styling to match your existing design
//         containerStyle={{
//           width: '100%'
//         }}
//         inputStyle={{
//           width: '100%',
//           height: '40px',
//           fontSize: '16px',
//           paddingLeft: '45px', // Space for the CallIcon
//           backgroundColor: 'white',
//           border: '1px solid #E1E0E0',
//           borderRadius: '8px'
//         }}
//         buttonStyle={{
//           border: 'none',
//           backgroundColor: 'transparent',
//           padding: '0 8px'
//         }}
//       />
//     </div>
//   );
// };

// export default PhoneInputField;
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CallIcon from '@mui/icons-material/Call';

const PhoneInputField = ({ value, onChange }) => {
  const handleChange = (value, country) => {
    if (onChange) {
      onChange({
        target: {
          value: `+${value}`
        }
      });
    }
  };

  // Remove the '+' if present for the PhoneInput value
  const inputValue = value ? value.replace(/^\+/, '') : '';

  return (
    <div className="relative phone-input-wrapper">
      <PhoneInput
        country={'us'}
        value={inputValue}
        onChange={handleChange}
        enableSearch
        searchPlaceholder="Search country..."
        inputProps={{
          required: true,
        }}
        containerStyle={{
          width: '100%',
        }}
        inputStyle={{
          width: '100%',
          height: '56px',
          fontSize: '16px',
          backgroundColor: 'white',
          border: '1px solid #E1E0E0',
          borderRadius: '8px'
        }}
        buttonStyle={{
          border: '1px solid #E1E0E0',
          borderRight: 'none',
          backgroundColor: 'white',
          borderRadius: '8px 0 0 8px',
        }}
        dropdownStyle={{
          width: '300px',
          maxHeight: '300px',
          borderRadius: '8px',
          border: '1px solid #E1E0E0',
        }}
      />
    </div>
  );
};

export default PhoneInputField;