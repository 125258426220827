import React from "react";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();

  // Navigation to sigin
  const handleSigninClick = () => {
    navigate("/signin");
  };
  return (
    <nav
      className=" p-4 flex justify-between items-center "
      style={{
        boxShadow: "0px 2px 4px 0px #DADADA",
        marginBottom: "2px",
      }}
    >
      <div className="text-lg font-bold">
        <img
          onClick={handleSigninClick}
          src="/assets/Aria-Logo.png"
          className="h-10 pl-9 cursor-pointer"
          alt="Aria Logo"
        />
      </div>
    </nav>
  );
};

export default Navbar;
