import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";

interface ImageUploadResponse {
  preSignedUrl: string;
  image: string;
}

interface ImageState {
  images: Record<string, string>;
  isLoading: boolean;
  error: any;
  deleteStatus: string;
  deleteError: any;
}

export const uploadImage = createAsyncThunk(
  "imageManagement/uploadImage",
  async (
    {
      file,
      fileName,
      mimeType,
    }: {
      file: File;
      fileName: string;
      mimeType: string;
    },
    { rejectWithValue }
  ) => {
    try {
      // Step 1: Get pre-signed URL
      const queryParams = new URLSearchParams({
        fileName,
        mimeType,
      });

      const presignedResponse = await apiClient.get(
        `/admin/pre-signed-url?${queryParams}`
      );

      const { preSignedUrl, image } = presignedResponse.data.data;

      // Step 2: Upload to S3 using presigned URL
      const uploadResponse = await axios.put(preSignedUrl, file, {
        headers: {
          "Content-Type": mimeType,
        },
      });
      if (uploadResponse.status === 200) {
        return { fileName, imageUrl: image };
      } else {
        return rejectWithValue("Upload failed");
      }
    } catch (error: any) {
      console.error("Upload error:", error);
      return rejectWithValue(error.response?.data || "Upload failed");
    }
  }
);

export const updateImageInfo = createAsyncThunk(
  "imageManagement/updateImageInfo",
  async (
    {
      imageUrl,
      slug,
      screenName,
    }: {
      imageUrl: string;
      slug: string;
      screenName?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const payload = {
        image: imageUrl,
        slug,
        ...(screenName && { screenName }),
      };

      const response = await apiClient.patch("/admin/image", payload);
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue("Update failed");
      }
    } catch (error: any) {
      console.error("Update error:", error);
      return rejectWithValue(error.response?.data || "Update failed");
    }
  }
);

// Create an async thunk for deleting an image
export const deleteImage = createAsyncThunk(
  "imageManagement/deleteImage",
  async (imageUrl: string, { rejectWithValue }) => {
    try {
      // Include the image URL as a query parameter
      const response = await apiClient.delete(
        `/admin/image?image=${encodeURIComponent(imageUrl)}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const uploadToS3 = createAsyncThunk(
//   "imageManagement/uploadToS3",
//   async (
//     { file, presignedUrl }: { file: File; presignedUrl: string },
//     { rejectWithValue }
//   ) => {
//     try {
//       const response = await axios.put(presignedUrl, file, {
//         headers: {
//           "Content-Type": file.type,
//         },
//       });
//       if (response.status === 200) {
//         return { success: true };
//       } else {
//         return rejectWithValue("Upload failed");
//       }
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// Slice
const imageManagementSlice = createSlice({
  name: "imageManagement",
  initialState: {
    images: {},
    isLoading: false,
    error: null,
    deleteStatus: "idle",
    deleteError: null,
  } as ImageState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Image URL
      .addCase(uploadImage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.images = action.payload.imageUrl;
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateImageInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateImageInfo.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateImageInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteImage.pending, (state) => {
        state.isLoading = true;
        state.deleteStatus = "loading";
      })
      .addCase(deleteImage.fulfilled, (state) => {
        state.isLoading = false;

        state.deleteStatus = "succeeded";
        state.deleteError = null;
      })
      .addCase(deleteImage.rejected, (state, action) => {
        state.isLoading = false;
        state.deleteStatus = "failed";
        state.deleteError = action.payload;
      });
  },
});

export default imageManagementSlice.reducer;
