// testimonialCreationSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../../utils/apiClient.tsx";

interface TestimonialFormData {
  title: string;
  name: string;
  nameId: number;
  propertyId: number;
  propertyName: string;
  source: string;
  rating: number;
  description: string;
}

interface TestimonialCreationState {
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: TestimonialCreationState = {
  loading: false,
  error: null,
  success: false,
};

export const createTestimonial = createAsyncThunk(
  "testimonial/create",
  async (data: TestimonialFormData, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("/testimonial-management/create", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "An error occurred");
    }
  }
);

const testimonialCreationSlice = createSlice({
  name: "testimonialCreation",
  initialState,
  reducers: {
    resetTestimonialCreation: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTestimonial.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(createTestimonial.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(createTestimonial.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.success = false;
      });
  },
});

export const { resetTestimonialCreation } = testimonialCreationSlice.actions;
export default testimonialCreationSlice.reducer;