import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Tabs, Tab, Box, CircularProgress } from "@mui/material";
import axios from "axios";
import {
  uploadImage,
  updateImageInfo,
  deleteImage,
} from "../../../redux/features/imageManagement/imageManagementSlice.tsx";
import { AppDispatch, RootState } from "../../../redux/store.tsx";

import { useDispatch, useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import { useAppSelector } from "../../../hooks/useAppSelector.tsx";
import { fetchImages } from "../../../redux/features/imageManagement/getUploadedImagesSlice.tsx";
import { toast } from "react-toastify";

// const tutorialTabs = ["Screen 1", "Screen 2", "Screen 3"];
const tutorialTabs = ["Screen 1", "Screen 2"];

const ImageManagement: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedTutorialTab, setSelectedTutorialTab] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null); // For displaying the image
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadResult, setUploadResult] = useState<{ imageUrl: string } | null>(
    null
  );
  const [isUploading, setIsUploading] = useState(false); // New state for upload loader

  const dispatch = useAppDispatch();
  const { imagesList, isLoading } = useAppSelector(
    (state: RootState) => state.imageList
  );

  useEffect(() => {
    dispatch(fetchImages());
  }, [dispatch]);

  // State to hold image URLs for each section
  const [welcomeImage, setWelcomeImage] = useState<string>(
    "/assets/resort-demo.png"
  );
  const [tutorialImages, setTutorialImages] = useState<string[]>([
    "/assets/resort-demo.png",
    "/assets/resort-demo.png",
    "/assets/resort-demo.png",
  ]);
  const [homeBackdropImage, setHomeBackdropImage] = useState<string>(
    "/assets/resort-demo.png"
  );
  const [knowMoreImage, setKnowMoreImage] = useState<string>(
    "/assets/resort-demo.png"
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleTutorialTabChange = (
    _: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedTutorialTab(newValue);
  };

  const handleUploadClick = (title: string) => {
    setModalTitle(title);
    setIsModalOpen(true);
    setSelectedFile(null);
    setFilePreview(null);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/svg+xml",
        "image/webp",
      ];
      const maxSize = 5 * 1024 * 1024;
console.log(file.type,"filetypeeee")
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only JPEG, PNG, JPG, SVG and WEBP files are allowed");
        return;
      }

      if (file.size > maxSize) {
        toast.error("File size exceeds 5MB limit");
        return;
      }

      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first");
      return;
    }
    setIsUploading(true); // Start the upload process

    try {
      // Delete the existing image before uploading a new one
      const imageUrlToDelete = getImageUrlForModalTitle(modalTitle); // Implement this function to get the current image URL
      if (imageUrlToDelete) {
        await dispatch(deleteImage(imageUrlToDelete)).unwrap();
      }

      // Proceed with uploading the new image
      const result = await dispatch(
        uploadImage({
          file: selectedFile,
          fileName: selectedFile.name,
          mimeType: selectedFile.type,
        })
      ).unwrap();

      setUploadResult(result);
      let slug = modalTitle.toLowerCase().replace(/\s+/g, "-");
      const screenName = modalTitle.includes("Tutorials")
        ? tutorialTabs[selectedTutorialTab]
        : undefined;

      if (modalTitle.includes("Tutorials")) {
        slug = "tutorials";
      }

      await dispatch(
        updateImageInfo({
          imageUrl: result.imageUrl,
          slug,
          screenName,
        })
      ).unwrap();

      // Fetch images again after updating
      dispatch(fetchImages());

      const updatedImages = {
        welcomeImage,
        tutorialImages,
        homeBackdropImage,
        knowMoreImage,
        [modalTitle]: result.imageUrl,
      };

      localStorage.setItem("imageUrls", JSON.stringify(updatedImages));

      if (modalTitle === "welcome-screen") {
        setWelcomeImage(result.imageUrl);
      } else if (modalTitle.includes("Tutorials")) {
        const updatedTutorialImages = [...tutorialImages];
        updatedTutorialImages[selectedTutorialTab] = result.imageUrl;
        setTutorialImages(updatedTutorialImages);
      } else if (modalTitle === "home-backdrop") {
        setHomeBackdropImage(result.imageUrl);
      } else if (modalTitle === "know-more") {
        setKnowMoreImage(result.imageUrl);
      }

      toast.success("File uploaded successfully!");
      handleModalClose();
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("File upload failed");
    } finally {
      setIsUploading(false); // End the upload process
    }
  };

  const getImageUrlForModalTitle = (title: string): string | null => {
    switch (title) {
      case "welcome-screen":
        return welcomeImage;
      case "home-backdrop":
        return homeBackdropImage;
      case "know-more":
        return knowMoreImage;
      case `Tutorials - ${tutorialTabs[selectedTutorialTab]}`:
        return tutorialImages[selectedTutorialTab];
      default:
        return null;
    }
  };

  const handleUploadAreaClick = () => {
    fileInputRef.current?.click();
  };

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box sx={{ padding: "24px" }}>
      {/* Welcome Screen Section */}
      <Box mb={4}>
        <div className="flex justify-between items-center">
          <h2 className="text-[24px] font-normal text-[#004135]">
            Welcome Screen
          </h2>
          <Button
            variant="contained"
            style={{
              height: "fit-content",
              backgroundColor: "#3BC1CD",
              color: "#FFF",
            }}
            onClick={() => handleUploadClick("welcome-screen")}
          >
            Upload Image
          </Button>
        </div>

        <Box
          style={{
            width: "255px",
            height: "230px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FFF",
            marginTop: "31px",
            marginBottom: "31px",
          }}
        >
          {/* Image Section */}
          <Box
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <img
              src={
                imagesList["welcome-screen"]?.[0] || "/assets/resort-demo.png"
              }
              alt="Card Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Text Section */}
          <Box
            style={{
              padding: "12px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: 0,
                color: "#333",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Balqis Residence, Palm Jumeirah
            </p>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "#3BC1CD",
                fontWeight: "700",
              }}
            >
              Selected
            </p>
          </Box>
        </Box>
      </Box>
      <hr />

      {/* Tutorials Section */}
      <Box mb={4}>
        <div className="flex justify-between items-center">
          <h2 className="text-[24px] font-normal text-[#004135]">Tutorials</h2>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3BC1CD",
              color: "#FFF",
              marginTop: "10px",
            }}
            onClick={() =>
              handleUploadClick(
                `Tutorials - ${tutorialTabs[selectedTutorialTab]}`
              )
            }
          >
            Upload Image
          </Button>
        </div>
        <Tabs
          value={selectedTutorialTab}
          onChange={handleTutorialTabChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{
            style: { display: "none" }, // Hide the default indicator
          }}
          sx={{ marginTop: "10px" }}
        >
          {tutorialTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              style={{
                backgroundColor:
                  selectedTutorialTab === index ? "#3BC1CD" : "#FFF",
                color: selectedTutorialTab === index ? "#FFF" : "#000", // Change text color based on active/inactive
                border: "none",
                borderRadius: "8px",
                fontWeight: selectedTutorialTab === index ? "700" : "500",
                textTransform: "none", // Disable uppercase
                padding: "10px 16px",
                minWidth: "auto", // Prevent tabs from stretching
                marginRight: "12px",
                fontSize: "16px",
              }}
            />
          ))}
        </Tabs>
        <Box
          style={{
            width: "255px",
            height: "230px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FFF",
            marginTop: "31px",
            marginBottom: "31px",
          }}
        >
          {/* Image Section */}
          <Box
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <img
              src={
                imagesList["tutorials"]?.[selectedTutorialTab] ||
                "/assets/resort-demo.png"
              }
              alt="Card Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Text Section */}
          <Box
            style={{
              padding: "12px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: 0,
                color: "#333",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Balqis Residence, Palm Jumeirah
            </p>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "#3BC1CD",
                fontWeight: "700",
              }}
            >
              Selected
            </p>
          </Box>
        </Box>
      </Box>
      <hr />

      {/* Home Backdrop Section */}
      <Box mb={4}>
        <div className="flex justify-between items-center">
          <h2 className="text-[24px] font-normal text-[#004135]">
            Home Backdrop
          </h2>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3BC1CD",
              color: "#FFF",
              marginTop: "10px",
            }}
            onClick={() => handleUploadClick("home-backdrop")}
          >
            Upload Image
          </Button>
        </div>
        <Box
          style={{
            width: "255px",
            height: "230px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FFF",
            marginTop: "31px",
            marginBottom: "31px",
          }}
        >
          {/* Image Section */}
          <Box
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <img
              src={
                imagesList["home-backdrop"]?.[0] || "/assets/resort-demo.png"
              }
              alt="Card Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Text Section */}
          <Box
            style={{
              padding: "12px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: 0,
                color: "#333",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Balqis Residence, Palm Jumeirah
            </p>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "#3BC1CD",
                fontWeight: "700",
              }}
            >
              Selected
            </p>
          </Box>
        </Box>
      </Box>
      <hr />

      {/* Know More Section */}
      <Box mb={4}>
        <div className="flex justify-between items-center">
          <h2 className="text-[24px] font-normal text-[#004135]">Know More</h2>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#3BC1CD",
              color: "#FFF",
              marginTop: "10px",
            }}
            onClick={() => handleUploadClick("know-more")}
          >
            Upload Image
          </Button>
        </div>

        <Box
          style={{
            width: "255px",
            height: "230px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#FFF",
            marginTop: "31px",
            marginBottom: "31px",
          }}
        >
          {/* Image Section */}
          <Box
            style={{
              width: "100%",
              overflow: "hidden",
              borderRadius: "6px",
            }}
          >
            <img
              src={imagesList["know-more"]?.[0] || "/assets/resort-demo.png"}
              alt="Card Image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>

          {/* Text Section */}
          <Box
            style={{
              padding: "12px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: 0,
                color: "#333",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              Balqis Residence, Palm Jumeirah
            </p>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "#3BC1CD",
                fontWeight: "700",
              }}
            >
              Selected
            </p>
          </Box>
        </Box>
      </Box>

      {/* Modal for Upload */}
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FFF",
            padding: "20px",
            borderRadius: "10px",
          }}
          sx={{
            width: {
              xs: "350px",
              md: "612px",
            },
          }}
        >
          <h2 className="text-center text-[32px] font-bold text-[#3D3A3A] mb-7">
            {modalTitle}
          </h2>
          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=" image/jpg, image/jpeg, image/png, image/svg+xml, image/webp"
            onChange={handleFileSelect}
          />

          {/* File upload area */}
          <Box
            onClick={handleUploadAreaClick}
            style={{
              border: "2px dashed #C4C4C4",
              borderRadius: "10px",
              padding: "43px",
              backgroundColor: "#A9EDF34D",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {filePreview ? (
              <img
                src={filePreview}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <>
                <img src="/assets/fileUpload-icon.png" alt="upload icon" />
                <p className="text-sm font-bold "> Upload Image</p>
                <span className="text-xs font-normal text-[#3BC1CD] text-center">
                  Maximum size allowed: 5 MB,
                  <br /> Format supported: JPEG,PNG,JPG,SVG and WEBP only
                </span>
              </>
            )}
          </Box>
          <Box mt={3} display="flex" gap={1}>
            <Button
              variant="outlined"
              style={{ flex: "1" }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#3BC1CD",
                color: "#FFF",
                flex: "1",
                opacity: selectedFile ? 1 : 0.5,
              }}
              onClick={handleFileUpload}
              disabled={isUploading}
            >
              {isUploading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Confirm"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageManagement;
