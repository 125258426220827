// src/redux/features/reservations/reservationsSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ApiResponse,
  ReservationsDetailsResponse,
  FetchReservationsParams,
} from "./types.tsx";
import apiClient from "../../../utils/apiClient.tsx";

export const fetchReservations = createAsyncThunk(
  "reservations/fetchReservations",
  async ({
    searchKey,
    status,
    fromDate,
    toDate,
    sortBy,
    sortOrder,
    page,
    limit,
  }: FetchReservationsParams) => {
    const response = await apiClient.get<ApiResponse>(`/reservation/list`, {
      params: {
        searchKey,
        status,
        fromDate,
        toDate,
        sortBy,
        sortOrder,
        page,
        limit,
      },
    });
    return response.data;
  }
);

// Async thunk to fetch reservations details
export const fetchReservationsDetails = createAsyncThunk(
  "reservations/fetchReservationsDetails",
  async (reservationId: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<ReservationsDetailsResponse>(
        `/reservation/detail?reservationId=${reservationId}`
      );
      return response.data; // Assuming the response format
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          status: error.response.status,
          message:
            error.response.data.message || "Failed to fetch booking details",
        });
      }
      return rejectWithValue({ message: "An unexpected error occurred" });
    }
  }
);

const reservationsSlice = createSlice({
  name: "reservations",
  initialState: {
    reservations: null as ApiResponse | null,
    reservationsDetails: null as ReservationsDetailsResponse | null,
    loading: false,
    totalCount: 0,
    error: null as { status?: number; message: string } | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReservations.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = action.payload; // Store the full ApiResponse
        state.totalCount = action.payload?.data?.count;
      })
      .addCase(fetchReservations.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          message: action.error.message || "Failed to fetch reservations",
        };
      })
      .addCase(fetchReservationsDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reservationsDetails = null;
      })
      .addCase(fetchReservationsDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.reservationsDetails = action.payload;
      })
      .addCase(fetchReservationsDetails.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload as { status: number; message: string };
        } else {
          state.error = {
            message:
              action.error.message || "Failed to fetch Reservation details",
          };
        }
      });
  },
});

export default reservationsSlice.reducer;
