import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import apiClient from '../../../utils/apiClient.tsx';

interface TestimonialDetails {
  testimonialId: number;
  name: string;
  title: string;
  description: string;
  propertyName: string;
  status: string;
  createdAt: string;
}

interface TestimonialDetailsState {
  details: TestimonialDetails | null;
  loading: boolean;
  error: string | null;
}

const initialState: TestimonialDetailsState = {
  details: null,
  loading: false,
  error: null,
};

export const fetchTestimonialDetails = createAsyncThunk(
  'testimonialDetails/fetchTestimonialDetails',
  async (testimonialId: number) => {
    const response = await apiClient.get(
      `/testimonial-management/detail?testimonialId=${testimonialId}`
    );
    return response.data.data;
  }
);

const testimonialDetailsSlice = createSlice({
  name: 'testimonialDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestimonialDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTestimonialDetails.fulfilled, (state, action: PayloadAction<TestimonialDetails>) => {
        state.details = action.payload;
        state.loading = false;
      })
      .addCase(fetchTestimonialDetails.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch testimonial details';
        state.loading = false;
      });
  },
});

export default testimonialDetailsSlice.reducer;