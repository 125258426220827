// src/redux/features/booking/bookingSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ApiResponse,
  BookingDetailsResponse,
  FetchBookingsParams,
} from "./types.tsx";
import apiClient from "../../../utils/apiClient.tsx";

export const fetchBookings = createAsyncThunk(
  "bookings/fetchBookings",
  async ({
    searchKey,
    status,
    fromDate,
    toDate,
    sortBy,
    sortOrder,
    page,
    limit,
  }: FetchBookingsParams) => {
    const response = await apiClient.get<ApiResponse>(
      `/reservation-request/list`,
      {
        params: {
          searchKey,
          status,
          fromDate,
          toDate,
          sortBy,
          sortOrder,
          page,
          limit,
        },
      }
    );
    return response.data;
  }
);

// Async thunk to fetch booking details
export const fetchBookingDetails = createAsyncThunk(
  "booking/fetchBookingDetails",
  async (bookingReqId: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<BookingDetailsResponse>(
        `/reservation-request/detail?bookingReqId=${bookingReqId}`
      );
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          status: error.response.status,
          message:
            error.response.data.message || "Failed to fetch booking details",
        });
      }
      return rejectWithValue({ message: "An unexpected error occurred" });
    }
  }
);

const bookingSlice = createSlice({
  name: "bookings",
  initialState: {
    bookings: null as ApiResponse | null,
    totalCount: 0,
    bookingDetails: null as BookingDetailsResponse | null,
    loading: false,
    error: null as { status?: number; message: string } | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBookings.fulfilled, (state, action) => {
        state.loading = false;
        state.bookings = action.payload; // Store the full ApiResponse
        state.totalCount = action.payload?.data?.count;
      })
      .addCase(fetchBookings.rejected, (state, action) => {
        state.loading = false;
        state.error = {
          message: action.error.message || "Failed to fetch bookings",
        };
      })
      .addCase(fetchBookingDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.bookingDetails = null;
      })
      .addCase(fetchBookingDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingDetails = action.payload;
      })
      .addCase(fetchBookingDetails.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload as { status: number; message: string };
        } else {
          state.error = {
            message: action.error.message || "Failed to fetch booking details",
          };
        }
      });
  },
});

export default bookingSlice.reducer;
