import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Switch,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormGroup,
  FormControlLabel,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  fetchUserDetails,
  updateUserStatus,
} from "../../../redux/features/userManagement/userManagementThunk.tsx";
import { RootState } from "../../../redux/store.tsx";
import CommonConfirmationModal from "../../commonComponents/CommonConfirmationModal.tsx";
import { fetchAutoDebitData } from "../../../redux/features/userManagement/autoDebitSlice.tsx";
import WarningIcon from "@mui/icons-material/Warning";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import { toast } from "react-toastify";

const UserDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { userDetails, loading, error } = useSelector(
    (state: RootState) => state.userDetails
  );

  // Getting the details of the user in the Variable
  const userData = userDetails?.user;
  const membershipData = userDetails?.membership;

  const [autoDebitData, setAutoDebitData] = useState<{
    autoDebit: boolean;
    cardType: string;
    isActive: boolean;
  } | null>(null);

  useEffect(() => {
    if (id) {
      const nameId = parseInt(id, 10);
      if (nameId) {
        dispatch(fetchUserDetails(nameId));
        dispatch(fetchAutoDebitData(nameId))
          .unwrap()
          .then((data) => {
            setAutoDebitData(data);
          })
          .catch((error) => {
            console.error("Failed to fetch auto debit data:", error);
          });
      }
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (error && error.status === 500) {
      navigate("/not-found");
    }
  }, [error, navigate]);

  const [showModal, setShowModal] = React.useState(false);
  const [isBlocking, setIsBlocking] = React.useState(false);
  const [updating, setUpdating] = React.useState(false); // New state for updating status

  const handleBlockUnblock = () => {
    if (userData) {
      setIsBlocking(userData.status === "ACTIVE");
      setShowModal(true);
    }
  };

  const handleModalConfirm = async () => {
    if (userData) {
      const newStatus = userData.status === "ACTIVE" ? "BLOCKED" : "ACTIVE";
      setUpdating(true);
      try {
        await dispatch(
          updateUserStatus({ nameId: userData?.nameId, status: newStatus })
        ).unwrap();
        toast.success(`User has been ${newStatus}`);
        // Fetch the updated user details
        await dispatch(fetchUserDetails(userData?.nameId));
      } catch (error) {
        console.error("Failed to update user status:", error);
      } finally {
        setUpdating(false); // Reset updating to false when the operation ends
      }
    }
    setShowModal(false);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleToggleChange = async () => {
    if (autoDebitData?.cardType === "REQUESTED") {
      try {
        // Dispatch the action to update the user status
        await dispatch(
          updateUserStatus({ nameId: userData?.nameId, autoDebit: false })
        ).unwrap();

        // Fetch the updated user details
        await dispatch(fetchUserDetails(userData?.nameId));

        setAutoDebitData((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              autoDebit: false,
              cardType: "UPDATED",
            };
          }
          return null; // Or handle this case differently if needed
        });

        toast.success("Auto debit status updated successfully.");
      } catch (error) {
        console.error("Failed to update auto debit status:", error);
      }
    } else {
      console.log("Cannot change auto debit status unless it is requested.");
    }
  };

  const isToggleDisabled = autoDebitData?.cardType !== "REQUESTED";
  const isTooltipVisible = autoDebitData?.cardType === "REQUESTED";

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  // if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ padding: "20px" }}>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          <Button
            variant="text"
            onClick={handleGoBack}
            sx={{ fontWeight: "normal" }}
          >
            <ArrowBackIosIcon />
          </Button>
          User Details
        </Typography>
        <Button
          variant="outlined"
          color={
            userData?.status === "BLOCKED"
              ? "success"
              : userData?.status === "ACTIVE"
              ? "error"
              : "primary" // Use a valid color when the user is inactive
          }
          onClick={handleBlockUnblock}
          sx={{ padding: "10px 50px" }}
          disabled={userData?.status === "INACTIVE"}
        >
          {userData?.status === "BLOCKED"
            ? "Unblock"
            : userData?.status === "ACTIVE"
            ? "Block"
            : "Inactive"}
        </Button>
      </Box>

      {/* Basic Information Section */}
      <Box
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: "10px",
          padding: "20px",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "24px", fontWeight: "400", color: "#3D3A3A" }}
          >
            Basic Information
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormGroup>
              <FormControlLabel
                required={false}
                disabled={isToggleDisabled}
                control={
                  <Switch
                    size={"medium"}
                    checked={autoDebitData?.autoDebit || false}
                    onChange={handleToggleChange}
                    sx={{ color: "#3BC1CD" }}
                  />
                }
                label={
                  <Box display="flex" alignItems="center">
                    Autodebit
                    {isTooltipVisible && (
                      <Tooltip title="User has requested to turn off auto debit">
                        <WarningIcon
                          sx={{ marginLeft: "5px", fontSize: "20px" }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                }
                labelPlacement={"start"}
              />
            </FormGroup>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            overflowX: "auto",
            "& > .MuiGrid-item": {
              flexShrink: 0, // Prevent items from shrinking
              maxWidth: "none", // Override default max-width constraints
            },
          }}
        >
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Name
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "20px", // 20px on extra small (mobile) screens
                  md: "24px", // 24px on medium screens and above
                },
                color: "#3D3A3A",
                fontWeight: "400",
              }}
            >
              {`${userData?.firstName || "N/A"} ${userData?.lastName || "N/A"}`}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Name Id
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "20px", // 20px on extra small (mobile) screens
                  md: "24px", // 24px on medium screens and above
                },
                color: "#3D3A3A",
                fontWeight: "400",
              }}
            >
              {userData?.nameId || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Phone Number
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "20px", // 20px on extra small (mobile) screens
                  md: "24px", // 24px on medium screens and above
                },
                color: "#3D3A3A",
                fontWeight: "400",
              }}
            >
              {userData?.phoneNumber || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Email Address
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "20px", // 20px on extra small (mobile) screens
                  md: "24px", // 24px on medium screens and above
                },
                color: "#3D3A3A",
                fontWeight: "400",
              }}
            >
              {userData?.email || "N/A"}
            </Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Address
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "20px", // 20px on extra small (mobile) screens
                  md: "24px", // 24px on medium screens and above
                },
                color: "#3D3A3A",
                fontWeight: "400",
              }}
            >
              {userData?.address || "N/A"}
            </Typography>
          </Grid> */}
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Joined On
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "20px", // 20px on extra small (mobile) screens
                  md: "24px", // 24px on medium screens and above
                },
                color: "#3D3A3A",
                fontWeight: "400",
              }}
            >
              {new Date(userData?.createdDate).toLocaleDateString() || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", fontSize: "16px" }}
            >
              Status
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "20px", // 20px on extra small (mobile) screens
                  md: "24px", // 24px on medium screens and above
                },
                color: "#3D3A3A",
                fontWeight: "400",
              }}
            >
              {userData?.status || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Membership Details Section */}
      <Box
        sx={{
          border: "1px solid #C4C4C4",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ marginBottom: "20px" }}
        >
          Membership Details
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Membership Type</TableCell>
                <TableCell>Points available</TableCell>
                <TableCell>Current Levy Balance</TableCell>
                <TableCell>Points Owned</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {membershipData && membershipData.length > 0 ? (
                membershipData.map((membership, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: "20px" }}>
                      {membership?.productPoolMemberCategory || "N/A"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: {
                          xs: "19px",
                          md: "24px",
                        },
                      }}
                    >
                      {membership?.pointsBalance || "N/A"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "24px" }}>
                      {membership?.levyBalance || "N/A"}
                    </TableCell>
                    <TableCell sx={{ fontSize: "24px" }}>
                      {membership?.pointsOwned || "N/A"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ textAlign: "center", fontSize: "20px" }}
                  >
                    N/A
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <CommonConfirmationModal
        open={showModal}
        onClose={() => setShowModal(false)}
        title={isBlocking ? "Block User" : "Unblock User"}
        description={`Are you sure to ${
          isBlocking ? "block" : "unblock"
        } this user?`}
        confirmButtonText={isBlocking ? "Block" : "Unblock"}
        onConfirm={handleModalConfirm}
        loading={updating}
      />
    </Box>
  );
};

export default UserDetails;
