import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";

interface ImageManagementState {
  imagesList: {
    [key: string]: string[];
  };
  isLoading: boolean;
  error: string | null;
}

const initialState: ImageManagementState = {
  imagesList: {},
  isLoading: false,
  error: null,
};

export const fetchImages = createAsyncThunk(
  "imageManagement/fetchImages",
  async (_, thunkAPI) => {
    try {
      const response = await apiClient.get(
        "/content-management/images"
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch images");
    }
  }
);

const getUploadedImages = createSlice({
  name: "imageManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchImages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;

        const images: { [key: string]: string[] } = {};

        action.payload.forEach((item: any) => {
          images[item.slug] = item.contentitems.map(
            (contentItem: any) => contentItem.image
          );
        });

        state.imagesList = images;
      })
      .addCase(fetchImages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default getUploadedImages.reducer;