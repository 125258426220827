import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Modal,
  Box,
  Typography,
  Checkbox,
  ListItemText,
  IconButton,
  CircularProgress,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import { fetchRoomDetails } from "../../../redux/features/roomManagement/roomDetailsThunk.tsx";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  deleteImage,
  uploadImage,
} from "../../../redux/features/imageManagement/imageManagementSlice.tsx";
import { editUnit } from "../../../redux/features/roomManagement/editUnitThunk.tsx";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import { useAppSelector } from "../../../hooks/useAppSelector.tsx";
import { RootState } from "../../../redux/store.tsx";
import { fetchAmenities } from "../../../redux/features/roomManagement/amenitiesSlice.tsx";
import CommonConfirmationModal from "../../commonComponents/CommonConfirmationModal.tsx";
import { toast } from "react-toastify";

const EditUnit = () => {
  const [unitName, setUnitName] = useState<string>("");
  const [property, setProperty] = useState<string>("Aria Vacation Club");
  const [description, setDescription] = useState("");

  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<
    { file: File; preview: string }[]
  >([]);
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState<{
    url: string;
    index: number;
  } | null>(null);
  const [isDeletingImage, setIsDeletingImage] = useState(false);

  // Initial values for comparison
  const [initialDescription, setInitialDescription] = useState("");
  const [initialAmenities, setInitialAmenities] = useState<string[]>([]);

  const { amenities } = useAppSelector((state: RootState) => state.amenities);
  const location = useLocation();
  const { roomDetails } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { room, loading, error } = useAppSelector(
    (state: RootState) => state.roomDetails
  );

  useEffect(() => {
    if (room?.description) {
      setDescription(room.description);
      setInitialDescription(room.description); // Set initial description
    }

    if (room?.amenities) {
      const preSelected = room.amenities.map((amenity: any) => amenity.title);
      setSelectedAmenities(preSelected);
      setInitialAmenities(preSelected); // Set initial amenities
    }
  }, [room]);

  // Function to check if fields are edited
  const isEdited = () => {
    const amenitiesChanged =
      selectedAmenities.sort().join(",") !== initialAmenities.sort().join(",");
    const descriptionChanged = description !== initialDescription;
    return amenitiesChanged || descriptionChanged;
  };

  useEffect(() => {
    if (room?.images) {
      setUploadedImages(room.images);
    }

    if (room?.description) {
      setDescription(room.description);
    }

    if (room?.amenities) {
      const preSelected = room.amenities.map((amenity: any) => amenity.title);
      setSelectedAmenities(preSelected);
    }
  }, [room]);

  useEffect(() => {
    if (roomDetails) {
      dispatch(fetchRoomDetails(roomDetails?.id));
      setUnitName(roomDetails?.value);
      setProperty(roomDetails?.propertyName);
    }
  }, [roomDetails, dispatch]);

  useEffect(() => {
    dispatch(fetchAmenities({ page: 1, limit: 10 }));
  }, [dispatch]);
  useEffect(() => {
    return () => {
      selectedImages.forEach((image) => URL.revokeObjectURL(image?.preview));
    };
  }, []);

  const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedAmenities(event.target.value as string[]);
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/svg+xml",
        "image/webp",
      ];
      const maxSize = 5 * 1024 * 1024; // 5MB

      const newFiles = Array.from(files);
      const validFiles = newFiles.filter((file) => {
        if (!allowedTypes.includes(file.type)) {
          toast.error("Only JPEG, PNG, JPG, SVG, and WEBP files are allowed");
          return false;
        }

        if (file.size > maxSize) {
          toast.error("File size exceeds 5MB limit");
          return false;
        }

        return true;
      });

      if (validFiles.length === 0) {
        return; // Exit if no valid files
      }

      setIsUploading(true);

      try {
        // Upload images and get new URLs
        const newImageUrls = await Promise.all(
          validFiles.map(async (file) => {
            const fileName = `${file.name}`;
            const result = await dispatch(
              uploadImage({
                file: file,
                fileName: fileName,
                mimeType: file.type,
              })
            ).unwrap();

            if (result.imageUrl) {
              return {
                file,
                preview: result.imageUrl,
              };
            } else {
              throw new Error("Failed to upload image");
            }
          })
        );

        // Update state with new image URLs
        setSelectedImages((prev) => [...prev, ...newImageUrls]);
        // Update room details after ensuring state is updated
        updateRoomDetailsWithNewImages(newImageUrls);
      } catch (error) {
        console.error("Error uploading images:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const updateRoomDetailsWithNewImages = async (newImageUrls) => {
    if (!roomDetails) return;

    try {
      // Prepare final image URLs including the newly uploaded ones
      const allImageUrls = [
        ...uploadedImages,
        ...selectedImages.map((img) => img.preview),
        ...newImageUrls.map((img) => img.preview),
      ];

      const finalImageUrls = allImageUrls.filter(
        (url) => !deletedImages.includes(url)
      );

      const unitDetails = {
        id: roomDetails.id,
        amenityIds: selectedAmenities
          .map(
            (selectedAmenity) =>
              amenities.find((amenity) => amenity.title === selectedAmenity)
                ?.amenityId
          )
          .filter((amenityId) => amenityId !== undefined) as number[],
        description,
        images: finalImageUrls,
        features: [],
      };

      const result = await dispatch(editUnit(unitDetails));
      if (editUnit.fulfilled.match(result)) {
        console.log("Success:", result.payload);
        // Update initial states after successful edit
        setInitialDescription(description);
        setInitialAmenities([...selectedAmenities]);
        toast.success("Unit has been updated successfully");
      } else if (editUnit.rejected.match(result)) {
        console.error("Error:", result.payload);
      }
    } catch (error) {
      console.error("Error in updating room details:", error);
    }
  };

  const handleSubmit = async () => {
    if (!isEdited()) return; // Prevent submission if not edited
    try {
      setIsUploading(true);
      await updateRoomDetailsWithNewImages([]);
    } catch (error) {
      console.error("Error in submission:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const promptDeleteImage = (url: string, index: number) => {
    setImageToDelete({ url, index });
    setShowDeletionModal(true);
  };

  const confirmDeleteImage = async () => {
    if (imageToDelete && roomDetails) {
      const { url, index } = imageToDelete;
      setIsDeletingImage(true); // Start loading

      try {
        // Delete the image
        await dispatch(deleteImage(url));

        // Update state to reflect the deletion
        setDeletedImages((prev) => [...prev, url]);
        setUploadedImages((prev) =>
          prev.filter((imageUrl) => imageUrl !== url)
        );
        setSelectedImages((prev) => {
          const newImages = [...prev];
          if (newImages[index]) {
            URL.revokeObjectURL(newImages[index].preview);
            newImages.splice(index, 1);
          }
          return newImages;
        });

        // Prepare updated room details
        const allImageUrls = [
          ...uploadedImages.filter((imageUrl) => imageUrl !== url),
          ...selectedImages.map((img) => img.preview),
        ];

        const finalImageUrls = allImageUrls.filter(
          (imageUrl) => !deletedImages.includes(imageUrl)
        );

        const unitDetails = {
          id: roomDetails.id,
          amenityIds: selectedAmenities
            .map(
              (selectedAmenity) =>
                amenities.find((amenity) => amenity.title === selectedAmenity)
                  ?.amenityId
            )
            .filter((amenityId) => amenityId !== undefined) as number[],
          description,
          images: finalImageUrls,
          features: [],
        };

        // Update the room details
        const result = await dispatch(editUnit(unitDetails));

        if (editUnit.fulfilled.match(result)) {
          console.log("Room details updated successfully:", result.payload);
          toast.success("Image deleted successfully", {
            theme: "dark",
            icon: ({ theme, type }) => <DeleteForeverIcon />,
          });
          setShowDeletionModal(false);
        } else if (editUnit.rejected.match(result)) {
          console.error("Error updating room details:", result.payload);
        }
      } catch (error) {
        console.error("Error during image deletion or room update:", error);
      } finally {
        setIsDeletingImage(false); // End loading
      }
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  // const handleModalCancel = () => {
  //   setShowModal(false);
  // };

  const handleGoBack = () => {
    navigate("/room-management");
  };

  return (
    <div className="p-6 w-full lg:w-[80%]">
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ marginBottom: "34px", color: "#004135" }}
      >
        <Button
          variant="text"
          onClick={handleGoBack}
          sx={{ fontWeight: "normal" }}
        >
          <ArrowBackIosIcon />
        </Button>
        Edit Unit
      </Typography>
      <form className="flex flex-col gap-6">
        <div className="flex flex-col md:flex-row gap-9">
          <div className="w-full">
            <label className="block text-[#979797] text-sm font-normal mb-2">
              Unit Name
            </label>
            <TextField
              value={unitName}
              disabled
              className="w-full"
              style={{
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
                padding: "0px 14px",
                background: "#fff",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
                input: {
                  color: "#333333",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "16px",
                },
              }}
            />
          </div>

          <div className="w-full">
            <label className="block text-[#979797] text-sm font-normal mb-2">
              Associated Property
            </label>
            <TextField
              disabled
              value={property}
              className="w-full"
              style={{
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
                padding: "0px 14px",
                background: "#fff",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
                input: {
                  color: "#333333",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "16px",
                },
              }}
            />
          </div>

          <div className="w-full">
            <label className="block text-[#979797] text-sm font-normal mb-2">
              Amenities Provided
            </label>
            <TextField
              select
              value={selectedAmenities}
              onChange={handleSelect}
              SelectProps={{
                multiple: true,
                displayEmpty: true,
                renderValue: (selected) => {
                  if ((selected as string[]).length === 0) {
                    return "Select Amenities";
                  }
                  return (selected as string[]).join(", ");
                },
              }}
              variant="outlined"
              className="w-full "
              style={{
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
                background: "#fff",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
                input: {
                  color: "#333333",
                  maxHeight: "40px",
                  whiteSpace: "nowrap",
                  msOverflowX: "scroll",
                },
                "& .MuiFormHelperText-root": {
                  fontSize: "16px",
                },
              }}
            >
              {loading ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : (
                amenities?.map((amenity, index) => (
                  <MenuItem key={index} value={amenity.title}>
                    <Checkbox
                      checked={selectedAmenities.indexOf(amenity.title) > -1}
                    />
                    <ListItemText primary={amenity?.title} />
                  </MenuItem>
                ))
              )}
              {error && <MenuItem disabled>Error: {error}</MenuItem>}
            </TextField>
          </div>
        </div>

        <div>
          <label className="block text-[#979797] text-sm font-normal mb-2">
            Description
          </label>
          <TextField
            placeholder="Enter description here"
            multiline
            rows={7}
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{
              height: "187px",
              border: "1px solid #C4C4C4",
              borderRadius: "10px",
              padding: "0px 0px",
              background: "#fff",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              input: {
                color: "#333333",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "16px",
              },
            }}
          />
        </div>

        <div>
          <label className="block text-[#979797] text-sm font-normal mb-2">
            Add Image
          </label>
          <div className="flex flex-wrap gap-10">
            {uploadedImages.map((img, index) => (
              <div
                key={`backend-${index}`}
                className="relative border rounded-lg w-[300px] h-[187px]"
              >
                <img
                  src={img}
                  alt={`Uploaded ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
                <IconButton
                  onClick={() => promptDeleteImage(img, index)}
                  className="absolute top-2 right-2 bg-white hover:bg-gray-100"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            {selectedImages.map((img, index) => (
              <div
                key={`selected-${index}`}
                className="relative border rounded-lg w-[300px] h-[187px]"
              >
                <img
                  src={img?.preview}
                  alt={`Preview ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
                <IconButton
                  onClick={() => promptDeleteImage(img?.preview, index)}
                  className="absolute top-2 right-2 bg-white hover:bg-gray-100"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}

            <div className="border border-dashed rounded-lg p-4 bg-[#EAF7FA] flex flex-col items-center justify-center w-[300px] h-[187px]">
              <input
                type="file"
                accept=" image/jpg, image/jpeg, image/png, image/svg+xml, image/webp"
                multiple
                style={{ display: "none" }}
                id="upload-images"
                onChange={handleImageUpload}
              />
              <label
                htmlFor="upload-images"
                className="cursor-pointer flex flex-col items-center"
              >
                <UploadFileIcon style={{ fontSize: 40, color: "#3BC1CD" }} />
                <p className="text-gray-500">Upload Image</p>
              </label>
            </div>
          </div>
          <p className="text-sm text-gray-500 mt-10">
            Maximum size allowed: 5 MB, Format supported: JPEG,PNG,JPG,SVG and
            WEBP only
          </p>
        </div>

        <div className="flex justify-end gap-4">
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #C4C4C4",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "700",
              padding: "12px 55px",
              color: "#3D3A3A",
            }}
            onClick={handleCancel}
            disabled={isUploading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "#fff",
              backgroundColor: "#3BC1CD",
              fontSize: "16px",
              fontWeight: "700",
              padding: "12px 55px",
            }}
            onClick={handleSubmit}
            disabled={isUploading || !isEdited()} // Disable if uploading or not edited
          >
            {isUploading ? (
              <>
                <CircularProgress size={20} color="inherit" className="mr-2" />
                Updating...
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>

      {/* <Modal
        open={showModal}
        onClose={handleModalCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "9999" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "350px",
              md: "550px",
            },
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            overflow: "hidden",
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "32px", color: "#3D3A3A", fontWeight: "700" }}
          >
            Unit updated
          </Typography>
          <div className="">
            <img src="/assets/tickIcon.png" alt="tick icon" />
          </div>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              fontSize: "20px",
              color: "#3D3A3A",
              textAlign: "center",
            }}
          >
            Congratulations!
            <br />
            The Unit has been updated successfully
          </Typography>
          <Box
            sx={{
              width: "100%",
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleModalCancel}
              sx={{
                fontSize: "16px",
                padding: "12px",
                width: "100%",
                backgroundColor: "#3BC1CD",
                borderRadius: "8px",
                color: "#fff",
                border: "none",
              }}
            >
              Okay
            </Button>
          </Box>
        </Box>
      </Modal> */}

      <CommonConfirmationModal
        open={showDeletionModal}
        onClose={() => setShowDeletionModal(false)}
        title="Delete Image"
        description="This image will be permanently deleted from this room. Are you sure you want to delete this?"
        confirmButtonText={isDeletingImage ? "Deleting..." : "Yes"}
        onConfirm={confirmDeleteImage}
        loading={isDeletingImage}
      />
    </div>
  );
};

export default EditUnit;
