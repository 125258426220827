import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";
import { RoomListResponse } from "./types.tsx";

interface FetchRoomsParams {
  page: number;
  limit: number;
  searchKey?: string;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
}

export const fetchRooms = createAsyncThunk(
  "roomManagement/fetchRooms",
  async ({ page, limit, searchKey, sortBy, sortOrder }: FetchRoomsParams) => {
    try {
      const response = await apiClient.get<RoomListResponse>(
        "/room-management/list",
        {
          params: {
            page,
            limit,
            searchKey,
            sortBy,
            sortOrder,
          },
        }
      );
      return response.data.data;
    } catch (error: any) {
      throw error.response?.data?.message || "Failed to fetch rooms";
    }
  }
);
