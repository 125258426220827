import { createSlice } from '@reduxjs/toolkit';
import { fetchRoomDetails } from './roomDetailsThunk.tsx';

interface RoomDetailsState {
  room: {
    value: string;
    description: string;
    images: string[];
    propertyName: string;
    amenities: string[];
    features: string[];
    alias: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  loading: boolean;
  error: string | null;
}

const initialState: RoomDetailsState = {
  room: null,
  loading: false,
  error: null,
};

const roomDetailsSlice = createSlice({
  name: 'roomDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoomDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.room = action.payload; // Room details data
      })
      .addCase(fetchRoomDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default roomDetailsSlice.reducer;
