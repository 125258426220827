import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";
import { UserListResponse } from "./types.tsx";

export interface FetchUserParams {
  page: number;
  limit: number;
  searchKey?: string;
  status?: string[];
  cardType?: string[]; // Add this line
  fromDate?: string;
  toDate?: string;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
}

export const fetchUser = createAsyncThunk(
  "userManagement/fetchUser",
  async ({
    searchKey,
    status,
    fromDate,
    toDate,
    sortBy,
    sortOrder,
    cardType,
    page,
    limit,
  }: FetchUserParams) => {
    try {
      const response = await apiClient.get<UserListResponse>(
        "/user-management/list",
        {
          params: {
            searchKey,
            status,
            fromDate,
            toDate,
            sortBy,
            cardType,
            sortOrder,
            page,
            limit,
          },
        }
      );
      return response.data.data;
    } catch (error: any) {
      throw error.response?.data?.message || "Failed to fetch user";
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  "userManagement/fetchUserDetails",
  async (nameId: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/user-management/detail`, {
        params: { nameId },
      });
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          status: error.response.status,
          message:
            error.response.data.message || "Failed to fetch user details",
        });
      }
      return rejectWithValue({ message: "An unexpected error occurred" });
    }
  }
);

// Thunk to update user status
export const updateUserStatus = createAsyncThunk(
  "userManagement/updateUserStatus",
  async (
    {
      nameId,
      status,
      autoDebit,
    }: { nameId: number; status?: string; autoDebit?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.patch("/user-management/update", {
        nameId,
        status,
        autoDebit,
      });
      return response.data;
    } catch (error: any) {
      // Handle error appropriately
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);
