import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import apiClient from "../../../utils/apiClient.tsx";

export const fetchProperties = createAsyncThunk(
  "properties/fetch",
  async (params: { limit: number; searchKey: string }) => {
    const response = await apiClient.get(`/room-management/property-list`, {
      params: {
        page: 1,
        limit: params.limit,
        searchKey: params.searchKey,
      },
    });
    return response.data;
  }
);

interface PropertyState {
  properties: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
}

const initialState: PropertyState = {
  properties: [],
  status: "idle",
};

const propertyManagementSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.properties = action.payload.data;
      })
      .addCase(fetchProperties.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default propertyManagementSlice.reducer;
