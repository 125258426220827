import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiClient from "../../../utils/apiClient.tsx";

// Interface for an individual testimonial
interface Testimonial {
  testimonialId: number;
  name: string;
  title: string;
  description: string;
  status: string;
  createdAt: string;
}

// Interface for the response data
interface TestimonialListResponse {
  data: Testimonial[];
  count: number;
  page: number;
  limit: number;
  nextPage: number;
  totalPages: number;
}

// Interface for the state
interface TestimonialListState {
  testimonials: Testimonial[];
  loading: boolean;
  error: string | null;
  totalCount: number;
}

// Initial state
const initialState: TestimonialListState = {
  testimonials: [],
  loading: false,
  error: null,
  totalCount: 0,
};

// Async thunk to fetch testimonials with query parameters
export const fetchTestimonials = createAsyncThunk(
  "testimonialList/fetchTestimonials",
  async (params: {
    searchKey?: string;
    status?: string[];
    fromDate?: string;
    toDate?: string;
    sortBy?: string;
    sortOrder?: "ASC" | "DESC" | undefined;
    page: number;
    limit: number;
  }) => {
    const response = await apiClient.get("/testimonial-management/list", {
      params,
    });
    return response.data.data;
  }
);

// Slice definition
const testimonialListSlice = createSlice({
  name: "testimonialList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestimonials.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchTestimonials.fulfilled,
        (state, action: PayloadAction<TestimonialListResponse>) => {
          state.testimonials = action.payload.data;
          state.totalCount = action.payload.count;
          state.loading = false;
        }
      )
      .addCase(fetchTestimonials.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch testimonials";
        state.loading = false;
      });
  },
});

export default testimonialListSlice.reducer;
