import React from "react";
import { Modal, Box, Typography, CircularProgress, Button } from "@mui/material";

interface CommonModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  confirmButtonText: string;
  onConfirm: () => void;
  loading?: boolean;
}

const CommonConfirmationModal: React.FC<CommonModalProps> = ({
  open,
  onClose,
  title,
  description,
  confirmButtonText,
  onConfirm,
  loading = false,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: "9999" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 550,
          bgcolor: "background.paper",
          borderRadius: "12px",
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ fontSize: "32px", color: "#3D3A3A" }}
        >
          {title}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, fontSize: "20px", color: "#3D3A3A" }}
        >
          {loading ? (
            <span>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              Please wait...
            </span>
          ) : (
            description
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              mr: 2,
              width: "50%",
              borderRadius: "8px",
              border: "1px solid #9DA4AE",
              color: "#111",
            }}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={onConfirm}
            sx={{
              width: "50%",
              backgroundColor: "#3BC1CD",
              borderRadius: "8px",
              color: "#fff",
              border: "none",
            }}
            disabled={loading}
          >
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonConfirmationModal;