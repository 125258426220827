import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import { Popover, Typography, Box, IconButton, Badge } from "@mui/material";
import { useAppDispatch } from "../../hooks/useAppDispatch.tsx";
import { useAppSelector } from "../../hooks/useAppSelector.tsx";
import { fetchNotifications } from "../../redux/features/notification/notificationSlice.tsx";
import { formatDistanceToNow, differenceInHours } from "date-fns";
import {
  fetchNotificationCount,
  markNotificationsAsRead,
} from "../../redux/features/notification/notificationCountSlice.tsx";

interface DashboardNavbarProps {
  toggleSidebar: () => void;
}

const DashboardNavbar: React.FC<DashboardNavbarProps> = ({ toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const dispatch = useAppDispatch();
  const {
    data: notifications,
    loading,
    error,
  } = useAppSelector((state) => state.notifications);
  const profile = useAppSelector((state) => state.profile.data);
  const notificationCount = useAppSelector(
    (state) => state.notificationCount.count
  );

  const profileData = profile?.data;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(fetchNotifications({ page, limit }));
    dispatch(fetchNotificationCount()); // Fetch notification count
  }, [dispatch, page, limit]);

   // Fetch notification count whenever the route changes
   useEffect(() => {
    dispatch(fetchNotificationCount());
  }, [dispatch, location.pathname]);

  const getTitle = () => {
    const path = location.pathname;
    if (path === "/user-management") return "User Management";
    if (path === "/profile") return "My Profile";
    if (path.startsWith("/user-details")) return "User Management";
    if (path.startsWith("/static-content")) return "Static Content Management";
    if (path === "/booking-management") return "Booking Request Management";
    if (path.startsWith("/booking-detail")) return "Booking Request Management";
    if (path.startsWith("/room-management")) return "Room Management";
    if (path.startsWith("/room-details")) return "Room Management";
    if (path === "/notifications-management") return "Notifications Management";
    if (path === "/subadmin-management") return "Sub Admin Management";
    if (path.startsWith("/subadmin-management")) return "Sub Admin Management";
    if (path === "/testimonial-management") return "Testimonials Management";
    if (path === "/image-management") return "Image Management";
    if (path.startsWith("/testimonial-management"))
      return "Testimonials Management";
    if (path === "/reservations") return "Reservations";
    if (path.startsWith("/reservation")) return "Reservations";
    return "Dashboard";
  };

  const title = getTitle();
  const handleProfile = () => {
    navigate("/profile");
  };
  const handleNotificationClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(markNotificationsAsRead()); // Mark notifications as read when clicked
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;
    if (bottom && !loading && notifications.length >= limit * page) {
      setPage((prevPage) => prevPage + 1);
      setLimit((prevLimit) => prevLimit + 10);
    }
  };

  const formatNotificationTime = (createdAt: Date) => {
    const now = new Date();
    const hoursDifference = differenceInHours(now, createdAt);

    if (hoursDifference < 2) {
      return formatDistanceToNow(createdAt, { addSuffix: true });
    } else {
      return createdAt.toLocaleTimeString();
    }
  };

  const handleRedirect = (
    notificationType: string,
    redirectionId: number | null | undefined
  ) => {
    // Log for debugging
    // console.log("Notification Type:", notificationType);
    // console.log("Redirection ID:", redirectionId);

    // Return early if redirectionId is null or undefined
    if (redirectionId == null) {
      return;
    }

    // Handle navigation based on notification type
    switch (notificationType) {
      case "BOOKING_REQUEST_CREATED":
        navigate(`/booking-details/${redirectionId}`);
        break;
      case "RESERVATION_CREATED":
        navigate(`/reservation-details/${redirectionId}`);
        break;
      case "AUTO_DEBIT_DISABLED":
      case "AUTO_DEBIT_ENABLED":
      case "RECURRING_PAYMENT_SUCCESS":
      case "RECURRING_PAYMENT_FAILED":
      case "RECURRING_PAYMENT_RETRY":
        navigate(`/user-details/${redirectionId}`);
        break;
      default:
        console.log("Unknown notification type:", notificationType);
    }

    // Close the notification popover after navigation
    handleClose();
  };

  return (
    <nav className="py-4 px-1 md:px-9 flex justify-between items-center shadow-md h-[70px] md:h-[85px]">
      <div className="flex items-center justify-start">
        <IconButton
          sx={{
            display: { xs: "inline-flex", md: "none" },
            marginRight: 2,
          }}
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </IconButton>
        <h1 className="text-lg md:text-[32px] font-bold text-[#004135]">
          {title}
        </h1>
      </div>
      <div className="flex items-center gap-4">
        <div>
          <Badge badgeContent={notificationCount} color="error">
            <NotificationsIcon
              className="text-gray-600 cursor-pointer"
              onClick={handleNotificationClick}
            />
          </Badge>
          <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ width: "375px" }}>
              <Typography
                variant="h6"
                sx={{
                  padding: 2,
                  borderBottom: "1px solid #C4C4C4",
                  fontWeight: "bold",
                  fontSize: "24px",
                }}
              >
                Notifications
              </Typography>
              <Box
                sx={{
                  maxHeight: { xs: "411px", md: "730px" }, // Responsive maxHeight
                  overflowY: "auto",
                  // Hide scrollbar for WebKit browsers
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                  // Hide scrollbar for IE, Edge
                  "-ms-overflow-style": "none",
                  // Hide scrollbar for Firefox
                  "scrollbar-width": "none",
                  padding: "0px 10px",
                }}
                onScroll={handleScroll}
              >
                {loading && (
                  <Typography sx={{ padding: 2 }}>Loading...</Typography>
                )}
                {error && (
                  <Typography sx={{ padding: 2, color: "red" }}>
                    {error}
                  </Typography>
                )}
                {notifications.map((notification) => (
                  <Box
                    key={notification.notificationId}
                    onClick={() =>
                      handleRedirect(
                        notification.notificationType,
                        notification.redirectionId
                      )
                    }
                    sx={{
                      padding: "12px 1px",
                      borderBottom: "1px solid #E0E0E0",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: "100%",
                      overflow: "hidden",
                      cursor:
                        notification.redirectionId != null
                          ? "pointer"
                          : "default", // Only show pointer cursor if redirectionId exists
                      "&:hover": {
                        backgroundColor:
                          notification.redirectionId != null
                            ? "#f5f5f5"
                            : "transparent", // Only show hover effect if redirectionId exists
                      },
                    }}
                  >
                    <div className="flex flex-col w-[70%]">
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#111",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis", // Apply ellipsis for overflow
                          width: "100%", // Ensure it uses full width of the container
                        }}
                      >
                        {notification.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                          color: "#666",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis", // Apply ellipsis for overflow
                          width: "100%", // Ensure it uses full width of the container
                        }}
                      >
                        {notification.body}
                      </Typography>
                    </div>
                    <div className="w-[30%] text-end">
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "12px",
                          color: "#999",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis", // Apply ellipsis for overflow
                          width: "100%", // Ensure it uses full width of the container
                        }}
                      >
                        {formatNotificationTime(
                          new Date(notification.createdAt)
                        )}
                      </Typography>
                    </div>
                  </Box>
                ))}
              </Box>
            </div>
          </Popover>
        </div>
        <div
          onClick={handleProfile}
          className="text-[#004135] font-bold text-[15px] md:text-[23px] cursor-pointer"
        >
          Hi, {profileData?.name}
        </div>
      </div>
    </nav>
  );
};

export default DashboardNavbar;
