import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";

// Define the initial state for the notification count
interface NotificationCountState {
  count: number;
  loading: boolean;
  error: string | null;
}

const initialState: NotificationCountState = {
  count: 0,
  loading: false,
  error: null,
};

// Create an async thunk to fetch the notification count
export const fetchNotificationCount = createAsyncThunk(
  "notifications/fetchCount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        "/notification/count"
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch count");
    }
  }
);

// Create an async thunk to mark notifications as read
export const markNotificationsAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await apiClient.patch("/notification");
      dispatch(fetchNotificationCount()); // Refresh the count after marking as read
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to mark notifications as read");
    }
  }
);

// Create the slice
const notificationCountSlice = createSlice({
  name: "notificationCount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationCount.fulfilled, (state, action) => {
        state.loading = false;
        state.count = action.payload;
      })
      .addCase(fetchNotificationCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(markNotificationsAsRead.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export default notificationCountSlice.reducer;