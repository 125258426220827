import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiClient from '../../../utils/apiClient.tsx';

// Define the initial state
interface AutoDebitState {
  autoDebit: boolean | null;
  cardType: string | null;
  isActive: boolean | null;
  loading: boolean;
  error: string | null;
}

const initialState: AutoDebitState = {
  autoDebit: null,
  cardType: null,
  isActive: null,
  loading: false,
  error: null,
};

// Define an async thunk for fetching auto debit data
export const fetchAutoDebitData = createAsyncThunk(
  'autoDebit/fetchAutoDebitData',
  async (nameId: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `/user-management/autoDebit/${nameId}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create the slice
const autoDebitSlice = createSlice({
  name: 'autoDebit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutoDebitData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAutoDebitData.fulfilled, (state, action) => {
        state.loading = false;
        state.autoDebit = action.payload.autoDebit;
        state.cardType = action.payload.cardType;
        state.isActive = action.payload.isActive;
      })
      .addCase(fetchAutoDebitData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default autoDebitSlice.reducer;