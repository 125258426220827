// RichTextEditor.tsx
import React, { useEffect, useState } from "react";
import {
  ContentState,
  convertFromHTML,
  EditorState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.tsx";
import { updateContent } from "../../../redux/features/contentManagement/contentSlice.tsx";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

type RichTextEditorProps = {
  onCancel?: () => void;
  onSaveSuccess?: () => void; // Add this prop
  placeholder?: string;
  value: string;
  type: number;
};

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  onCancel,
  onSaveSuccess, // Destructure the new prop
  value,
  placeholder = "Enter details",
  type,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState("");
  const [initialContent, setInitialContent] = useState(value);

  const dispatch = useAppDispatch();
  const { content, isLoading } = useSelector(
    (state: RootState) => state.content
  );

  // Initialize editor state with the passed `value`
  useEffect(() => {
    if (value) {
      try {
        const blocksFromHTML = convertFromHTML(value);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
        setInitialContent(value); // Set the initial content
      } catch (error) {
        console.error("Error initializing editor state:", error);
      }
    } else {
      setEditorState(EditorState.createEmpty());
      setInitialContent(""); // Set initial content to empty if no value
    }
  }, [value]);

  // Effect to convert editorState to HTML whenever it changes
  useEffect(() => {
    const htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setConvertedContent(htmlContent);
  }, [editorState]);

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const handleSave = async () => {
    const resultAction = await dispatch(
      updateContent({ type: type, content: convertedContent })
    );
    if (
      updateContent.fulfilled.match(resultAction) &&
      resultAction.payload.statusCode === 200
    ) {
      toast.success("Content updated successfully")
      onSaveSuccess?.(); // Call onSaveSuccess if the response is successful
    }
  };

  const isContentChanged = convertedContent !== initialContent;

  return (
    <div style={{ direction: "ltr" }} className="w-full bg-white p-2">
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbar={{
          options: ["inline", "list"],
          inline: { options: ["bold", "italic", "underline"] },
          list: { options: ["unordered"] },
        }}
        placeholder={placeholder}
      />

      {onSaveSuccess && (
        <div className="flex justify-end gap-2">
          <Button
            variant="outlined"
            onClick={onCancel} // Use the onCancel prop here
            style={{
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "10px",
              padding: "8px 16px",
              color: "#111",
              border: "1px solid #C4C4C4",
              borderRadius: "10px",
            }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isContentChanged} // Disable button if content hasn't changed
            style={{
              fontSize: "16px",
              fontWeight: "700",
              marginTop: "10px",
              padding: "8px 16px",
              background: isContentChanged ? "#3BC1CD" : "#C4C4C4", // Change background color based on disabled state
              color: "#FFF",
              border: "none",
              borderRadius: "8px",
              cursor: isContentChanged ? "pointer" : "not-allowed", // Change cursor based on disabled state
            }}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default RichTextEditor;
