import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { logout } from "../../redux/features/auth/authSlice.tsx";
import { useAppDispatch } from "../../hooks/useAppDispatch.tsx";
import { toast } from "react-toastify";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

// Create a custom ListItem that works with React Router
const CustomListItem = styled(ListItem)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#a9edf35c",
    borderLeft: "4px solid #A9EDF3",
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  // State to manage sidebar open/close
  const [localIsOpen, setLocalIsOpen] = useState(true);

  // Effect to handle sidebar state on screen size change
  useEffect(() => {
    if (isMobileScreen) {
      // On mobile, sidebar should start closed
      setLocalIsOpen(true);
    } else {
      // On larger screens, sync with prop
      setLocalIsOpen(isOpen);
    }
  }, [isMobileScreen, isOpen]);

  const links = [
    { name: "Dashboard", path: "/dashboard", icon: "dashboard-Icon.png" },
    {
      name: "User Management",
      path: "/user-management",
      icon: "userManagement-Icon.png",
    },
    { name: "Rooms", path: "/room-management", icon: "rooms-Icon.png" },
    {
      name: "Testimonials",
      path: "/testimonial-management",
      icon: "testimonials-Icon.png",
    },
    {
      name: "Booking Request",
      path: "/booking-management",
      icon: "bookings-Icon.png",
    },
    {
      name: "Reservations ",
      path: "/reservations",
      icon: "bookings-Icon.png",
    },
    {
      name: "Static Content",
      path: "/static-content/privacy-policy",
      icon: "content-Icon.png",
    },
    {
      name: "Sub Admin",
      path: "/subadmin-management",
      icon: "subAdmin-Icon.png",
    },
    {
      name: "Static Images",
      path: "/image-management",
      icon: "image-management-icon.png",
    },
  ];

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  const handleLogout = () => {
    dispatch(logout()); // Dispatch the logout action to clear the Redux state
    toast.success("You are logged out");
    navigate("/signin"); // Redirect to the sign-in page
  };

  // Handle sidebar toggle with mobile considerations
  const handleToggleSidebar = () => {
    if (isMobileScreen) {
      // On mobile, directly toggle local state
      setLocalIsOpen(!localIsOpen);
      console.log(localIsOpen, "jlocalll");
    } else {
      // On larger screens, use the prop-based toggle
      toggleSidebar();
      setLocalIsOpen(!localIsOpen);
    }
  };
  return (
    <Drawer
      variant={isMobileScreen ? "temporary" : "permanent"}
      open={isOpen}
      onClose={toggleSidebar}
      sx={{
        width: isOpen ? 290 : 80,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          paddingLeft: localIsOpen ? "32px" : 0,
          paddingRight: localIsOpen ? "16px" : 0,
          width: isOpen ? 290 : 80,
          boxSizing: "border-box",
          transition: "width 0.3s ease",
          overflowX: "hidden",
          position: isMobileScreen ? "fixed" : "relative",
          zIndex: theme.zIndex.drawer + 1000,
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div
        className={`
          flex 
          items-center 
          justify-between 
          p-4 
          transition-all 
          duration-300 
          ease-in-out
        `}
      >
        {localIsOpen && (
          <img
            src="/assets/Aria-Logo.png"
            alt="Aria Logo"
            className="
              cursor-pointer 
              h-10 
              transition-all 
              duration-300 
              ease-in-out
              ${localIsOpen ? 'opacity-100' : 'opacity-0'}
            "
            onClick={handleLogoClick}
          />
        )}
        <IconButton
          onClick={isMobileScreen ? toggleSidebar : handleToggleSidebar}
        >
          {localIsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>

      <div>
        <List sx={{ marginTop: "44px" }}>
          {links.map((link, index) => (
            <CustomListItem
              sx={{ justifyContent: "center", minHeight: "48px" }}
              key={index}
              onClick={() => {
                navigate(link.path);
                // Automatically close on mobile after navigation
                if (isMobileScreen) {
                  toggleSidebar();
                }
              }}
              className={`rounded-lg mb-2 ${
                location.pathname === link.path
                  ? "bg-[#a9edf35c] border-[#A9EDF3] border-l-4 font-bold"
                  : "hover:bg-gray-100"
              }`}
            >
              <Tooltip
                title={link.name}
                placement="right"
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                        {
                          marginTop: "30px",
                        },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                        {
                          marginBottom: "30px",
                        },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "30px",
                        },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                        {
                          marginRight: "30px",
                        },
                    },
                  },
                }}
              >
                <img
                  src={`/assets/${link.icon}`}
                  className={`${localIsOpen ? "mr-3" : "mr-0"}`}
                  alt={`${link.name} icon`}
                />
              </Tooltip>
              {localIsOpen && <ListItemText primary={link.name} />}
            </CustomListItem>
          ))}
        </List>

        <List sx={{ marginTop: "220px" }}>
          <CustomListItem
            onClick={handleLogout}
            sx={{
              color: "#FF0000",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(255, 0, 0, 0.04)",
              },
            }}
          >
            <img
              src="/assets/logout-Icon.png"
              className={`${localIsOpen ? "mr-3" : "mr-0"}`}
              alt="logout icon"
            />{" "}
            {localIsOpen && <ListItemText primary="Logout" />}
          </CustomListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;
