// src/components/PageNotFound.tsx
import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const handleGoHome = () => {
    navigate("/dashboard"); // Navigate to the dashboard/home page
  };

  return (
    <div className="flex flex-col items-center justify-center h-[100vh]">
      <ErrorOutlineIcon sx={{ fontSize: 80, color: "primary.main", mb: 2 }} />
      <Typography variant="h4" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Oops! The page you are looking for does not exist
        .
      </Typography>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoBack}
          sx={{ mr: 2 }}
        >
          Go Back
        </Button>
        <Button variant="outlined" sx={{color:"black"}} onClick={handleGoHome}>
          Go to Dashboard
        </Button>
      </Box>
    </div>
  );
};

export default PageNotFound;
