import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomTextField from "../../commonComponents/CustomTextfield.tsx";
import { changePassword } from "../../../redux/features/forgot-reset-Password/changePasswordSlice.tsx";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import { useAppSelector } from "../../../hooks/useAppSelector.tsx";
import { toast } from "react-toastify";

interface ResetPasswordModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const { isLoading, error, successMessage } = useAppSelector(
    (state) => state.changePass
  );

  // Password visibility states
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  // Password input states
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validateNewPassword = () => {
    const errors: string[] = [];
    if (newPassword.length < 8) {
      errors.push("Must be at least 8 characters");
    }
    if (newPassword.length > 16) {
      errors.push("Not exceed 16 characters");
    }
    if (!/(?=.*[a-z])/.test(newPassword)) {
      errors.push("Contain a lowercase letter");
    }
    if (!/(?=.*[A-Z])/.test(newPassword)) {
      errors.push("Contain an uppercase letter");
    }
    if (!/(?=.*\d)/.test(newPassword)) {
      errors.push("Contain a number");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(newPassword)) {
      errors.push("Contain a special character");
    }
    return errors.length > 0 ? errors.join(", ") : "";
  };

  useEffect(() => {
    setErrorMessage(validateNewPassword());
  }, [newPassword]);

  const validatePasswords = () => {
    if (!oldPassword) {
      setErrorMessage("Please enter your current password.");
      return false;
    }
    const newPasswordErrors = validateNewPassword();
    if (newPasswordErrors) {
      setErrorMessage(newPasswordErrors);
      return false;
    }
    if (oldPassword === newPassword) {
      setErrorMessage(
        "New password must be different from the current password."
      );
      return false;
    }
    return true;
  };

  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
    setErrorMessage(null); // Clear error message when user modifies the input
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    setErrorMessage(null); // Clear error message on change
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setOldPassword("");
    setNewPassword("");
    setErrorMessage(null);
  };

  const handleSubmit = async () => {
    if (!validatePasswords()) {
      return;
    }

    setErrorMessage(null);
    try {
      await dispatch(changePassword({ oldPassword, newPassword })).unwrap();
      toast.success("Password updated successfully");
      handleModalClose();
    } catch (err: any) {
      const errorMsg =
        err?.message || "Password change failed. Please try again.";
      setErrorMessage(errorMsg);
      // toast.error(errorMsg);
    }
  };

  const getPasswordFieldStyle = {
    marginBottom: "20px",
    border: "1px solid #C4C4C4",
    borderRadius: "10px",
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleModalClose}
      maxWidth="sm"
      fullWidth
      sx={{ zIndex: "9999" }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
      }}
      PaperProps={{ sx: { borderRadius: "12px" } }}
    >
      <DialogTitle
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "24px" }}
      >
        Reset Password
      </DialogTitle>
      <DialogContent
        sx={{
          padding: { xs: "10px", md: "24px" },
        }}
      >
        <div className="flex flex-col gap-3">
          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter Current Password"
            type={showOldPassword ? "text" : "password"}
            value={oldPassword}
            onChange={handleOldPasswordChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon style={{ color: "#C4C4C4" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={getPasswordFieldStyle}
          />

          <CustomTextField
            fullWidth
            variant="outlined"
            placeholder="Enter New Password"
            type={showNewPassword ? "text" : "password"}
            value={newPassword}
            onChange={handleNewPasswordChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon style={{ color: "#C4C4C4" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={getPasswordFieldStyle}
          />
        </div>

        {errorMessage && (
          <Typography color="error" variant="body2" gutterBottom>
            {errorMessage}
          </Typography>
        )}
      </DialogContent>

      <DialogActions
        style={{
          padding: "24px 10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <Button
          onClick={handleModalClose}
          variant="outlined"
          style={{
            color: "#111",
            fontWeight: "500",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            padding: "10px 20px",
            flex: 1,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          style={{
            backgroundColor: "#3BC1CD",
            color: "#FFF",
            fontWeight: "500",
            borderRadius: "8px",
            padding: "10px 20px",
            flex: 1,
          }}
          disabled={isLoading || !!errorMessage || !validatePasswords()} // Use !! to coerce errorMessage to boolean
        >
          {isLoading ? <CircularProgress size={24} /> : "Update Password"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordModal;
