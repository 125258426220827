import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";

interface LoginData {
  email: string;
  password: string;
}

const login = async (data: LoginData) => {
  const auth = process.env.REACT_APP_BASIC_AUTH;
  const response = await axios.post(
    "https://aria-vacation-dev.appskeeper.in/api/v1/admin/login",
    data,
    {
      headers: {
        Authorization: `Basic ${auth}`,
      },
    }
  );
  return response.data;
};

export default {
  login,
};
