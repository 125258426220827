import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../dataTable/index.ts";
import {
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store.tsx";
import { fetchRooms } from "../../../redux/features/roomManagement/roomManagementThunks.tsx";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  fetchToggleStatus,
  updateToggleStatus,
} from "../../../redux/features/masterToggle/masterToggleSlice.tsx";

interface FetchRoomsParams {
  page: number;
  limit: number;
  searchKey?: string;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
}

const RoomManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { rooms, loading, error, totalCount } = useSelector(
    (state: RootState) => state.roomManagement
  );
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const { pageStatus } = useSelector((state: RootState) => state.toggleStatus);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [sortedColumn, setSortedColumn] = useState<string>("");

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Pass the type when fetching
    dispatch(fetchToggleStatus("room"));
  }, [dispatch]);

  const handleToggleChange = (_: React.SyntheticEvent, checked: boolean) => {
    dispatch(updateToggleStatus({ pageStatus: checked, type: "room" }));
  };
  useEffect(() => {
    dispatch(fetchRooms({ page, limit: pageSize, searchKey }));
  }, [dispatch, page, pageSize, searchKey]);

  const rows = useMemo(() => {
    return rooms.map((room, index) => ({
      ...room,
      id: room?.id,
      serialNumber: (page - 1) * pageSize + index + 1, // Calculate the serial number
    }));
  }, [rooms, page, pageSize]);

  const handleSearch = (newSearchKey: string) => {
    setSearchKey(newSearchKey);
    setPage(1); // Reset to first page when searching
  };

  const handleEdit = (e: any, params: any) => {
    e.stopPropagation();
    navigate(`/room-management/edit-unit/${params.id}`, {
      state: { roomDetails: params.row },
    });
  };

  const handlePaginationModelChange = async (model: {
    page: number;
    pageSize: number;
  }) => {
    const newPage = model.page + 1;
    const newPageSize = model.pageSize;

    setPage(newPage);
    setPageSize(newPageSize);

    const fetchParams: FetchRoomsParams = {
      page: newPage,
      limit: newPageSize,
      searchKey: searchKey || undefined,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchRooms(fetchParams));
  };

  const handleHeaderIconClick = async (columnField: string) => {
    const nextSortOrder =
      columnField === sortedColumn
        ? sortOrder === "ASC"
          ? "DESC"
          : sortOrder === "DESC"
          ? ""
          : "ASC"
        : "ASC";

    setSortedColumn(nextSortOrder === "" ? "" : columnField);
    setSortOrder(nextSortOrder as "ASC" | "DESC" | "");

    // Fetch data with the new sort order
    dispatch(
      fetchRooms({
        page,
        limit: pageSize,
        sortBy: "value",
        sortOrder: nextSortOrder || undefined,
      })
    );
  };

  const getSortIcon = (
    columnField: string,
    sortedColumn: string,
    sortOrder: string
  ) => {
    if (columnField === sortedColumn) {
      if (sortOrder === "ASC") return <NorthIcon />;
      if (sortOrder === "DESC") return <SouthIcon />;
    }
    return <ImportExportIcon />;
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S.no",
      flex: isMobileScreen ? undefined : 0.5,
      width: isMobileScreen ? 70 : undefined,
      headerClassName: "column-header-bg-green",
      resizable: false,
      valueGetter: (params) => params, // Use the calculated serial number
      renderCell: (params) => <span>{params.row.serialNumber}</span>, // Display the serial number
    },
    {
      field: "value",
      headerName: "Unit Name",
      flex: isMobileScreen ? undefined : 1,
      width: isMobileScreen ? 150 : undefined,
      headerClassName: "column-header-bg-green",
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Unit Name</span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleHeaderIconClick("value");
            }}
            aria-label="sort-toggle"
            size="small"
            sx={{
              color:
                sortedColumn === "value" && sortOrder !== ""
                  ? "black"
                  : "black",
              "&:hover": {
                backgroundColor: "#3bc1cd",
                color: "white",
              },
            }}
          >
            {getSortIcon("value", sortedColumn, sortOrder)}
          </IconButton>
        </div>
      ),
    },

    {
      field: "propertyName",
      headerName: "Property Name",
      flex: isMobileScreen ? undefined : 1,
      width: isMobileScreen ? 150 : undefined,
      headerClassName: "column-header-bg-green",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: isMobileScreen ? undefined : 1,
      width: isMobileScreen ? 150 : undefined,
      headerClassName: "column-header-bg-green",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="gap-4">
          <Button
            sx={{
              padding: { xs: "3px", md: "7px" },
              fontSize: { xs: "11px", md: "16px" },
            }}
            variant="contained"
            style={{
              marginLeft: 16,
              backgroundColor: "#3BC1CD",
              color: "white",
              fontWeight: "400",
            }}
            onClick={(e) => handleEdit(e, params)}
          >
            <EditIcon sx={{ height: { xs: "12px", md: "20px" } }} />
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const handleRowClick = (params) => {
    navigate(`/room-details/${params.id}`, { state: { user: params } });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage + 1); // MUI Data Grid uses 0-based pagination
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(1); // Reset to first page when changing page size
  };

  return (
    <div className="p-6">
      <DataTable
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        searchPlaceholder="Search by Unit name"
        onSearch={handleSearch}
        loading={loading}
        masterToggle={
          <FormGroup sx={{ marginRight: "20px" }}>
            <FormControlLabel
              labelPlacement={"start"}
              control={
                <Switch checked={pageStatus} onChange={handleToggleChange} />
              }
              label="Home screen visibility"
              onClick={(e) => e.stopPropagation()} // This prevents label clicks from triggering the switch
              // onLabelClick={(e) => e.preventDefault()} // This specifically prevents label clicks
            />
          </FormGroup>
        }
        paginationModel={{
          page: page - 1, // Convert to 0-based for DataGrid
          pageSize,
        }}
        pageSizeOptions={[10, 25, 50]}
        rowCount={totalCount || 0}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default RoomManagement;
