import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";

interface UpdateSubAdminPayload {
  adminId: number;
  name?: string;
  email?:string;
  password?: string;
  status?: string;
  phoneNumber?: string;
  viewModule?: string[];
  editModule?: string[];
}

interface SubAdmin {
  adminId: number;
  name: string;
  email: string;
  status: string;
  role: string;
  phoneNumber: string;
  createdAt: string;
}

interface SubAdminState {
  data: SubAdmin[];
  count: number;
  page: number;
  limit: number;
  loading: boolean;
  error: any;
}

const initialState: SubAdminState = {
  data: [],
  count: 0,
  page: 1,
  limit: 10,
  loading: false,
  error: null,
};

// Define the parameters type for the API call
export interface FetchSubAdminsParams {
  searchKey?: string;
  status?: string[];
  fromDate?: string;
  toDate?: string;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
  page: number;
  limit: number;
}

// Define the return type of the API call
interface FetchSubAdminsResponse {
  data: SubAdmin[];
  count: number;
  page: number;
  limit: number;
}

export const fetchSubAdmins = createAsyncThunk<
  FetchSubAdminsResponse,
  FetchSubAdminsParams
>("subAdmin/fetchSubAdmins", async (params) => {
  const response = await apiClient.get("/sub-admin/list", {
    params,
  });
  return response.data.data;
});

export const updateSubAdminStatus = createAsyncThunk(
  "subAdmin/updateStatus",
  async (payload: UpdateSubAdminPayload, { rejectWithValue }) => {
    try {
      const response = await apiClient.patch("/sub-admin", payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subAdminSlice = createSlice({
  name: "subAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubAdmins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.count = action.payload.count;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
      })
      .addCase(fetchSubAdmins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch sub-admins";
      })
      .addCase(updateSubAdminStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSubAdminStatus.fulfilled, (state, action) => {
        state.loading = false;
        // Update the specific sub-admin in the state
        const updatedAdmin = action.payload;
        const index = state.data.findIndex(
          (admin) => admin.adminId === updatedAdmin.adminId
        );
        if (index !== -1) {
          state.data[index] = updatedAdmin;
        }
      })
      .addCase(updateSubAdminStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default subAdminSlice.reducer;
