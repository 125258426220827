import React, { useEffect, useState, useMemo } from "react";
import { DataTable } from "../../dataTable/index.ts";
import {
  Switch,
  Button,
  useTheme,
  FormGroup,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import { useMediaQuery } from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { useAppSelector } from "../../../hooks/useAppSelector.tsx";
import { RootState } from "../../../redux/store";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import {
  fetchToggleStatus,
  updateToggleStatus,
} from "../../../redux/features/masterToggle/masterToggleSlice.tsx";
import { fetchTestimonials } from "../../../redux/features/testimonialManagement/testimonialListSlice.tsx";
import dayjs from "dayjs";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { updateTestimonialStatus } from "../../../redux/features/testimonialManagement/testimonialStatusSlice.tsx";
import CommonConfirmationModal from "../../commonComponents/CommonConfirmationModal.tsx";
import { deleteTestimonial } from "../../../redux/features/testimonialManagement/testimonialDeleteSlice.tsx";
import CommonFilter from "../../commonComponents/CommonFilter.tsx";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface Testimonial {
  testimonialId: number;
  title: string;
  userName: string;
  status: string;
}

interface FilterParams {
  selectedStatuses: string[];
  fromDate?: string;
  toDate?: string;
}

interface FetchTestimonials {
  page: number;
  limit: number;
  searchKey?: string;
  status: string[];
  fromDate?: string;
  toDate?: string;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
}
const TestimonialManagement = () => {
  const [selectedRow, setSelectedRow] = useState<Testimonial | null>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [sortedColumn, setSortedColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  // Add new state for filter params
  const [filterParams, setFilterParams] = useState<FilterParams>({
    selectedStatuses: [],
    fromDate: undefined,
    toDate: undefined,
  });

  const { pageStatus } = useAppSelector(
    (state: RootState) => state.toggleStatus
  );
  const dispatch = useAppDispatch();
  const { testimonials, loading, error, totalCount } = useAppSelector(
    (state: RootState) => state.testimonialList
  );

  const testimonialStatusOptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
  ];

  const handlePaginationModelChange = async (model: {
    page: number;
    pageSize: number;
  }) => {
    const newPage = model.page + 1;
    const newPageSize = model.pageSize;

    setPage(newPage);
    setPageSize(newPageSize);

    const fetchParams: FetchTestimonials = {
      page: newPage,
      limit: newPageSize,
      searchKey: searchKey || undefined,
      status: filterParams.selectedStatuses,
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchTestimonials(fetchParams));
  };

  // Transform testimonials data to include id field
  const rows = useMemo(() => {
    return testimonials.map((testimonial, index) => ({
      ...testimonial,
      id: testimonial.testimonialId,
      serialNumber: (page - 1) * pageSize + index + 1, // Calculate the serial number
    }));
  }, [testimonials, page, pageSize]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchTestimonials({ page, limit: pageSize, searchKey }));
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };
    fetchData();
  }, [dispatch, page, pageSize, searchKey]);

  const handleTitleClick = (params) => {
    navigate(
      `/testimonial-management/testimonial-details/${params.testimonialId}`,
      {
        state: { testimonial: params.testimonialId },
      }
    );
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleToggleChange = (_: React.SyntheticEvent, checked: boolean) => {
    dispatch(updateToggleStatus({ pageStatus: checked, type: "testimonial" }));
  };

  useEffect(() => {
    dispatch(fetchToggleStatus("testimonial"));
  }, [dispatch]);

  const handleSearch = (newSearchKey: string) => {
    setSearchKey(newSearchKey);
    setPage(1); // Reset to first page when searching
  };
  const handleToggleSwitch = async (event, row: any) => {
    event.stopPropagation();
    const newStatus = row.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

    try {
      // Dispatch the action to update the testimonial status
      await dispatch(
        updateTestimonialStatus({
          testimonialId: row.testimonialId,
          status: newStatus,
        })
      ).unwrap(); // Use unwrap to handle any errors

      // Fetch the updated list of testimonials
      await dispatch(fetchTestimonials({ page, limit: pageSize, searchKey }));
    } catch (error) {
      console.error("Failed to Testimonial status:", error);
    }
  };

  const handleHeaderIconClick = (columnField: string) => {
    const nextSortOrder =
      columnField === sortedColumn
        ? sortOrder === "ASC"
          ? "DESC"
          : sortOrder === "DESC"
          ? ""
          : "ASC"
        : "ASC";

    setSortedColumn(nextSortOrder === "" ? "" : columnField);
    setSortOrder(nextSortOrder as "ASC" | "DESC" | "");

    // Create properly typed sort parameters
    const sortParams = nextSortOrder
      ? {
          sortBy: columnField,
          sortOrder: nextSortOrder as "ASC" | "DESC",
        }
      : {};

    dispatch(
      fetchTestimonials({
        page,
        limit: pageSize,
        ...sortParams,
        searchKey: searchKey || undefined,
      })
    );
  };

  const getSortIcon = (
    columnField: string,
    sortedColumn: string,
    sortOrder: string
  ) => {
    if (columnField === sortedColumn) {
      if (sortOrder === "ASC") return <NorthIcon />;
      if (sortOrder === "DESC") return <SouthIcon />;
    }
    return <ImportExportIcon />;
  };

  const handleDelete = (event, row: any) => {
    event.stopPropagation();
    setSelectedRow(row);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!selectedRow) return;
    setDeleting(true);
    try {
      await dispatch(deleteTestimonial(selectedRow.testimonialId)).unwrap();

      toast.success("Testimonial deleted successfully", {
        theme: "dark",
        icon: ({ theme, type }) => <DeleteForeverIcon />,
      });
    } catch (error) {
      console.error("Failed to delete testimonial:", error);
    } finally {
      await dispatch(fetchTestimonials({ page, limit: pageSize, searchKey }));

      setDeleting(false);
      setModalOpen(false);
    }
  };

  const handleFilterSubmit = async (filters: FilterParams) => {
    setFilterParams(filters);

    const fetchParams: FetchTestimonials = {
      page: 1,
      limit: pageSize,
      searchKey: searchKey || undefined,
      status: filters.selectedStatuses,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    };
    console.log(fetchParams, "fetchParams");

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchTestimonials(fetchParams));
    setPage(1);
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S.no",
      flex: isMobileScreen ? 1 : 0.5,
      minWidth: isMobileScreen ? 80 : undefined,
      headerClassName: "column-header-bg-green",
      resizable: false,
      valueGetter: (params) => params, // Use the calculated serial number
      renderCell: (params) => <span>{params.row.serialNumber}</span>,
    },
    {
      field: "title",
      headerName: "Title",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      resizable: false,
      minWidth: isMobileScreen ? 120 : undefined,
      renderCell: (params) => (
        <span
          style={{
            textDecoration: "underline",
            fontSize: "14px",
            fontWeight: "700",
            cursor: "pointer",
            color: "#3BC1CD",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleTitleClick(params.row)}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: isMobileScreen ? 2 : 1,
      headerClassName: "column-header-bg-green",
      resizable: false,
      minWidth: isMobileScreen ? 250 : undefined,
    },
    {
      field: "name",
      headerName: "User Name",
      flex: isMobileScreen ? 2 : 1.5,
      headerClassName: "column-header-bg-green",
      resizable: false,
      minWidth: isMobileScreen ? 120 : undefined,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>User Name</span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleHeaderIconClick("name");
            }}
            aria-label="sort-toggle"
            size="small"
            sx={{
              color:
                sortedColumn === "name" && sortOrder !== "" ? "black" : "black",
              "&:hover": {
                backgroundColor: "#3bc1cd",
                color: "white",
              },
            }}
          >
            {getSortIcon("name", sortedColumn, sortOrder)}
          </IconButton>
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Added on",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      resizable: false,
      minWidth: isMobileScreen ? 100 : undefined,
      valueGetter: (params) => {
        return params;
      },
      renderCell: (params) => {
        try {
          if (!params.value) return "";
          // Format the date using dayjs
          const formattedDate = dayjs(params.value).format("MMM DD, YYYY");
          return formattedDate;
        } catch (error) {
          console.error("Error formatting date:", error);
          return params.value; // Return raw value if formatting fails
        }
      },
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Joined On</span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleHeaderIconClick("createdAt");
            }}
            aria-label="sort-toggle"
            size="small"
            sx={{
              color:
                sortedColumn === "createdAt" && sortOrder !== ""
                  ? "black"
                  : "black",
              "&:hover": {
                backgroundColor: "#3bc1cd",
                color: "white",
              },
            }}
          >
            {getSortIcon("createdAt", sortedColumn, sortOrder)}
          </IconButton>
        </div>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      resizable: false,
      minWidth: isMobileScreen ? 90 : undefined,
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "24px",
            padding: "4px 8px",
            backgroundColor: params.value === "ACTIVE" ? "#52F0BE" : "#F0F0F0",
            color: params.value === "ACTIVE" ? "#0B6067" : "#9DA4AE",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      resizable: false,
      minWidth: isMobileScreen ? 120 : undefined,
      renderCell: (params) => (
        <div className="flex items-center gap-2">
          <Switch
            checked={params.row.status === "ACTIVE"}
            onChange={(event) => handleToggleSwitch(event, params.row)}
            color="primary"
          />
          <div
            onClick={(event) => handleDelete(event, params.row)}
            className="p-2 rounded-lg cursor-pointer"
            style={{ border: "1px solid #C4C4C4" }}
          >
            <img src="/assets/delete-Icon.png" alt="deleteIcon" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6">
      <DataTable
        rows={rows}
        columns={columns}
        filterComponent={
          <CommonFilter
            statusOptions={testimonialStatusOptions}
            isMobileScreen={isMobileScreen}
            onFilterSubmit={handleFilterSubmit}
            currentFilters={filterParams}
          />
        }
        // Pagination props
        paginationModel={{
          page: page - 1, // Convert to 0-based for DataGrid
          pageSize,
        }}
        pageSizeOptions={[10, 25, 50]}
        rowCount={totalCount || 0}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        searchPlaceholder="Search by Title or User Name"
        onSearch={handleSearch}
        filterable
        loading={loading}
        customAction={
          <div>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                fontSize: "16px",
                backgroundColor: "#3BC1CD",
                textTransform: "none",
              }}
              onClick={() =>
                navigate("/testimonial-management/add-newFeedback")
              }
            >
              {!isMobileScreen ? "Add New Feedback" : <AddCommentIcon />}
            </Button>
          </div>
        }
        masterToggle={
          <FormGroup sx={{ marginRight: "20px" }}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch checked={pageStatus} onChange={handleToggleChange} />
              }
              label="Home screen visibility"
              onClick={(e) => e.stopPropagation()}
            />
          </FormGroup>
        }
        disableRowSelectionOnClick
      />
      <CommonConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Confirm Deletion"
        description="Are you sure you want to delete this testimonial?"
        confirmButtonText="Yes, Delete"
        onConfirm={confirmDelete}
        loading={deleting}
      />
    </div>
  );
};

export default TestimonialManagement;
