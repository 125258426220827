import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../dataTable/index.ts"; // Correct import
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import {
  fetchBookingDetails,
  fetchBookings,
} from "../../../redux/features/booking/bookingSlice.tsx";
import { AppDispatch, RootState } from "../../../redux/store";
import { FetchBookingsParams } from "../../../redux/features/booking/types.tsx";
import dayjs from "dayjs";
import CommonFilter from "../../commonComponents/CommonFilter.tsx";

interface FilterParams {
  selectedStatuses: string[];
  fromDate?: string;
  toDate?: string;
}

const BookingManagement = () => {
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [sortedColumn, setSortedColumn] = useState<string>("");
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch bookings from Redux state
  const {
    bookings: bookingData,
    loading,
    totalCount,
    error,
  } = useSelector((state: RootState) => state.bookings);

  const bookingRequestData = bookingData?.data.data;

  const [filterParams, setFilterParams] = useState<FilterParams>({
    selectedStatuses: [],
    fromDate: undefined,
    toDate: undefined,
  });

  const bookingRequestStatus = [
    { value: "Assigned", label: "Assigned" },
    { value: "Unassigned", label: "Unassigned" },
    { value: "Rejected", label: "Rejected" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  const handlePaginationModelChange = async (model: {
    page: number;
    pageSize: number;
  }) => {
    const newPage = model.page + 1;
    const newPageSize = model.pageSize;

    setPage(newPage);
    setPageSize(newPageSize);

    const fetchParams: FetchBookingsParams = {
      page: newPage,
      limit: newPageSize,
      searchKey: searchKey || undefined,
      status: filterParams.selectedStatuses,
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchBookings(fetchParams));
  };

  const handleFilterSubmit = async (filters: FilterParams) => {
    setFilterParams(filters);

    const fetchParams: FetchBookingsParams = {
      page: 1,
      limit: pageSize,
      searchKey: searchKey || undefined,
      status: filters.selectedStatuses,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchBookings(fetchParams));
    setPage(1);
  };

  // Transform users with proper typing
  const rows = useMemo(() => {
    return bookingRequestData?.map((booking, index) => ({
      ...booking,
      id: booking.bookingReqId,
      serialNumber: (page - 1) * pageSize + index + 1, // Calculate the serial number
    }));
  }, [bookingRequestData, page, pageSize]); // This will update whenever fetchedUsers changes

  useEffect(() => {
    const fetchParams: FetchBookingsParams = {
      page: 1,
      limit: pageSize,
    };
    dispatch(fetchBookings(fetchParams));
  }, []);

  const handleSearch = async (newSearchKey: string) => {
    setSearchKey(newSearchKey);
    setPage(1); // Reset to first page when searching

    // Make API call with current filters and new search key
    const fetchParams: FetchBookingsParams = {
      page: 1,
      limit: pageSize,
      searchKey: newSearchKey || undefined,
      status: filterParams.selectedStatuses,
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchBookings(fetchParams));
  };

  const handleNameClick = (row) => {
    dispatch(fetchBookingDetails(row.bookingReqId)).then(() => {
      navigate(`/booking-details/${row.bookingReqId}`, {
        state: { bookingId: row.bookingReqId },
      });
    });
  };

  const handleHeaderIconClick = (columnField: string) => {
    const nextSortOrder =
      columnField === sortedColumn
        ? sortOrder === "ASC"
          ? "DESC"
          : sortOrder === "DESC"
          ? ""
          : "ASC"
        : "ASC";

    setSortedColumn(nextSortOrder === "" ? "" : columnField);
    setSortOrder(nextSortOrder as "ASC" | "DESC" | "");

    // Create properly typed sort parameters
    const sortParams: Partial<FetchBookingsParams> = nextSortOrder
      ? {
          sortBy: columnField,
          sortOrder: nextSortOrder as "ASC" | "DESC",
        }
      : {};

    dispatch(
      fetchBookings({
        page,
        limit: pageSize,
        ...sortParams,
        searchKey: searchKey || undefined,
      })
    );
  };

  const getSortIcon = (
    columnField: string,
    sortedColumn: string,
    sortOrder: string
  ) => {
    if (columnField === sortedColumn) {
      if (sortOrder === "ASC") return <NorthIcon />;
      if (sortOrder === "DESC") return <SouthIcon />;
    }
    return <ImportExportIcon />;
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S.no",
      flex: isMobileScreen ? 1 : 0.5,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 80 : undefined,
      resizable: false,
      valueGetter: (params) => params, // Use the calculated serial number
      renderCell: (params) => <span>{params.row.serialNumber}</span>,
    },
    {
      field: "unitTypeCode",
      headerName: "Unit Name",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 200 : undefined,
      resizable: false,
      renderCell: (params) => (
        <span
          style={{
            textDecoration: "underline",
            fontSize: "14px",
            fontWeight: "700",
            cursor: "pointer",
            color: "#3BC1CD",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() => handleNameClick(params.row)}
        >
          {params.value}
        </span>
      ),
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Unit Name</span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleHeaderIconClick("unitTypeCode");
            }}
            aria-label="sort-toggle"
            size="small"
            sx={{
              color:
                sortedColumn === "unitTypeCode" && sortOrder !== ""
                  ? "black"
                  : "black",
              "&:hover": {
                backgroundColor: "#3bc1cd",
                color: "white",
              },
            }}
          >
            {getSortIcon("unitTypeCode", sortedColumn, sortOrder)}
          </IconButton>
        </div>
      ),
    },
    {
      field: "property",
      headerName: "Property",
      flex: isMobileScreen ? 2 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 150 : undefined,
      resizable: false,
    },
    {
      field: "nameId",
      headerName: "Name ID",
      flex: isMobileScreen ? 2 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 120 : undefined,
      resizable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 130 : undefined,
      resizable: false,
    },
    {
      field: "guests",
      headerName: "Total Guests",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 200 : undefined,
      resizable: false,
      renderCell: (params) => (
        <span>{`${params.row.guests.adults} Adults, ${params.row.guests.children} Children`}</span>
      ),
    },
    {
      field: "requestStatusDescription",
      headerName: "Status",
      flex: isMobileScreen ? 1.5 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 100 : undefined,
      resizable: false,
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "24px",
            padding: "4px 8px",
            backgroundColor: "rgba(169, 237, 243, 0.3)",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "sysDate",
      headerName: "Created Date",
      flex: isMobileScreen ? 2 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 250 : 300,
      valueGetter: (params) => {
        return params;
      },
      renderCell: (params) => {
        try {
          if (!params.value) return "";
          // Format the date using dayjs
          const formattedDate = dayjs(params.value).format("MMM DD, YYYY");
          return formattedDate;
        } catch (error) {
          console.error("Error formatting date:", error);
          return params.value; // Return raw value if formatting fails
        }
      },
    },
  ];

  return (
    <div className="p-6">
      <DataTable
        rows={rows || []}
        columns={columns}
        searchPlaceholder={"Search by Unit Name, Name Id, Property or Phone"}
        onSearch={handleSearch}
        filterable
        filterComponent={
          <CommonFilter
            statusOptions={bookingRequestStatus}
            isMobileScreen={isMobileScreen}
            onFilterSubmit={handleFilterSubmit}
            currentFilters={filterParams}
          />
        }
        // Pagination props
        paginationModel={{
          page: page - 1, // Convert to 0-based for DataGrid
          pageSize,
        }}
        pageSizeOptions={[10, 25, 50]}
        rowCount={totalCount || 0}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        loading={loading}
        disableRowSelectionOnClick
      />
      {/* )} */}
    </div>
  );
};

export default BookingManagement;
