import React, { useEffect, useState } from "react";
import { Button, Box, Typography, CircularProgress } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import CustomTextField from "../../commonComponents/CustomTextfield.tsx";
import ResetPasswordModal from "./ResetPasswordModal.tsx";
import PhoneInput from "react-phone-input-2";

import {
  fetchProfile,
  updatePhoneNumber,
} from "../../../redux/features/profile/profileSlice.tsx";
import { useAppSelector } from "../../../hooks/useAppSelector.tsx";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import { toast } from "react-toastify";
import PhoneInputField from "./PhoneInputField.tsx";

const Profile = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: profile,
    isLoadingProfile,
    isUpdating,
  } = useAppSelector((state) => state.profile);
  const profileData = profile?.data;

  const [phone, setPhone] = useState(profile?.data?.phoneNumber || "");
  const [isPhoneChanged, setIsPhoneChanged] = useState(false);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile?.data?.phoneNumber) {
      setPhone(profile.data.phoneNumber);
    }
  }, [profile]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
    setIsPhoneChanged(e.target.value !== profile?.data?.phoneNumber);
  };
  // const handleSaveChanges = async () => {
  //   if (isPhoneChanged) {
  //     try {
  //       const result = await dispatch(updatePhoneNumber(phone));

  //       if (result.meta.requestStatus === "fulfilled") {
  //         // Extract and set the message from the API response
  //         const responseMessage =
  //           result.payload?.message || "Profile updated successfully";
  //         toast.success(responseMessage);
  //         await dispatch(fetchProfile());
  //       }
  //     } catch (error) {
  //       console.error("Error updating phone number:", error);
  //       toast.error("Failed to update profile");
  //     }
  //   }
  // };

  const handleSaveChanges = async () => {
    if (isPhoneChanged) {
      try {
        const result = await dispatch(updatePhoneNumber(phone)).unwrap();

        // Show a success message
        toast.success(result.message || "Profile updated successfully");

        // Fetch the latest profile data
        await dispatch(fetchProfile());

        setIsPhoneChanged(false);
      } catch (error: any) {
        // Use the message from the state
        if (error.errors?.phoneNumber) {
          toast.error(error.errors.phoneNumber);
        } else {
          toast.error(error.message || "Failed to update profile");
        }
      }
    }
  };
  const handleResetPassword = () => {
    setIsModalOpen(true);
  };

  const formatCardType = (type: string) => {
    if (!type) return "NA";
    return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
  };

  if (isLoadingProfile)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-5 bg-[#A9EDF382] rounded-[10px] px-4 md:px-6 py-0 md:py-2">
        <h2 className="text-base md:text-2xl font-normal text-[#004135] py-[19px]">
          Update my profile
        </h2>
        <Button
          variant="contained"
          onClick={handleResetPassword}
          sx={{
            background: "#3BC1CD",
            color: "#ffffff",
            fontWeight: 400,
            textTransform: "none",
            borderRadius: "8px",
            fontSize: {
              xs: "12px",
              md: "16px",
            },
          }}
        >
          Reset Password
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full md:w-[70%]">
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Full Name
          </Typography>
          <CustomTextField
            backgroundd="#E1E0E0"
            fullWidth
            value={profileData?.name}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <PersonIcon style={{ marginRight: "10px", color: "#C4C4C4" }} />
              ),
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Role{" "}
          </Typography>
          <CustomTextField
            backgroundd="#E1E0E0"
            fullWidth
            value={formatCardType(profileData?.role)}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <SubtitlesIcon
                  style={{ marginRight: "10px", color: "#C4C4C4" }}
                />
              ),
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Email Address
          </Typography>
          <CustomTextField
            backgroundd="#E1E0E0"
            fullWidth
            value={profileData?.email}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <MailIcon style={{ marginRight: "10px", color: "#C4C4C4" }} />
              ),
            }}
          />
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "8px",
              fontSize: "14px",
              fontWeight: "400",
              color: "#979797",
            }}
          >
            Phone Number
          </Typography>
          <PhoneInputField value={phone} onChange={handlePhoneChange} />
        </Box>
        <div className="mt-4 md:mt-[30px] text-right md:text-left">
          <Button
            variant="contained"
            onClick={handleSaveChanges}
            disabled={!isPhoneChanged}
            sx={{
              color: "#fff",
              backgroundColor: "#3BC1CD",
              fontSize: "16px",
              fontWeight: "700",
              padding: "12px 55px",
            }}
          >
            {isUpdating ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>

      {/* Include ResetPasswordModal */}
      <ResetPasswordModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default Profile;
