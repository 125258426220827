import React, { useState } from "react";
import Navbar from "../signin/Navbar.tsx";
import LeftPanel from "../signin/LeftPanel.tsx";
import { Link, useNavigate } from "react-router-dom";
import { InputAdornment, TextField } from "@mui/material";
import { Email } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { forgotPassword } from "../../redux/features/forgot-reset-Password/forgotPasswordSlice.tsx";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { loading, success, error } = useSelector((state: RootState) => state.forgotPassword);

  const handleSubmit = async () => {
    try {
      await dispatch(forgotPassword(email)).unwrap();
      if (success) {
        // This will be updated automatically by Redux state
      }
    } catch (err) {
      // Handle the error appropriately
    }
  };

  const handleBackToLogin = () => {
    navigate("/signin");
  };

  return (
    <div className="h-screen flex flex-col">
      <Navbar />
      <div className="flex flex-1">
        <div className="w-1/2 hidden md:block">
          <LeftPanel />
        </div>

        <div className="w-full md:w-1/2 flex flex-col justify-center p-8 px-3 sm:px-8 lg:px-32">
          {success ? (
            <div className="text-center">
              <h2 className="text-[44px] font-bold mb-4 text-[#3D3A3A]">
                Reset Link
              </h2>
              <p className="text-xl text-[#9DA4AE] mb-6">
                The verification link email has been sent to your registered
                email ID. Please follow the instructions to reset your password.
              </p>
              <button
                onClick={handleBackToLogin}
                className="w-full py-2 px-4 text-white rounded-lg bg-[#3BC1CD]"
              >
                Back to login
              </button>
            </div>
          ) : (
            <>
              <h2 className="text-[44px] font-bold mb-4 text-center">
                Forgot Password?
              </h2>
              <p className="text-xl mb-6 text-center text-[#9DA4AE]">
                Please enter your registered email address, we will send the
                verification link with the associated email address.
              </p>
              <form className="flex flex-col gap-4">
                <TextField
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!error}
                  helperText={error || ""}
                  placeholder="Your Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email style={{ color: "#B0B0B0" }} />
                      </InputAdornment>
                    ),
                    style: {
                      border: "1px solid #C4C4C4",
                      borderRadius: "10px",
                      padding: "0px 14px",
                      background: "#fff",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                    input: {
                      color: "#333333",
                    },
                    "& .MuiFormHelperText-root": {
                      fontSize: "16px",
                    },
                  }}
                />

                <button
                  type="button"
                  onClick={handleSubmit}
                  className={`py-2 px-4 text-white rounded-lg ${
                    email ? "bg-[#3BC1CD]" : "bg-[#DADADA]"
                  }`}
                  disabled={!email || loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </form>
              <Link to={"/signin"} className="text-[black] mt-4 text-center">
                Back to login
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;