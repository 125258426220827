import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Autocomplete,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import debounce from "lodash/debounce";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux/store";

export interface Option {
    id: string | number;
    label: string;
    sublabel?: string;
  }

interface AsyncSelectProps {
  value: string;
  onChange: (value: Option | null) => void; // Change to pass the entire Option object
  placeholder?: string;
  fetchOptions: (
    dispatch: ThunkDispatch<RootState, void, AnyAction>,
    limit: number,
    search: string
  ) => Promise<any>;
  dispatch: ThunkDispatch<RootState, void, AnyAction>;
  type: 'user' | 'property';
}

const AsyncSelect = ({
  value,
  onChange,
  placeholder = "Select",
  fetchOptions,
  dispatch,
  type
}: AsyncSelectProps) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(10);
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const transformData = (result: any): Option[] => {
    if (type === 'property') {
      return (result.data?.data || []).map((item: any) => ({
        id: item.propertyId,
        label: item.propertyName,
        // sublabel: `ID: ${item.propertyId}`
      }));
    } else {
      return (result.data || []).map((item: any) => ({
        id: item.nameId || item.id,
        label: item.username || item.name,
        sublabel: item.nameId || item.userId
      }));
    }
  };

  const fetchData = async (limit: number, search: string) => {
    setLoading(true);
    try {
      const result = await fetchOptions(dispatch, limit, search);
      const transformedOptions = transformData(result);
      setOptions(transformedOptions);
      if (type === 'property') {
        setHasMore(result.data?.nextPage !== -1);
      } else {
        setHasMore(transformedOptions.length >= limit);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(limit, searchText);
  }, [limit, searchText, open]);

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      setLimit(10);
      setOptions([]);
      fetchData(10, searchValue);
    }, 300),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const list = event.currentTarget;
    if (
      list.scrollTop + list.clientHeight >= list.scrollHeight - 50 &&
      !loading &&
      hasMore
    ) {
      const newLimit = limit + 10;
      setLimit(newLimit);
      fetchData(newLimit, searchText);
    }
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      getOptionLabel={(option) => option.label}
      value={options.find((option) => option.label === value) || null}
      onChange={(_, newValue) => {
        onChange(newValue || null); // Pass the entire Option object or null
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      ListboxProps={{
        onScroll: handleScroll,
        style: { maxHeight: "200px" },
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          key={option.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '8px 16px'
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>{option.label}</Typography>
          {option.sublabel && (
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: 'text.secondary'
              }}
            >
              {option.sublabel}
            </Typography>
          )}
        </Box>
      )}
      filterOptions={(x) => x}
    />
  );
};

export default AsyncSelect;