import React, { useState, useMemo, useEffect } from "react";
import { DataTable } from "../../dataTable/index.ts";
import { GridColDef } from "@mui/x-data-grid";
import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../redux/store.tsx";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  fetchUserDetails,
  updateUserStatus,
} from "../../../redux/features/userManagement/userManagementThunk.tsx";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { FetchUserParams } from "../../../redux/features/userManagement/types.tsx";
import dayjs from "dayjs";
import CommonFilter from "../../commonComponents/CommonFilter.tsx";
import CommonConfirmationModal from "../../commonComponents/CommonConfirmationModal.tsx";
import { toast } from "react-toastify";

interface FilterParams {
  selectedStatuses: string[];
  selectedCardTypes: string[];
  fromDate?: string;
  toDate?: string;
}

const UserManagement: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    users: fetchedUsers,
    loading,
    error,
    totalCount,
    limit,
    nextPage,
    totalPages,
  } = useSelector((state: RootState) => state.userManagement);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [sortedColumn, setSortedColumn] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [updating, setUpdating] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Add new state for filter params
  const [filterParams, setFilterParams] = useState<FilterParams>({
    selectedStatuses: [],
    selectedCardTypes: [],
    fromDate: undefined,
    toDate: undefined,
  });

  // Status options for the user management filters
  const userStatusOptions = [
    { value: "ACTIVE", label: "ACTIVE" },
    { value: "INACTIVE", label: "INACTIVE" },
    { value: "BLOCKED", label: "BLOCKED" },
  ];

  // Add cardTypeOptions constant
  const cardTypeOptions = [
    { value: "ON", label: "ON" },
    { value: "OFF", label: "OFF" },
    { value: "REQUESTED", label: "REQUESTED" },
  ];

  // Function to handle filter submission
  const handleFilterSubmit = async (filters: FilterParams) => {
    setFilterParams(filters);

    const fetchParams: FetchUserParams = {
      page: 1,
      limit: pageSize,
      searchKey: searchKey || undefined,
      status: filters.selectedStatuses,
      cardType: filters.selectedCardTypes,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    };
    console.log(fetchParams, "fetchParams");

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await fetchUserData(fetchParams);
    setPage(1);
  };

  // Update fetchUserData to include filter params
  const fetchUserData = async (params: FetchUserParams) => {
    try {
      await dispatch(
        fetchUser({
          ...params,
          status: params.status || filterParams.selectedStatuses,
          cardType: params.cardType || filterParams.selectedCardTypes,
          fromDate: params.fromDate || filterParams.fromDate,
          toDate: params.toDate || filterParams.toDate,
        })
      ).unwrap();
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Update other pagination and search handlers to include filter params
  const handlePaginationModelChange = async (model: {
    page: number;
    pageSize: number;
  }) => {
    const newPage = model.page + 1;
    const newPageSize = model.pageSize;

    setPage(newPage);
    setPageSize(newPageSize);

    const fetchParams: FetchUserParams = {
      page: newPage,
      limit: newPageSize,
      searchKey: searchKey || undefined,
      status: filterParams.selectedStatuses,
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await fetchUserData(fetchParams);
  };

  // Transform users with proper typing and include serial number
  const rows = useMemo(() => {
    return fetchedUsers.map((user, index) => ({
      ...user,
      id: user.nameId,
      serialNumber: (page - 1) * pageSize + index + 1, // Calculate the serial number
    }));
  }, [fetchedUsers, page, pageSize]);

  // Initial data fetch
  useEffect(() => {
    // console.log("i am initial fetch");
    const initialFetch = async () => {
      const fetchParams: FetchUserParams = {
        page,
        limit: pageSize,
        searchKey: searchKey || undefined,
      };

      if (sortedColumn && sortOrder) {
        fetchParams.sortBy = sortedColumn;
        fetchParams.sortOrder = sortOrder;
      }

      await fetchUserData(fetchParams);
    };

    initialFetch();
  }, []);

  const handleSearch = async (newSearchKey: string) => {
    setSearchKey(newSearchKey);
    setPage(1); // Reset to first page when searching

    const fetchParams: FetchUserParams = {
      page: 1,
      limit: pageSize,
      searchKey: newSearchKey || undefined,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await fetchUserData(fetchParams);
  };

  const handleHeaderIconClick = async (columnField: string) => {
    const nextSortOrder =
      columnField === sortedColumn
        ? sortOrder === "ASC"
          ? "DESC"
          : sortOrder === "DESC"
          ? ""
          : "ASC"
        : "ASC";

    setSortedColumn(nextSortOrder === "" ? "" : columnField);
    setSortOrder(nextSortOrder as "ASC" | "DESC" | "");

    const fetchParams: FetchUserParams = {
      page,
      limit: pageSize,
      searchKey: searchKey || undefined,
      ...(nextSortOrder && {
        sortBy: columnField,
        sortOrder: nextSortOrder as "ASC" | "DESC",
      }),
    };

    await fetchUserData(fetchParams);
  };

  const handleNameClick = (params) => {
    dispatch(fetchUserDetails(params.nameId)).then((result) => {
      if (fetchUserDetails.fulfilled.match(result)) {
        navigate(`/user-details/${params.nameId}`, {
          state: { user: result.payload },
        });
      }
    });
  };

  const getSortIcon = (
    columnField: string,
    sortedColumn: string,
    sortOrder: string
  ) => {
    if (columnField === sortedColumn) {
      if (sortOrder === "ASC") return <NorthIcon />;
      if (sortOrder === "DESC") return <SouthIcon />;
    }
    return <ImportExportIcon />;
  };
  // Update handleActionClick to open modal
  const handleActionClick = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const handleConfirmStatusUpdate = async () => {
    if (!selectedUser) return;

    const newStatus = selectedUser.status === "ACTIVE" ? "BLOCKED" : "ACTIVE";
    setUpdating(true);

    try {
      await dispatch(
        updateUserStatus({ nameId: selectedUser?.nameId, status: newStatus })
      ).unwrap();
      toast.success(`User has been ${newStatus}`)
      await dispatch(fetchUser({ page, limit: pageSize, searchKey }));
    } catch (error) {
      console.error("Failed to update user status:", error);
    } finally {
      setUpdating(false);
      setModalOpen(false);
      setSelectedUser(null);
    }
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedUser(null);
    setUpdating(false);
  };

  const columns = useMemo(() => {
    const baseColumns: GridColDef[] = [
      {
        field: "serialNumber",
        headerName: "S.no",
        flex: isMobileScreen ? undefined : 0.5,
        width: isMobileScreen ? 70 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
        valueGetter: (params) => params, // Use the calculated serial number
        renderCell: (params) => <span>{params.row.serialNumber}</span>, // Display the serial number
      },
      {
        field: "username",
        headerName: "User Name",
        flex: isMobileScreen ? undefined : 1,
        width: isMobileScreen ? 150 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
        renderCell: (params) => {
          const value = params.value ?? "--NA--"; // Using "__" if value is null or undefined
          return (
            <span
              style={{
                textAlign: "center",
                textDecoration: "underline",
                fontSize: "14px",
                fontWeight: "700",
                cursor: "pointer",
                color: "#3BC1CD",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={() => handleNameClick(params.row)}
            >
              {value}
            </span>
          );
        },
        renderHeader: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>User Name</span>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleHeaderIconClick("username");
              }}
              aria-label="sort-toggle"
              size="small"
              sx={{
                color:
                  sortedColumn === "username" && sortOrder !== ""
                    ? "black"
                    : "black",
                "&:hover": {
                  backgroundColor: "#3bc1cd",
                  color: "white",
                },
              }}
            >
              {getSortIcon("username", sortedColumn, sortOrder)}
            </IconButton>
          </div>
        ),
      },
      {
        field: "nameId",
        headerName: "Name Id",
        flex: isMobileScreen ? undefined : 1,
        width: isMobileScreen ? 120 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
      },
      {
        field: "email",
        headerName: "Email Address",
        flex: isMobileScreen ? undefined : 1.5,
        width: isMobileScreen ? 200 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
        renderCell: (params) => (
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {params.value}
          </span>
        ),
      },
      {
        field: "autoDebit",
        headerName: "Auto Debit",
        flex: isMobileScreen ? undefined : 1,
        width: isMobileScreen ? 120 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
        renderCell: (params) => {
          const userCard = params.row.userCards?.[0];
          const cardType = userCard?.cardType;
          // function to capitalize the first letter
          const formatCardType = (type: string) => {
            if (!type) return "Off";
            return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
          };

          return formatCardType(cardType);
        },
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
        flex: isMobileScreen ? undefined : 1,
        width: isMobileScreen ? 140 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
      },
      {
        field: "createdDate",
        headerName: "Joined On",
        flex: isMobileScreen ? undefined : 1,
        width: isMobileScreen ? 120 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
        valueGetter: (params) => {
          return params;
        },
        renderCell: (params) => {
          try {
            if (!params.value) return "";
            // Format the date using dayjs
            const formattedDate = dayjs(params.value).format("MMM DD, YYYY");
            return formattedDate;
          } catch (error) {
            console.error("Error formatting date:", error);
            return params.value; // Return raw value if formatting fails
          }
        },
        renderHeader: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>Joined On</span>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleHeaderIconClick("createdDate");
              }}
              aria-label="sort-toggle"
              size="small"
              sx={{
                color:
                  sortedColumn === "createdDate" && sortOrder !== ""
                    ? "black"
                    : "black",
                "&:hover": {
                  backgroundColor: "#3bc1cd",
                  color: "white",
                },
              }}
            >
              {getSortIcon("createdDate", sortedColumn, sortOrder)}
            </IconButton>
          </div>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: isMobileScreen ? undefined : 1,
        width: isMobileScreen ? 100 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
        renderCell: (params) => {
          let backgroundColor;
          let textColor;

          switch (params.value) {
            case "ACTIVE":
              backgroundColor = "#52F0BE";
              textColor = "#0B6067";
              break;
            case "INACTIVE":
              backgroundColor = "#D8D8D8";
              textColor = "#707070";
              break;
            case "BLOCKED":
              backgroundColor = "#FFD6D6";
              textColor = "#9F2E42";
              break;
            default:
              backgroundColor = "#FFFFFF";
              textColor = "#000000";
          }

          return (
            <span
              style={{
                borderRadius: "24px",
                padding: "4px 8px",
                backgroundColor: backgroundColor,
                color: textColor,
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              {params.value}
            </span>
          );
        },
      },
      {
        field: "actions",
        headerName: "Action",
        flex: isMobileScreen ? undefined : 1,
        width: isMobileScreen ? 100 : undefined,
        headerClassName: "column-header-bg-green",
        resizable: false,
        renderCell: (params) => {
          const isBlocked = params.row.status === "BLOCKED";
          const isInactive = params.row.status === "INACTIVE";

          return (
            <Button
              variant="outlined"
              size="small"
              disabled={isInactive || loading}
              style={{
                backgroundColor: isInactive ? "#d8d8d8" : "#fff",
                color: isBlocked ? "#3BC1CD" : "#3D3A3A",
                border: "1px solid #C4C4C4",
                borderRadius: "4px",
                whiteSpace: "nowrap",
                minWidth: isMobileScreen ? "70px" : undefined,
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleActionClick(params.row);
              }}
            >
              {isBlocked ? "Unblock" : "Block"}
            </Button>
          );
        },
      },
    ];

    return baseColumns;
  }, [isMobileScreen, loading]);

  return (
    <div className="p-6">
      <DataTable
        rows={rows}
        columns={columns}
        searchPlaceholder="Search by User Name, Name Id, Email or Phone"
        onSearch={handleSearch}
        filterable
        filterComponent={
          <CommonFilter
            statusOptions={userStatusOptions}
            cardTypeOptions={cardTypeOptions}
            isMobileScreen={isMobileScreen}
            onFilterSubmit={handleFilterSubmit}
            currentFilters={filterParams}
          />
        }
        loading={loading}
        // Pagination props
        paginationModel={{
          page: page - 1, // Convert to 0-based for DataGrid
          pageSize,
        }}
        pageSizeOptions={[10, 25, 50]}
        rowCount={totalCount || 0}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        disableRowSelectionOnClick
      />
      <CommonConfirmationModal
        open={modalOpen}
        onClose={handleModalClose}
        title={`Confirm ${
          selectedUser?.status === "ACTIVE" ? "Block" : "Unblock"
        }`}
        description={`Are you sure you want to ${
          selectedUser?.status === "ACTIVE" ? "block" : "unblock"
        } this user?`}
        confirmButtonText={`Yes, ${
          selectedUser?.status === "ACTIVE" ? "Block" : "Unblock"
        }`}
        onConfirm={handleConfirmStatusUpdate}
        loading={updating}
      />
    </div>
  );
};

export default UserManagement;
