import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface ResetPasswordPayload {
  token: string;
  newPassword: string;
  confirmPassword: string;
}

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (
    { token, newPassword, confirmPassword }: ResetPasswordPayload,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.patch(
        `https://aria-vacation-dev.appskeeper.in/api/v1/admin/reset-password?token=${token}`,
        { newPassword, confirmPassword },
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_BASIC_AUTH}`,

            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ResetPasswordState {
  loading: boolean;
  success: boolean;
  error: string | null;
}

const initialState: ResetPasswordState = {
  loading: false,
  success: false,
  error: null,
};

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default resetPasswordSlice.reducer;
