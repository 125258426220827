import React, { useState, useEffect, useMemo } from "react";
import { TextField, Button, Box, Typography, Rating } from "@mui/material";
import CustomTextField, {
  CustomTextarea,
} from "../../commonComponents/CustomTextfield.tsx";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../../redux/features/userManagement/userManagementThunk.tsx";
import AsyncSelect from "./AsyncSelect.tsx";
import { fetchProperties } from "../../../redux/features/roomManagement/propertyListSlice.tsx";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import { RootState } from "../../../redux/store";
import {
  createTestimonial,
  resetTestimonialCreation,
} from "../../../redux/features/testimonialManagement/addNewTestimonial.tsx";
import { useAppSelector } from "../../../hooks/useAppSelector.tsx";
import { Option } from "./AsyncSelect.tsx";
import { toast } from "react-toastify";

interface FormData {
  title: string;
  nameId: number | null;
  name: string;
  propertyId: number | null;
  propertyName: string;
  source: string;
  rating: number;
  description: string;
}

const AddNewTestimonial = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading, error, success } = useAppSelector(
    (state: RootState) => state.testimonialCreation
  );

  const [formData, setFormData] = useState<FormData>({
    title: "",
    nameId: null,
    name: "",
    propertyId: null,
    propertyName: "",
    source: "",
    rating: 3,
    description: "",
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});

  // Add isFormValid computation using useMemo
  const isFormValid = useMemo(() => {
    return (
      formData.title.trim() !== "" &&
      formData.nameId !== null &&
      formData.propertyId !== null &&
      formData.source.trim() !== "" &&
      formData.description.trim() !== "" &&
      formData.rating > 0
    );
  }, [formData]);

  useEffect(() => {
    return () => {
      dispatch(resetTestimonialCreation());
    };
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        navigate("/testimonial-management");
      }, 1500);
    }
  }, [success, navigate]);

  const validateForm = () => {
    const newErrors: Partial<FormData> = {};

    if (!formData.title.trim()) newErrors.title = "Title is required";
    if (!formData.nameId) newErrors.name = "User selection is required";
    if (!formData.propertyId)
      newErrors.propertyName = "Property selection is required";
    if (!formData.source.trim()) newErrors.source = "Source is required";
    if (!formData.description.trim())
      newErrors.description = "Description is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const submitData = {
      ...formData,
      nameId: formData.nameId!,
      propertyId: formData.propertyId!,
    };

    dispatch(createTestimonial(submitData)).unwrap();
    toast.success("Testimonial added successfully");
  };

  const handleUserSelect = (option: Option | null) => {
    if (!option) return;
    setFormData((prev) => ({
      ...prev,
      nameId:
        typeof option.id === "number"
          ? option.id
          : parseInt(option.id as string, 10),
      name: option.label,
    }));
    setErrors((prev) => ({ ...prev, name: undefined }));
  };

  const handlePropertySelect = (option: Option | null) => {
    if (!option) return;
    setFormData((prev) => ({
      ...prev,
      propertyId:
        typeof option.id === "number"
          ? option.id
          : parseInt(option.id as string, 10),
      propertyName: option.label,
    }));
    setErrors((prev) => ({ ...prev, propertyName: undefined }));
  };

  const handleTextChange =
    (field: keyof FormData) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    };

  return (
    <div className="p-6 w-full lg:w-[80%]">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Button variant="text" onClick={() => navigate(-1)}>
          <ArrowBackIosIcon />
        </Button>
        <Typography variant="h6" sx={{ color: "#004135" }}>
          Add New Feedback
        </Typography>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px",
        }}
      >
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1, color: "#979797" }}>
            Title
          </Typography>
          <CustomTextField
            fullWidth
            placeholder="Enter Title"
            value={formData.title}
            onChange={handleTextChange("title")}
            error={!!errors.title}
            helperText={errors.title}
          />
        </Box>

        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1, color: "#979797" }}>
            User Name
          </Typography>
          <AsyncSelect
            value={formData.name}
            onChange={handleUserSelect}
            placeholder="Select User"
            fetchOptions={(dispatch, limit, search) =>
              dispatch(
                fetchUser({ page: 1, limit, searchKey: search })
              ).unwrap()
            }
            dispatch={dispatch}
            type="user"
          />
        </Box>

        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1, color: "#979797" }}>
            Associated Property
          </Typography>
          <AsyncSelect
            value={formData.propertyName}
            onChange={handlePropertySelect}
            placeholder="Select Property"
            fetchOptions={(dispatch, limit, search) =>
              dispatch(fetchProperties({ limit, searchKey: search })).unwrap()
            }
            dispatch={dispatch}
            type="property"
          />
        </Box>

        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1, color: "#979797" }}>
            Source
          </Typography>
          <CustomTextField
            fullWidth
            placeholder="Source"
            value={formData.source}
            onChange={handleTextChange("source")}
            error={!!errors.source}
            helperText={errors.source}
          />
        </Box>

        <Box sx={{ gridColumn: "span 2" }}>
          <Typography variant="subtitle1" sx={{ mb: 1, color: "#979797" }}>
            What would you rate us?
          </Typography>
          <Rating
            size="large"
            value={formData.rating}
            onChange={(_, newValue) => {
              setFormData((prev) => ({
                ...prev,
                rating: newValue || 0,
              }));
            }}
          />
        </Box>

        <Box sx={{ gridColumn: "span 2" }}>
          <Typography variant="subtitle1" sx={{ mb: 1, color: "#979797" }}>
            Description
          </Typography>
          <CustomTextarea
            value={formData.description}
            onChange={handleTextChange("description")}
            placeholder="Enter Description"
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}>
        <Button
          variant="outlined"
          sx={{
            borderColor: "#C4C4C4",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: 700,
            px: 7,
            py: 1.5,
            color: "#3D3A3A",
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            color: "#fff",
            bgcolor: "#3BC1CD",
            fontSize: "16px",
            fontWeight: 700,
            px: 7,
            py: 1.5,
            "&:disabled": {
              bgcolor: "#E0E0E0",
              color: "#A0A0A0",
            },
          }}
          onClick={handleSubmit}
          disabled={loading || !isFormValid}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Box>
    </div>
  );
};

export default AddNewTestimonial;