import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiClient from "../../../utils/apiClient.tsx";

interface SubAdminState {
  loading: boolean;
  error: string | null;
  successMessage: string;
}

interface CreateSubAdminPayload {
  name: string;
  email: string;
  password: string;
  viewModule: string[];
  editModule: string[];
}

interface ApiResponse {
  statusCode: number;
  message: string;
  data: {
    message: string;
  } | null;
}

export const createSubAdmin = createAsyncThunk<
  ApiResponse,
  CreateSubAdminPayload,
  { rejectValue: ApiResponse }
>("subAdmin/create", async (payload, { rejectWithValue }) => {
  try {
    const response = await apiClient.post("/sub-admin", {
      ...payload,
      role: "SUB_ADMIN",
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue({
      statusCode: 500,
      message: "Network error or server is down.",
      data: null,
    });
  }
});

const initialState: SubAdminState = {
  loading: false,
  error: null,
  successMessage: "",
};

const subAdminSlice = createSlice({
  name: "subAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSubAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = "";
      })
      .addCase(createSubAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage =
          action.payload.data?.message || "Sub-admin created successfully";
      })
      .addCase(createSubAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Failed to create sub-admin";
      });
  },
});

export default subAdminSlice.reducer;
