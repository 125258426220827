import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

interface FilterOption {
  value: string;
  label: string;
}

interface FilterState {
  [key: string]: boolean;
}

export interface CommonFilterProps {
  isMobileScreen?: boolean;
  statusOptions: FilterOption[];
  cardTypeOptions?: FilterOption[];
  onFilterSubmit: (filters: {
    selectedStatuses: string[];
    selectedCardTypes: string[];
    fromDate?: string;
    toDate?: string;
  }) => void;
  currentFilters?: {
    selectedStatuses?: string[];
    selectedCardTypes?: string[];
    fromDate?: string;
    toDate?: string;
  };
}

const CommonFilter: React.FC<CommonFilterProps> = ({
  isMobileScreen,
  statusOptions,
  cardTypeOptions,
  onFilterSubmit,
  currentFilters,
}) => {
  const [statusFilter, setStatusFilter] = useState<FilterState>(() => {
    const initialState: FilterState = {};
    statusOptions.forEach((option) => {
      initialState[option.value] = false;
    });
    return initialState;
  });

  const [cardTypeFilter, setCardTypeFilter] = useState<FilterState>(() => {
    const initialState: FilterState = {};
    cardTypeOptions?.forEach((option) => {
      initialState[option.value] = false;
    });
    return initialState;
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);

  useEffect(() => {
    if (currentFilters) {
      if (currentFilters.selectedStatuses) {
        const newStatusFilter: FilterState = {};
        statusOptions.forEach((option) => {
          newStatusFilter[option.value] =
            currentFilters.selectedStatuses?.includes(option.value) || false;
        });
        setStatusFilter(newStatusFilter);
      }

      if (currentFilters.selectedCardTypes) {
        const newCardTypeFilter: FilterState = {};
        cardTypeOptions?.forEach((option) => {
          newCardTypeFilter[option.value] =
            currentFilters.selectedCardTypes?.includes(option.value) || false;
        });
        setCardTypeFilter(newCardTypeFilter);
      }

      if (currentFilters.fromDate) {
        setFromDate(dayjs(currentFilters.fromDate));
      }
      if (currentFilters.toDate) {
        setToDate(dayjs(currentFilters.toDate));
      }
    }
  }, [currentFilters, statusOptions, cardTypeOptions]);

  useEffect(() => {
    if (fromDate && toDate && toDate.isBefore(fromDate)) {
      setDateError("To Date cannot be earlier than From Date");
    } else {
      setDateError(null);
    }
  }, [fromDate, toDate]);

  const handleStatusCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    setStatusFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const handleCardTypeCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    setCardTypeFilter((prev) => ({ ...prev, [name]: checked }));
  };

  const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleSubmitFilter = () => {
    const selectedStatuses = Object.entries(statusFilter)
      .filter(([_, isSelected]) => isSelected)
      .map(([status]) => status);

    const selectedCardTypes = Object.entries(cardTypeFilter)
      .filter(([_, isSelected]) => isSelected)
      .map(([cardType]) => cardType);

    const formattedFromDate = fromDate
      ? fromDate.startOf("day").toISOString()
      : undefined;

    const formattedToDate = toDate
      ? toDate.endOf("day").toISOString()
      : undefined;

    onFilterSubmit({
      selectedStatuses,
      selectedCardTypes,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
    });

    handleCloseFilter();
  };

  const handleReset = () => {
    const resetStatusFilter: FilterState = {};
    statusOptions.forEach((option) => {
      resetStatusFilter[option.value] = false;
    });
    setStatusFilter(resetStatusFilter);

    const resetCardTypeFilter: FilterState = {};
    cardTypeOptions?.forEach((option) => {
      resetCardTypeFilter[option.value] = false;
    });
    setCardTypeFilter(resetCardTypeFilter);

    setFromDate(null);
    setToDate(null);

    onFilterSubmit({
      selectedStatuses: [],
      selectedCardTypes: [],
      fromDate: undefined,
      toDate: undefined,
    });

    handleCloseFilter();
  };

  const isFilterOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        variant="outlined"
        endIcon={
          <img
            src="/assets/filter-Icon.png"
            className="h-[20px]"
            alt="filter icon"
          />
        }
        onClick={handleOpenFilter}
        sx={{
          justifyContent: "start",
          color: "#111",
          border: "1px solid #C4C4C4",
          borderRadius: "8px",
        }}
      >
        {!isMobileScreen && "Filter"}
      </Button>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          style: {
            maxHeight: "500px",
            overflowY: "auto",
          },
          className: "hide-scrollbar",
        }}
      >
        <div style={{ padding: "20px", width: "350px" }}>
          <div className="flex justify-between">
            <h3 style={{ marginBottom: "10px", fontSize: "16px" }}>
              Filter By
            </h3>
            <span className="text-[12px] cursor-pointer" onClick={handleReset}>
              Reset
            </span>
          </div>

          <h4 style={{ marginBottom: "10px", fontSize: "12px" }}>Status</h4>
          <FormGroup sx={{ gap: "12px", marginBottom: "12px" }}>
            {statusOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                sx={{
                  border: "1px solid #C4C4C4",
                  borderRadius: "8px",
                  margin: "0px",
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
                control={
                  <Checkbox
                    name={option.value}
                    checked={statusFilter[option.value]}
                    onChange={handleStatusCheckboxChange}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>

          {cardTypeOptions && (
            <>
              <h4 style={{ marginBottom: "10px", fontSize: "12px" }}>
                Auto Debit
              </h4>
              <FormGroup sx={{ gap: "12px", marginBottom: "12px" }}>
                {cardTypeOptions?.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    sx={{
                      border: "1px solid #C4C4C4",
                      borderRadius: "8px",
                      margin: "0px",
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                    control={
                      <Checkbox
                        name={option.value}
                        checked={cardTypeFilter[option.value]}
                        onChange={handleCardTypeCheckboxChange}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </FormGroup>
            </>
          )}

          <h4 style={{ marginBottom: "10px", fontSize: "14px" }}>Date Range</h4>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              value={fromDate}
              onChange={(newValue: Dayjs | null) => setFromDate(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  style: { marginBottom: "10px" },
                },
              }}
            />
            <DatePicker
              label="To Date"
              value={toDate}
              onChange={(newValue: Dayjs | null) => setToDate(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  style: { marginBottom: "10px" },
                  error: !!dateError,
                  helperText: dateError,
                },
              }}
            />
          </LocalizationProvider>

          <Button
            variant="contained"
            fullWidth
            style={{ backgroundColor: "#3BC1CD", color: "#fff" }}
            onClick={handleSubmitFilter}
            disabled={!!dateError}
          >
            Submit
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default CommonFilter;