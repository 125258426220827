// redux/notificationSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import apiClient from '../../../utils/apiClient.tsx';

interface Notification {
  redirectionId: number;
  notificationType: string;
  notificationId: number;
  title: string;
  body: string;
  isRead: boolean;
  createdAt: string;
}

interface NotificationState {
  loading: boolean;
  error: string | null;
  data: Notification[];
}

const initialState: NotificationState = {
  loading: false,
  error: null,
  data: [],
};

// Async thunk to fetch notifications
export const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async ({ page, limit }: { page: number; limit: number }) => {
    const response = await apiClient.get(
      `/notification?page=${page}&limit=${limit}`
    );
    return response.data.data.data; // Accessing nested data array
  }
);

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action: PayloadAction<Notification[]>) => {
        state.loading = false;
        state.data = [...state.data, ...action.payload]; // Append new notifications
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch notifications';
      });
  },
});

export default notificationSlice.reducer;