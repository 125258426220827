// testimonialSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import apiClient from '../../../utils/apiClient.tsx';

// Define the type for a testimonial
interface Testimonial {
  testimonialId: number;
  title: string;
  description: string;
  name: string;
  createdAt: string;
  status: string;
}

// Define the initial state type
interface TestimonialState {
  testimonials: Testimonial[];
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: TestimonialState = {
  testimonials: [],
  loading: false,
  error: null,
};

// Define the thunk
export const updateTestimonialStatus = createAsyncThunk(
  'testimonial/updateStatus',
  async ({ testimonialId, status }: { testimonialId: number; status: string }) => {
    const response = await apiClient.patch('/testimonial-management/status', {
      testimonialId,
      status,
    });
    return response.data;
  }
);

// Create the slice
const testimonialSlice = createSlice({
  name: 'testimonial',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateTestimonialStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTestimonialStatus.fulfilled, (state, action: PayloadAction<Testimonial>) => {
        state.loading = false;
        const updatedTestimonial = action.payload;
        const index = state.testimonials.findIndex(
          (t) => t.testimonialId === updatedTestimonial.testimonialId
        );
        if (index !== -1) {
          state.testimonials[index] = updatedTestimonial;
        }
      })
      .addCase(updateTestimonialStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update status';
      });
  },
});

export default testimonialSlice.reducer;