import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Card, CircularProgress, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TabMenu from "./TabMenu.tsx";
import FAQEditor from "./FAQEditor.tsx";
import { RootState } from "../../../redux/store";
import {
  FAQ,
  fetchFAQs,
  updateFAQ,
  addFAQ,
  deleteFAQ,
} from "../../../redux/features/faq/faqSlice.tsx";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import CommonConfirmationModal from "../../commonComponents/CommonConfirmationModal.tsx";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const FAQPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { faqs, isLoading, error } = useSelector(
    (state: RootState) => state.faqs
  );

  const [isEditing, setIsEditing] = useState(false);
  const [currentFAQ, setCurrentFAQ] = useState<FAQ | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(fetchFAQs(2));
  }, [dispatch]);

  const handleEditFAQ = (faq: FAQ) => {
    setCurrentFAQ(faq);
    setIsEditing(true);
  };

  const handleDeleteFAQ = (id: number) => {
    setSelectedId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedId === null) return;

    setDeleting(true);
    try {
      await dispatch(deleteFAQ(selectedId)).unwrap();
      toast.success("FAQ deleted successfully", {
        theme: "dark",
        icon: ({ theme, type }) => <DeleteForeverIcon />,
      });
      setModalOpen(false);
      // Optionally refresh the FAQ list
      dispatch(fetchFAQs(2));
    } catch (error) {
      console.error("Failed to delete FAQ:", error);
    } finally {
      setDeleting(false);
      setSelectedId(null);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentFAQ(null);
  };

  const applyTextColor = (element) => {
    if (element) {
      const spans = element.getElementsByTagName("span");
      for (let i = 0; i < spans.length; i++) {
        spans[i].style.color = "#3D3A3A";
        spans[i].style.fontSize = "18px";
      }
    }
  };

  return (
    <div className="py-6 px-9">
      <TabMenu selectedTab="/static-content/faqs" />

      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && <p>Error: {error}</p>}
      {!isEditing && !isLoading && (
        <div className="border rounded-lg shadow">
          <div className="flex justify-between items-center bg-[#E3F7F9] p-4 h-16 rounded-t-lg">
            <h1 className="font-bold text-2xl text-[#004135]">FAQs</h1>
            <Button
              variant="contained"
              style={{
                fontWeight: "700",
                fontSize: "14px",
                backgroundColor: "#3BC1CD",
                color: "#FFF",
                padding: "8px 16px",
                borderRadius: "8px",
              }}
              onClick={() => {
                setCurrentFAQ(null);
                setIsEditing(true);
              }}
            >
              Add New FAQ
            </Button>
          </div>
          {faqs.map((faq) => (
            <Card
              key={faq.contentId}
              style={{
                padding: "16px",
                margin: "12px",
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
              }}
            >
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col justify-start">
                  <div className="flex justify-start text-xl">
                    Q.
                    <strong
                      ref={(el) => applyTextColor(el)}
                      dangerouslySetInnerHTML={{ __html: faq.questionEn }}
                      style={{
                        display: "block",
                        marginBottom: "6px",
                        fontSize: "20px",
                      }}
                    />
                  </div>

                  <div>
                    <p
                      ref={(el) => applyTextColor(el)}
                      style={{
                        margin: 0,
                        color: "#3D3A3A",
                        fontWeight: "400",
                        fontSize: "18px",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 4,
                      }}
                      dangerouslySetInnerHTML={{ __html: faq.answerEn }}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <IconButton onClick={() => handleEditFAQ(faq)}>
                    <EditIcon style={{ color: "#3BC1CD" }} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFAQ(faq.contentId)}>
                    <DeleteIcon style={{ color: "red" }} />
                  </IconButton>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
      {isEditing && <FAQEditor faq={currentFAQ} onCancel={handleCancel} />}
      <CommonConfirmationModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSelectedId(null);
        }}
        title="Confirm Deletion"
        description="Are you sure you want to delete this FAQ?"
        confirmButtonText="Yes, Delete"
        onConfirm={confirmDelete}
        loading={deleting}
      />
    </div>
  );
};

export default FAQPage;
