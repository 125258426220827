import React, { useEffect, useState, ChangeEvent } from "react";
import {
  TextField,
  Switch,
  Button,
  Box,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchSubAdminDetails } from "../../../redux/features/subAdmin/subAdminDetailsSlice.tsx";
import { updateSubAdminStatus } from "../../../redux/features/subAdmin/subAdminSlice.tsx";
import { toast } from "react-toastify";

interface Permission {
  view: boolean;
  edit: boolean;
}

const EditSubAdmin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const adminId = location?.state?.subAdminDetails;

  const { data, loading, error } = useSelector(
    (state: RootState) => state.subAdminDetails
  );

  const [permissions, setPermissions] = useState<Record<string, Permission>>({
    Dashboard: { view: false, edit: false },
    "User Management": { view: false, edit: false },
    "Room Management": { view: false, edit: false },
    "Testimonial Management": { view: false, edit: false },
    "Booking Management": { view: false, edit: false },
    "Sub-Admin Management": { view: false, edit: false },
    "Content Management": { view: false, edit: false },
  });

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  useEffect(() => {
    if (adminId) {
      dispatch(fetchSubAdminDetails(parseInt(adminId)));
    }
  }, [adminId, dispatch]);

  useEffect(() => {
    if (data) {
      setName(data.name || "");
      setEmail(data.email || "");
      const updatedPermissions = { ...permissions };
      data.permissions[0]?.viewModule?.forEach((module: string) => {
        const key = formatKey(module);
        if (updatedPermissions[key]) {
          updatedPermissions[key].view = true;
        }
      });
      data.permissions[0]?.editModule?.forEach((module: string) => {
        const key = formatKey(module);
        if (updatedPermissions[key]) {
          updatedPermissions[key].edit = true;
        }
      });
      setPermissions(updatedPermissions);
    }
  }, [data]);

  const formatKey = (key: string) => {
    const mapping: Record<string, string> = {
      dashboard: "Dashboard",
      "user-management": "User Management",
      "room-management": "Room Management",
      "testimonial-management": "Testimonial Management",
      "booking-management": "Booking Management",
      "sub-admin-management": "Sub-Admin Management",
      "content-management": "Content Management",
    };
    return mapping[key] || key;
  };

  const inverseFormatKey = (key: string) => {
    const inverseMapping: Record<string, string> = {
      Dashboard: "dashboard",
      "User Management": "user-management",
      "Room Management": "room-management",
      "Testimonial Management": "testimonial-management",
      "Booking Management": "booking-management",
      "Sub-Admin Management": "sub-admin-management",
      "Content Management": "content-management",
    };
    return inverseMapping[key] || key;
  };

  const handleToggle = (key: string, type: "view" | "edit") => {
    setPermissions((prev) => {
      const currentPermission = prev[key];
      if (type === "edit") {
        return {
          ...prev,
          [key]: {
            view: true,
            edit: !currentPermission.edit,
          },
        };
      } else if (type === "view") {
        return {
          ...prev,
          [key]: {
            view: !currentPermission.view,
            edit: currentPermission.view ? false : currentPermission.edit,
          },
        };
      }
      return prev;
    });
  };

  const validateEmail = (email: string): string | null => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? null : "Invalid email format.";
  };

  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/\d/.test(password)) {
      return "Password must contain at least one number.";
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return "Password must contain at least one special character.";
    }
    return null;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      setEmailError(validateEmail(value));
    } else if (name === "password") {
      setPassword(value);
      setPasswordError(validatePassword(value));
    } else if (name === "name") {
      setName(value);
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async () => {
    if (emailError || passwordError) {
      return;
    }

    try {
      const viewModule = Object.keys(permissions)
        .filter((key) => permissions[key].view)
        .map(inverseFormatKey);
      const editModule = Object.keys(permissions)
        .filter((key) => permissions[key].edit)
        .map(inverseFormatKey);

      await dispatch(
        updateSubAdminStatus({
          adminId: parseInt(adminId),
          name,
          email,
          password,
          viewModule,
          editModule,
        })
      ).unwrap()

toast.success("Sub Admin updated successfully")
      navigate(-1); // Navigate back upon success
    } catch (error) {
      console.error("Failed to update sub-admin status", error);
      // Handle error display here
    }
  };

  const handleGoBack = () => {
    navigate("/subadmin-management");
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <div className="p-6 w-full md:w-[70%]">
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ marginBottom: "34px", color: "#004135" }}
      >
        <Button
          variant="text"
          onClick={handleGoBack}
          sx={{ fontWeight: "normal" }}
        >
          <ArrowBackIosIcon />
        </Button>
        Edit Sub-Admin
      </Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <TextField
          required
          label="Name"
          variant="outlined"
          fullWidth
          placeholder="Enter Full Name"
          name="name"
          value={name}
          onChange={handleInputChange}
          InputProps={{
            style: {
              borderRadius: "8px",
            },
          }}
        />
        <TextField
          required
          label="Email"
          variant="outlined"
          fullWidth
          placeholder="Enter Email"
          name="email"
          value={email}
          onChange={handleInputChange}
          error={!!emailError}
          helperText={emailError}
          InputProps={{
            style: {
              borderRadius: "8px",
            },
          }}
        />
      </div>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <TextField
          required
          label="Password"
          variant="outlined"
          fullWidth
          type={showPassword ? "text" : "password"}
          placeholder="Enter Password"
          name="password"
          value={password}
          onChange={handleInputChange}
          error={!!passwordError}
          helperText={passwordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handlePasswordToggle}>
                  {showPassword ? (
                    <VisibilityOff style={{ color: "#3BC1CD" }} />
                  ) : (
                    <Visibility style={{ color: "#3BC1CD" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              borderRadius: "8px",
            },
          }}
        />
      </div> */}
      <div className="border bg-[#F8F8F8] rounded-xl">
        <div className="bg-[#3BC1CD82] p-4 grid grid-cols-3 pb-2 font-bold rounded-t-xl">
          <span>Permission</span>
          <span>View</span>
          <span>Edit</span>
        </div>

        {Object.keys(permissions).map((key) => (
          <div
            key={key}
            className="grid grid-cols-3 items-center p-4 border-b last:border-0"
          >
            <span>{key}</span>
            <Switch
              checked={permissions[key].view}
              onChange={() => handleToggle(key, "view")}
              color="primary"
            />
            <Switch
              checked={permissions[key].edit}
              onChange={() => handleToggle(key, "edit")}
              color="primary"
            />
          </div>
        ))}
      </div>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}>
        <Button
          variant="outlined"
          sx={{
            borderColor: "#C4C4C4",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: 700,
            px: 7,
            py: 1.5,
            color: "#3D3A3A",
          }}
          onClick={handleGoBack}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            color: "#fff",
            bgcolor: "#3BC1CD",
            fontSize: "16px",
            fontWeight: 700,
            px: 7,
            py: 1.5,
          }}
          onClick={handleSubmit}
          disabled={!!emailError || !!passwordError || !name.trim() || !email.trim() || !password.trim()}
        >
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default EditSubAdmin;