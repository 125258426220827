import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiClient from "../../../utils/apiClient.tsx";

interface PasswordChangeResponse {
  statusCode: number;
  message: string;
}

interface PasswordChangeState {
  isLoading: boolean;
  error: string | null;
  successMessage: string | null;
}

const initialState: PasswordChangeState = {
  isLoading: false,
  error: null,
  successMessage: null,
};

export const changePassword = createAsyncThunk<
  PasswordChangeResponse, // The type of the payload for fulfilled action
  { oldPassword: string; newPassword: string }, // The type of the argument expected by the thunk
  { rejectValue: { message: string } } // The type of the payload for rejected action
>(
  "auth/changePassword",
  async (
    { oldPassword, newPassword },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.patch("/admin/change-password", {
        oldPassword,
        newPassword,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { message: "Something went wrong" });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(changePassword.fulfilled, (state, action: PayloadAction<PasswordChangeResponse>) => {
        state.isLoading = false;
        state.successMessage = action.payload.message;
      })
      .addCase(changePassword.rejected, (state, action: PayloadAction<{ message: string } | undefined>) => {
        state.isLoading = false;
        state.error = action.payload?.message || "Failed to change password";
      });
  },
});

export default authSlice.reducer;