import { createSlice } from "@reduxjs/toolkit";
import { RoomState } from "./types.tsx";
import { fetchRooms } from "./roomManagementThunks.tsx";

const initialState: RoomState = {
  rooms: [],
  loading: false,
  error: null,
  totalCount: 0,
};

const roomManagementSlice = createSlice({
  name: "roomManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRooms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRooms.fulfilled, (state, action) => {
        state.loading = false;
        state.rooms = action.payload.data;
        state.totalCount = action.payload.count;
      })
      .addCase(fetchRooms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default roomManagementSlice.reducer;
