import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchReservationsDetails } from "../../../redux/features/reservations/reservationsSlice.tsx";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReservationDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { reservationsDetails, loading, error } = useSelector(
    (state: RootState) => state.reservations
  );
  console.log(reservationsDetails, "reservationsDetails");

  // destructuring the data
  const reservationData = reservationsDetails?.data;

  useEffect(() => {
    if (id) {
      const reservationId = parseInt(id, 10);
      if (!isNaN(reservationId)) {
        dispatch(fetchReservationsDetails(reservationId));
      } else {
        console.error("Invalid reservation ID:", id);
      }
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (error && error.status === 500) {
      navigate("/not-found");
    }
  }, [error, navigate]);
  const handleGoBack = () => {
    navigate("/reservations");
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth(); // Months are zero-indexed
    const year = date.getUTCFullYear();

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[monthIndex];

    return `${day} ${month} ${year}`;
  }

  function formatGuests(guests: {
    adults: number;
    children: number;
    babies: number | null;
  }): string {
    const adultText =
      guests?.adults === 1 ? "1 adult" : `${guests?.adults} adults`;
    const childText =
      guests?.children === 1 ? "1 child" : `${guests?.children} children`;

    return `${adultText}, ${childText}`;
  }

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  const CustomPrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          zIndex: 1,
          cursor: "pointer",
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          padding: "10px",
        }}
      >
        <ArrowBackIosIcon />
      </Box>
    );
  };

  const CustomNextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          zIndex: 1,
          cursor: "pointer",
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          padding: "10px",
        }}
      >
        <ArrowBackIosIcon sx={{ transform: "rotate(180deg)" }} />
      </Box>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Box sx={{ padding: "20px", margin: "0 auto" }}>
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: "20px" }}>
        <Button
          variant="text"
          onClick={handleGoBack}
          sx={{ fontWeight: "normal" }}
        >
          <ArrowBackIosIcon />
        </Button>
        Reservation Details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {/* Property Image */}
            {reservationData?.images && reservationData.images.length > 0 ? (
              <Slider {...settings}>
                {reservationData.images.map((image: string, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      height: "400px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      component="img"
                      src={image}
                      alt={`Property Image ${index + 1}`}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                ))}
              </Slider>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: "#3D3A3A",
                  textAlign: "center",
                }}
              >
                No image found
              </Typography>
            )}
            <div>
              {/* Property Name */}
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#979797",
                  marginBottom: "8px",
                }}
              >
                Property
              </Typography>

              <Typography
                variant="h6"
                sx={{ fontSize: "24px", fontWeight: "700", color: "#3D3A3A" }}
              >
                {reservationData?.property}
              </Typography>
            </div>
            <div>
              {/* Property About */}
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#979797",
                  marginBottom: "8px",
                }}
              >
                About
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "400", fontSize: "16px", color: "#3D3A3A" }}
              >
                {reservationData?.description}
              </Typography>
            </div>
          </div>
        </Grid>

        {/* Right Section: reservation Information */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={1}
            sx={{
              borderRadius: "10px",
              padding: "24px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: "20px", fontSize: "24px", fontWeight: "400" }}
            >
              Basic Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Unit Name
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {reservationData?.unittypecode || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Booked for{" "}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {reservationData?.bookedformember}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Arrival Date
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {formatDate(reservationData?.arrival)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Departure Date
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {formatDate(reservationData?.departure)}
                </Typography>
              </Grid>
              {/* <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Total Night
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {reservationData?.noofnights}{" "}
                </Typography>
              </Grid> */}
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Total Guests
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {reservationData?.adults} adults ,{reservationData?.children}{" "}
                  children{" "}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {" "}
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Remarks
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {reservationData?.remarks || "NA"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Status
                </Typography>
                <Typography
                  sx={{
                    color:
                      reservationData?.reservationstatus === "Cancelled"
                        ? "error.main"
                        : "success.main",
                    fontWeight: "bold",
                  }}
                >
                  {reservationData?.reservationstatus}
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReservationDetails;
