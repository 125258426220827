import React, { useEffect } from "react";
import { Box, Typography, Grid, Button, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store.tsx";
import { fetchRoomDetails } from "../../../redux/features/roomManagement/roomDetailsThunk.tsx";
import Slider from "react-slick"; // Import the Slider component
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css"; // Import slick theme styles

const RoomDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { user }: any = location.state; // Get user from location state

  const { room, loading, error } = useSelector(
    (state: RootState) => state.roomDetails
  );
  console.log(room, "roomroom");
  useEffect(() => {
    if (user?.id) {
      dispatch(fetchRoomDetails(user.id)); // Fetch room details
    }
  }, [dispatch, user?.id]);

  const handleGoBack = () => {
    navigate("/room-management"); // Navigate back to the previous page
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (error) return <div>Error: {error}</div>;

  // Custom arrow components
  const CustomPrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          zIndex: 1,
          cursor: "pointer",
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          padding: "10px",
        }}
      >
        <ArrowBackIosIcon />
      </Box>
    );
  };

  const CustomNextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <Box
        onClick={onClick}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          zIndex: 1,
          cursor: "pointer",
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          padding: "10px",
        }}
      >
        <ArrowBackIosIcon sx={{ transform: "rotate(180deg)" }} />
      </Box>
    );
  };

  // Carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Box sx={{ padding: "20px", margin: "0 auto" }}>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "20px",
          fontWeight: "400",
          fontSize: "24px",
          color: "#004135",
        }}
      >
        <Button
          variant="text"
          onClick={handleGoBack}
          sx={{ fontWeight: "normal" }}
        >
          <ArrowBackIosIcon />
        </Button>
        Room Details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {/* Property Image Carousel */}
            {room?.images && room.images.length > 0 ? (
              <Slider {...settings}>
                {room.images.map((image: string, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      height: "400px", // Set a fixed height for the image container
                      overflow: "hidden", // Hide overflow to prevent whitespace
                    }}
                  >
                    <Box
                      component="img"
                      src={image}
                      alt={`Room Image ${index + 1}`}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain", // Cover the container without white spaces
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                ))}
              </Slider>
            ) : (
              <Box
                component="img"
                src="/assets/demo-booking-property.png"
                alt={"property image"}
                sx={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "10px",
                }}
              />
            )}

            {/* Property Name */}
            <div>
              <Typography
                variant="h6"
                sx={{ fontSize: "24px", fontWeight: "700", color: "#3D3A3A" }}
              >
                {room?.value}
              </Typography>
              <span className="font-normal text-base text-[#3D3A3A]">
                {room?.propertyName}
              </span>
            </div>
            <div>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#979797",
                  marginBottom: "8px",
                }}
              >
                Description
              </Typography>
              {/* Property About */}
              <Typography
                variant="body2"
                sx={{ fontWeight: "400", fontSize: "16px", color: "#3D3A3A" }}
              >
                {room?.description}
              </Typography>
            </div>
          </div>
        </Grid>

        {/* Right Section: Booking Information */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              borderRadius: "16px",
              padding: "24px",
              paddingBottom: "60px",
              height: "fit-content",
              border: "1px solid #C4C4C4",
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: "20px", fontSize: "24px", fontWeight: "400" }}
            >
              Unit Details{" "}
            </Typography>

            <div className="flex flex-col ">
              <div className="mb-3">
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Unit Name
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {room?.value}
                </Typography>
              </div>

              <div className="mb-3">
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#979797" }}
                >
                  Associated With{" "}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: "#3D3A3A",
                    fontSize: { xs: "19px", md: "24px" },
                  }}
                >
                  {room?.propertyName}{" "}
                </Typography>
              </div>
              <div className="mb-3">
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#979797",
                    marginBottom: "8px",
                  }}
                >
                  What we offer
                </Typography>
                <div className="flex flex-row gap-3">
                  {room?.amenities?.map((service: any, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-center items-center h-[80px] w-[83px] bg-[#A9EDF380] rounded-xl py-2"
                      style={{ border: "1px solid #A9EDF380" }}
                    >
                      <img
                        className="h-7 w-7 mb-2"
                        src={service?.imageUrl}
                        alt=""
                      />
                      <p className="text-[10px] font-normal text-[#3BC1CD] text-center">
                        {(service?.title).toUpperCase()}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoomDetails;
