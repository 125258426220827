import React, { useState, useEffect } from "react";
import Navbar from "../signin/Navbar.tsx";
import LeftPanel from "../signin/LeftPanel.tsx";
import { InputAdornment, IconButton, TextField, Button } from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, AppDispatch } from "../../redux/store";
import { resetPassword } from "../../redux/features/forgot-reset-Password/resetPasswordSlice.tsx";

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const {
    loading,
    success,
    error: apiError,
  } = useSelector((state: RootState) => state.resetPassword);

  // Extract token from URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (!token) {
      setError("Invalid or missing token.");
    }
  }, [token]);

  useEffect(() => {
    if (success) {
      alert("Password reset successfully!");
      navigate("/signin");
    }
  }, [success, navigate]);

  const handleResetPassword = async () => {
    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (token) {
      try {
        await dispatch(
          resetPassword({ token, newPassword, confirmPassword })
        ).unwrap();
      } catch (err) {
        // Handle error from API
      }
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <Navbar />
      <div className="flex flex-1">
        <div className="w-1/2 hidden md:block">
          <LeftPanel />
        </div>
        <div className="w-full md:w-1/2 flex flex-col justify-center p-8 px-3 sm:px-8 lg:px-32">
          <>
            <h2 className="mb-14 text-[44px] text-[#3D3A3A] font-bold text-center">
              Reset Your Password
            </h2>
            <form className="flex flex-col gap-4">
              <TextField
                variant="outlined"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter New Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: "#B0B0B0" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? (
                          <VisibilityOff sx={{ color: "#3BC1CD" }} />
                        ) : (
                          <Visibility sx={{ color: "#3BC1CD" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    border: "1px solid #C4C4C4",
                    borderRadius: "10px",
                    padding: "0px 14px",
                    background: "#fff",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  input: {
                    color: "#333333",
                  },
                }}
              />
              <TextField
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm New Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: "#B0B0B0" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff sx={{ color: "#3BC1CD" }} />
                        ) : (
                          <Visibility sx={{ color: "#3BC1CD" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    border: "1px solid #C4C4C4",
                    borderRadius: "10px",
                    padding: "0px 14px",
                    background: "#fff",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                  input: {
                    color: "#333333",
                  },
                }}
              />
              {error && <p className="text-red-500 text-sm">{error}</p>}
              {apiError && <p className="text-red-500 text-sm">{apiError}</p>}
              <Button
                variant="contained"
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#3BC1CD",
                  fontSize: "16px",
                  padding: "12px 0px",
                  marginTop: "36px",
                }}
                fullWidth
                onClick={handleResetPassword}
                disabled={loading}
              >
                {loading ? "Resetting..." : "Reset Password"}
              </Button>
            </form>
          </>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
