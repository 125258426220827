import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Signin from "./pages/signin/index.tsx";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword.tsx";
import ResetPassword from "./pages/resetPassword/ResetPassword.tsx";
import UserManagement from "./components/dashboard/user-management/index.tsx";
import Dashboard from "./components/dashboard/dashboardPage/DashboardPage.tsx";
import RoomManagement from "./components/dashboard/room-management/RoomManagement.tsx";
import RoomDetails from "./components/dashboard/room-management/RoomDetails.tsx";
import TestimonialManagement from "./components/dashboard/testimonial-management/TestimonialManagement.tsx";
import TestimonialDetails from "./components/dashboard/testimonial-management/TestimonialDetails.tsx";
import AddNewTestimonial from "./components/dashboard/testimonial-management/AddNewTestimonial.tsx";
import BookingManagement from "./components/dashboard/booking-management/BookingManagement.tsx";
import Reservations from "./components/dashboard/Reservations/Reservations.tsx";
import ReservationDetails from "./components/dashboard/Reservations/ReservationDetails.tsx";
import SubAdminManagement from "./components/dashboard/subAdmin-management/SubAdminManagement.tsx";
import ImageManagement from "./components/dashboard/image-management/ImageManagement.tsx";
import Profile from "./components/dashboard/profile/Profile.tsx";
import AddSubAdmin from "./components/dashboard/subAdmin-management/AddSubAdmin.tsx";
import EditSubAdmin from "./components/dashboard/subAdmin-management/EditSubAdmin.tsx";
import Navbar from "./components/commonComponents/DashboardNavbar.tsx";
import Sidebar from "./components/commonComponents/DashboardSidebar.tsx";
import UserDetails from "./components/dashboard/user-management/UserDetails.tsx";
import BookingDetails from "./components/dashboard/booking-management/BookingDetails.tsx";
import EditUnit from "./components/dashboard/room-management/EditUnit.tsx";
import PageNotFound from "./components/PageNotFound.tsx";

// Static Content Management Components
import PrivacyPolicy from "./components/dashboard/content-management/PrivacyPolicy.tsx";
import FAQs from "./components/dashboard/content-management/FAQs.tsx";
import AboutUs from "./components/dashboard/content-management/AboutUs.tsx";
import TermsAndConditions from "./components/dashboard/content-management/TermsAndConditions.tsx";
import ContactUs from "./components/dashboard/content-management/ContactUs.tsx";
import { useMediaQuery } from "@mui/material";
import ProtectedRoute from "./ProtectedRoute.tsx"; // Import the ProtectedRoute component
import { useAppDispatch } from "./hooks/useAppDispatch.tsx";
import { fetchProfile } from "./redux/features/profile/profileSlice.tsx";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A9EDF3",
    },
  },
});

// PrivateRoute component to protect routes
const PrivateRoute = ({ element }) => {
  const isAuthenticated = Boolean(localStorage.getItem("token")); // Example: Check if user is authenticated
  return isAuthenticated ? element : <Navigate to="/signin" />;
};

// Layout for private routes
const PrivateLayout = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    isMobileScreen ? false : true
  );

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      {/* Content Area */}
      <main
        className={`
        flex-1 
        transition-all 
        duration-300 
        ease-in-out 
        overflow-y-auto 
      `}
      >
        {/* Navbar */}
        <Navbar toggleSidebar={toggleSidebar} />

        {/* Page Content */}
        <div className="p-1 md:p-4">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchProfile()); // Fetch profile if token exists
    }
  }, [dispatch]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Outlet />,
      children: [
        {
          path: "signin",
          element: <Signin />,
        },
        {
          index: true,
          element: <Signin />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password",
          element: <ProtectedRoute element={<ResetPassword />} />, //  ProtectedRoute
        },
      ],
    },
    {
      path: "/",
      element: <PrivateRoute element={<PrivateLayout />} />, // Protect routes with PrivateRoute
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "user-management",
          element: <UserManagement />,
        },
        {
          path: "/user-details/:id",
          element: <UserDetails />,
        },
        {
          path: "room-management",
          element: <RoomManagement />,
        },
        {
          path: "/room-details/:id",
          element: <RoomDetails />,
        },
        {
          path: "/room-management/edit-unit/:id",
          element: <EditUnit />,
        },
        {
          path: "testimonial-management",
          element: <TestimonialManagement />,
        },
        {
          path: "testimonial-management/add-newfeedback",
          element: <AddNewTestimonial />,
        },
        {
          path: "testimonial-management/testimonial-details/:id",
          element: <TestimonialDetails />,
        },
        {
          path: "booking-management",
          element: <BookingManagement />,
        },
        {
          path: "/booking-details/:id",
          element: <BookingDetails />,
        },
        {
          path: "reservations",
          element: <Reservations />,
        },
        {
          path: "/reservation-details/:id",
          element: <ReservationDetails />,
        },
        {
          path: "subadmin-management",
          element: <SubAdminManagement />,
        },
        {
          path: "subadmin-management/add-subadmin",
          element: <AddSubAdmin />,
        },
        {
          path: "subadmin-management/edit-subadmin/:id",
          element: <EditSubAdmin />,
        },
        {
          path: "image-management",
          element: <ImageManagement />,
        },
        // Static Content Management Routes
        {
          path: "static-content/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "static-content/faqs",
          element: <FAQs />,
        },
        {
          path: "static-content/about-us",
          element: <AboutUs />,
        },
        {
          path: "static-content/terms-and-conditions",
          element: <TermsAndConditions />,
        },
        {
          path: "static-content/contact-us",
          element: <ContactUs />,
        },

        // Add more private routes here
      ],
    },
    // Add the Not Found route
    {
      path: "/not-found",
      element: <PageNotFound />,
    },
    // Catch-all route for undefined paths
    {
      path: "*",
      element: <Navigate to="/not-found" />,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
