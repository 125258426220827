import axios from "axios";
import refreshAccessToken from "./refreshToken.tsx";

const apiClient = axios.create({
  baseURL: "https://aria-vacation-dev.appskeeper.in/api/v1", // Base URL
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("accessToken");
    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is 401 and if it's not a retry
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();

        // Update the Authorization header with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        // Retry the original request with the new token
        return apiClient(originalRequest);
      } catch (refreshError) {
        // Handle token refresh failure (e.g., logout user)
        return Promise.reject(refreshError);
      }
    }

    // If the error is not 401 or retry fails, reject the promise
    return Promise.reject(error);
  }
);

export default apiClient;
