import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUser } from "./userManagementThunk.tsx";
import { User, UserState, UserListResponse } from "./types.tsx";

// Initial state for the user management slice
const initialState: UserState = {
  users: [],
  loading: false,
  error: null,
  totalCount: 0,
  page: 1,
  limit: 10,
  nextPage: null,
  totalPages: 0,
};

// Create the user management slice
const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchUser.fulfilled,
        (state, action: PayloadAction<UserListResponse["data"]>) => {
          state.loading = false;
          state.users = action.payload.data || [];
          state.totalCount = action.payload.count;
          state.page = action.payload.page;
          state.limit = action.payload.limit;
          state.nextPage = action.payload.nextPage;
          state.totalPages = action.payload.totalPages;
        }
      )
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch users";
      });
  },
});

// Export actions if you have any synchronous actions
export const {} = userManagementSlice.actions;

// Export the reducer to be used in the store
export default userManagementSlice.reducer;
