import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../../utils/apiClient.tsx'; // Your common axios instance

export const fetchRoomDetails = createAsyncThunk(
  'roomDetails/fetchRoomDetails',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/room-management/detail?id=${id}`);
      return response.data.data; // Return only the room details
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);
