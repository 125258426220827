import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import apiClient from '../../../utils/apiClient.tsx';

interface SubAdminDetails {
  adminId: number;
  name: string;
  email: string;
  status: string;
  role: string;
  phoneNumber: string | null;
  createdAt: string;
  permissions: {
    viewModule: string[];
    editModule: string[];
  }[];
}

interface SubAdminState {
  data: SubAdminDetails | null;
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: SubAdminState = {
  data: null,
  loading: false,
  error: null,
};

// Async thunk to fetch sub-admin details
export const fetchSubAdminDetails = createAsyncThunk<SubAdminDetails, number>(
  'subAdmin/fetchSubAdminDetails',
  async (adminId, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/sub-admin/${adminId}`);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Slice
const subAdminDetailsSlice = createSlice({
  name: 'subAdminDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubAdminDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubAdminDetails.fulfilled, (state, action: PayloadAction<SubAdminDetails>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSubAdminDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default subAdminDetailsSlice.reducer;