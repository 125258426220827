// src/redux/features/profile/profileSlice.tsx
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";

interface ProfileState {
  data: any | null;
  isLoadingProfile: boolean;
  isUpdating: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string | null;
}

const initialState: ProfileState = {
  data: null,
  isLoadingProfile: false,
  isUpdating: false,
  isError: false,
  isSuccess: false,
  message: null,
};

// Async Thunk for fetching profile data
export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (_, thunkAPI) => {
    try {
      const response = await apiClient.get("/admin/profile");
      return response.data; // Return the API response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch profile"
      );
    }
  }
);

// update profile API
export const updatePhoneNumber = createAsyncThunk(
  "profile/updatePhoneNumber",
  async (phoneNumber: string, thunkAPI) => {
    try {
      const response = await apiClient.patch("/admin/profile", { phoneNumber });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to update phone number"
      );
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.isLoadingProfile = true;
        state.isError = false;
        state.message = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.isLoadingProfile = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.message = null;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.isLoadingProfile = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(updatePhoneNumber.pending, (state) => {
        state.isUpdating = true;
        state.isError = false;
        state.message = null;
      })
      .addCase(updatePhoneNumber.fulfilled, (state, action) => {
        state.isUpdating = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.message = "Phone number updated successfully";
      })
      .addCase(updatePhoneNumber.rejected, (state, action) => {
        state.isUpdating = false;
        state.isError = true;
        state.message = action.payload as string;
      });
  },
});

export default profileSlice.reducer;
