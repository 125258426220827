import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService.tsx";
import { toast } from "react-toastify";

interface AuthState {
  user: any | null;
  token: string | null;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string | null;
}

const initialState: AuthState = {
  user: null,
  token: localStorage.getItem("token") || null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: null,
};

// Async Thunk for login
export const loginUser = createAsyncThunk(
  "auth/login",
  async (userData: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await authService.login(userData);
      localStorage.setItem("token", response.data.accessToken); // Store token immediately
      return response; // Return the API response
    } catch (error: any) {
      // Check if the status code is 400
      if (error?.response?.status === 400) {
        // Use the message field for status 400
        const message = error?.response?.data?.message || "Login failed";
        return thunkAPI.rejectWithValue(message);
      }

      // Use the existing logic for other errors
      const message = error?.response?.data?.errors?.password || "Login failed";
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Auth Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
    },
    resetAuthState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        toast.success(action?.payload?.message);
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.token = action.payload.data.accessToken; // Assuming the response has accessToken
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
      });
  },
});

export const { logout, resetAuthState } = authSlice.actions;
export default authSlice.reducer;
