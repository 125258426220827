import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff, Email, Lock } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch.tsx";
import { useAppSelector } from "../../hooks/useAppSelector.tsx";
import {
  loginUser,
  resetAuthState,
} from "../../redux/features/auth/authSlice.tsx";
import { fetchProfile } from "../../redux/features/profile/profileSlice.tsx";
import { toast } from "react-toastify";

const RightPanel: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    password: false,
  });
  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, isError, isSuccess, message } = useAppSelector(
    (state) => state.auth
  );
  // console.log(message, "message");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,16}$/;

  // Handle autofill detection
  useEffect(() => {
    // Check for autofilled values on mount
    const checkAutofill = () => {
      const emailInput = document.querySelector(
        'input[type="email"]'
      ) as HTMLInputElement;
      const passwordInput = document.querySelector(
        'input[type="password"]'
      ) as HTMLInputElement;

      if (emailInput && emailInput.value) {
        setEmail(emailInput.value);
        setTouchedFields((prev) => ({ ...prev, email: true }));
      }

      if (passwordInput && passwordInput.value) {
        setPassword(passwordInput.value);
        setTouchedFields((prev) => ({ ...prev, password: true }));
      }
    };

    // Check immediately and after a short delay to catch different browser behaviors
    checkAutofill();
    setTimeout(checkAutofill, 100);
  }, []);

  // Validate form whenever inputs change
  useEffect(() => {
    if (email || password) {
      validateForm();
    }
  }, [email, password]);

  const validateForm = () => {
    let isValid = true;

    // Reset errors
    setEmailError("");
    setPasswordError("");

    // Email validation (always validate if there's a value)
    if (email || touchedFields.email) {
      if (!email) {
        setEmailError("Email is required");
        isValid = false;
      } else if (!emailRegex.test(email)) {
        setEmailError("Please enter a valid email address");
        isValid = false;
      }
    }

    // Password validation (always validate if there's a value)
    if (password || touchedFields.password) {
      if (!password) {
        setPasswordError("Password is required");
        isValid = false;
      } else {
        const errors: string[] = []; // Explicitly type the errors array

        if (password.length < 8) {
          errors.push("Must be at least 8 characters");
        }
        if (password.length > 16) {
          errors.push("not exceed 16 characters");
        }
        if (!/(?=.*[a-z])/.test(password)) {
          errors.push("contain a lowercase letter");
        }
        if (!/(?=.*[A-Z])/.test(password)) {
          errors.push("contain an uppercase letter");
        }
        if (!/(?=.*\d)/.test(password)) {
          errors.push("contain a number");
        }
        if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
          errors.push("contain a special character");
        }

        if (errors.length > 0) {
          setPasswordError(errors.join(", "));
          isValid = false;
        }
      }
    }

    const formIsValid = Boolean(
      isValid &&
        email &&
        password &&
        emailRegex.test(email) &&
        passwordRegex.test(password)
    );
    setIsFormValid(formIsValid);
    return isValid;
  };

  useEffect(() => {
    if (isSuccess || isError) {
      const timer = setTimeout(() => {
        dispatch(resetAuthState());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, isError, dispatch]);

  const handleSignIn = async () => {
    setTouchedFields({
      email: true,
      password: true,
    });

    if (validateForm()) {
      try {
        await dispatch(loginUser({ email, password })).unwrap();
        setIsProfileLoading(true);

        await dispatch(fetchProfile()).unwrap();
        navigate("/dashboard");
      } catch (error) {
        toast.error(error);
        console.error("Authentication failed:", error);
      } finally {
        setIsProfileLoading(false);
      }
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setTouchedFields((prev) => ({ ...prev, email: true }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setTouchedFields((prev) => ({ ...prev, password: true }));
  };

  const handleBlur = (field: "email" | "password") => {
    setTouchedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  // Handle form submission on Enter key
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && isFormValid && !isLoading) {
      handleSignIn();
    }
  };

  return (
    <div className="flex flex-col justify-center p-8 px-3 sm:px-8 lg:px-32 h-full">
      <h2 className="text-[44px] font-bold mb-4 text-center">Admin Sign In</h2>
      <p className="text-xl mb-6 text-center text-[#9DA4AE]">
        Sign in with your Email ID
      </p>
      {isProfileLoading ? (
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : (
        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            if (isFormValid && !isLoading) {
              handleSignIn();
            }
          }}
        >
          <TextField
            variant="outlined"
            type="email"
            value={email}
            onChange={handleEmailChange}
            onBlur={() => handleBlur("email")}
            onKeyPress={handleKeyPress}
            error={!!emailError}
            helperText={emailError}
            placeholder="Your Email"
            autoComplete="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email style={{ color: "#B0B0B0" }} />
                </InputAdornment>
              ),
              style: {
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
                padding: "0px 14px",
                background: "#FFF",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              input: {
                color: "#333333",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "16px",
              },
            }}
          />

          <TextField
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            onBlur={() => handleBlur("password")}
            onKeyPress={handleKeyPress}
            error={!!passwordError}
            helperText={passwordError}
            placeholder="Your Password"
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock style={{ color: "#B0B0B0" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordToggle}>
                    {showPassword ? (
                      <VisibilityOff style={{ color: "#3BC1CD" }} />
                    ) : (
                      <Visibility style={{ color: "#3BC1CD" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
                padding: "0px 14px",
                background: "#fff",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              input: {
                color: "#333333",
              },
              "& .MuiFormHelperText-root": {
                fontSize: "16px",
                whiteSpace: "pre-line",
              },
            }}
          />

          <FormControlLabel
            control={<Checkbox sx={{ color: "#B0B0B0" }} />}
            label={<span style={{ color: "#B0B0B0" }}>Remember me</span>}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{
              color: "#fff",
              backgroundColor: "#3BC1CD",
              padding: "12px 0px",
              fontSize: "16px",
              "&.Mui-disabled": {
                backgroundColor: "#B0B0B0",
                color: "#fff",
              },
            }}
            color="primary"
            onClick={handleSignIn}
            disabled={!isFormValid || isLoading}
            startIcon={
              isLoading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            {isLoading ? "Signing In..." : "Sign In"}
          </Button>
        </form>
      )}

      <Link
        to="/forgot-password"
        className="text-black mt-4 text-center text-base"
      >
        Forgot Password?
      </Link>
    </div>
  );
};

export default RightPanel;
