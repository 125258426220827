import React, { useState, ChangeEvent } from "react";
import {
  TextField,
  Switch,
  Button,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store.tsx";
import { createSubAdmin } from "../../../redux/features/subAdmin/addSubAdminSlice.tsx";
import { toast } from "react-toastify";

interface FormValues {
  name: string;
  email: string;
  password: string;
}

interface Permission {
  view: boolean;
  edit: boolean;
}

const AddSubAdmin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { loading, error, successMessage } = useSelector(
    (state: RootState) => state.addSubAdmin
  );

  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [permissions, setPermissions] = useState<Record<string, Permission>>({
    Dashboard: { view: false, edit: false },
    "User Management": { view: false, edit: false },
    "Room Management": { view: false, edit: false },
    "Testimonial Management": { view: false, edit: false },
    "Booking Management": { view: false, edit: false },
    "Sub-Admin Management": { view: false, edit: false },
    "Content Management": { view: false, edit: false },
  });

  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "email") {
      setEmailError(validateEmail(value));
    } else if (name === "password") {
      setPasswordError(validatePassword(value));
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggle = (key: string, type: "view" | "edit") => {
    setPermissions((prev) => {
      const currentPermission = prev[key];

      if (type === "edit") {
        return {
          ...prev,
          [key]: {
            view: true,
            edit: !currentPermission.edit,
          },
        };
      } else if (type === "view") {
        return {
          ...prev,
          [key]: {
            view: !currentPermission.view,
            edit: currentPermission.view ? false : currentPermission.edit,
          },
        };
      }

      return prev;
    });
  };

  const validateEmail = (email: string): string | null => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? null : "Invalid email format.";
  };

  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (password.length > 16) {
      return "Not exceed 16 characters";
    }
    if (!/\d/.test(password)) {
      return "Password must contain at least one number.";
    }

    if (!/(?=.*[A-Z])/.test(password)) {
      return "Contain an uppercase letter";
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return "Password must contain at least one special character.";
    }
    return null;
  };

  const handleSubmit = async () => {
    if (emailError || passwordError) {
      return;
    }

    if (
      !formValues.name.trim() ||
      !formValues.email.trim() ||
      !formValues.password.trim()
    ) {
      toast.error("Please fill in all fields before submitting.");
      return;
    }

    const hasPermission = Object.values(permissions).some(
      (permission) => permission.view || permission.edit
    );

    if (!hasPermission) {
      toast.error("Please select at least one access permission.");
      return;
    }

    const viewModule: string[] = [];
    const editModule: string[] = [];

    Object.entries(permissions).forEach(([key, value]) => {
      const formattedKey = key.toLowerCase().replace(" ", "-");
      if (value.view) viewModule.push(formattedKey);
      if (value.edit) editModule.push(formattedKey);
    });

    try {
      const result = await dispatch(
        createSubAdmin({
          name: formValues.name,
          email: formValues.email,
          password: formValues.password,
          viewModule,
          editModule,
        })
      ).unwrap();
      toast.success(result?.data?.message);

      setFormValues({
        name: "",
        email: "",
        password: "",
      });

      setPermissions({
        Dashboard: { view: false, edit: false },
        "User Management": { view: false, edit: false },
        "Room Management": { view: false, edit: false },
        "Testimonial Management": { view: false, edit: false },
        "Booking Management": { view: false, edit: false },
        "Sub-Admin Management": { view: false, edit: false },
        "Content Management": { view: false, edit: false },
      });

      navigate(-1);
    } catch (error: any) {
      if (error.statusCode === 400 && error.message === "EMAIL_EXISTS") {
        toast.error(
          "This email address is already in use. Please use a different email."
        );
      } else {
        toast.error(error.message || "An unexpected error occurred.");
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const isSubmitDisabled =
    !formValues.name.trim() ||
    !formValues.email.trim() ||
    !formValues.password.trim() ||
    !!emailError || // Ensure this is a boolean
    !!passwordError || // Ensure this is a boolean
    !Object.values(permissions).some(
      (permission) => permission.view || permission.edit
    );

  // Ensure loading is a boolean
  const isLoading = Boolean(loading);

  return (
    <div className="p-6 w-full md:w-[70%]">
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ marginBottom: "34px", color: "#004135" }}
      >
        <Button
          variant="text"
          onClick={handleGoBack}
          sx={{ fontWeight: "normal" }}
        >
          <ArrowBackIosIcon />
        </Button>
        Add New Sub-Admin{" "}
      </Typography>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <TextField
          required
          label="Name"
          variant="outlined"
          fullWidth
          placeholder="Enter Full Name"
          name="name"
          value={formValues.name}
          onChange={handleInputChange}
          InputProps={{
            style: {
              borderRadius: "8px",
            },
          }}
        />

        <TextField
          required
          label="Email"
          variant="outlined"
          fullWidth
          placeholder="Enter Email"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
          error={!!emailError}
          helperText={emailError}
          InputProps={{
            style: {
              borderRadius: "8px",
            },
          }}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <TextField
          required
          label="Password"
          variant="outlined"
          fullWidth
          type={showPassword ? "text" : "password"}
          placeholder="Enter Password"
          name="password"
          value={formValues.password}
          onChange={handleInputChange}
          error={!!passwordError}
          helperText={passwordError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handlePasswordToggle}>
                  {showPassword ? (
                    <VisibilityOff style={{ color: "#3BC1CD" }} />
                  ) : (
                    <Visibility style={{ color: "#3BC1CD" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              borderRadius: "8px",
            },
          }}
        />
      </div>
      <div className="border rounded-lg bg-[#F8F8F8] rounded-xl">
        <div className="bg-[#3BC1CD82] p-4 grid grid-cols-3  pb-2  font-bold rounded-t-xl ">
          <span>Permission</span>
          <span>View</span>
          <span>Edit</span>
        </div>

        {Object.keys(permissions).map((key) => (
          <div
            key={key}
            className="grid grid-cols-3 items-center p-4 border-b last:border-0"
          >
            <span>{key}</span>
            <Switch
              checked={permissions[key].view}
              onChange={() => handleToggle(key, "view")}
              color="primary"
            />
            <Switch
              checked={permissions[key].edit}
              onChange={() => handleToggle(key, "edit")}
              color="primary"
            />
          </div>
        ))}
      </div>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}>
        <Button
          variant="outlined"
          sx={{
            borderColor: "#C4C4C4",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: 700,
            px: 7,
            py: 1.5,
            color: "#3D3A3A",
          }}
          onClick={handleGoBack}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            color: "#fff",
            bgcolor: "#3BC1CD",
            fontSize: "16px",
            fontWeight: 700,
            px: 7,
            py: 1.5,
          }}
          onClick={handleSubmit}
          disabled={isLoading || isSubmitDisabled}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </Button>
      </Box>
    </div>
  );
};

export default AddSubAdmin;
