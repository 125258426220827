import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice.tsx";
import profileReducer from "./features/profile/profileSlice.tsx";
import imageManagementReducer from "./features/imageManagement/imageManagementSlice.tsx";
import getImagesReducer from "./features/imageManagement/getUploadedImagesSlice.tsx";
import roomManagementReducer from "./features/roomManagement/roomManagementSlice.tsx";
import roomDetailsReducer from "./features/roomManagement/roomDetailsSlice.tsx";
import amenitiesReducer from "./features/roomManagement/amenitiesSlice.tsx";
import userManagementReducer from "./features/userManagement/userManagementSlice.tsx";
import bookingRequestManagementReducer from "./features/booking/bookingSlice.tsx";
import userDetailsReducer from "./features/userManagement/userDetailsSlice.tsx";
import autoDebitReducer from "./features/userManagement/autoDebitSlice.tsx";
import reservationsReducer from "./features/reservations/reservationsSlice.tsx";
import toggleStatusReducer from "./features/masterToggle/masterToggleSlice.tsx";
import contentReducer from "./features/contentManagement/contentSlice.tsx";
import faqReducer from "./features/faq/faqSlice.tsx";
import testimonialListReducer from "./features/testimonialManagement/testimonialListSlice.tsx";
import testimonialDetailsReducer from "./features/testimonialManagement/testimonialDetailsSlice.tsx";
import testimonialStatusReducer from "./features/testimonialManagement/testimonialStatusSlice.tsx";
import testimonialCreationReducer from "./features/testimonialManagement/addNewTestimonial.tsx";
import forgotPasswordReducer from "./features/forgot-reset-Password/forgotPasswordSlice.tsx";
import resetPasswordReducer from "./features/forgot-reset-Password/resetPasswordSlice.tsx";
import changePassReducer from "./features/forgot-reset-Password/changePasswordSlice.tsx";
import subAdminReducer from "./features/subAdmin/subAdminSlice.tsx";
import addSubAdminReducer from "./features/subAdmin/addSubAdminSlice.tsx";
import subAdminDetailsReducer from "./features/subAdmin/subAdminDetailsSlice.tsx";
import notificationReducer from "./features/notification/notificationSlice.tsx";
import notificationCountReducer from "./features/notification/notificationCountSlice.tsx";


export const store = configureStore({
  reducer: {
    auth: authReducer,
    changePass: changePassReducer,
    profile: profileReducer,
    imageManagement: imageManagementReducer,
    imageList: getImagesReducer,
    roomManagement: roomManagementReducer,
    roomDetails: roomDetailsReducer,
    amenities: amenitiesReducer,
    userManagement: userManagementReducer,
    userDetails: userDetailsReducer,
    autoDebit: autoDebitReducer,
    bookings: bookingRequestManagementReducer,
    reservations: reservationsReducer,
    toggleStatus: toggleStatusReducer,
    content: contentReducer,
    faqs: faqReducer,
    testimonialList: testimonialListReducer,
    testimonialDetails: testimonialDetailsReducer,
    testimonialStatus: testimonialStatusReducer,
    testimonialCreation: testimonialCreationReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    subAdmin: subAdminReducer,
    addSubAdmin: addSubAdminReducer,
    subAdminDetails: subAdminDetailsReducer,
    notifications: notificationReducer,
    notificationCount: notificationCountReducer,


  },
});

// Define RootState type
export type RootState = ReturnType<typeof store.getState>;

// Define AppDispatch type
export type AppDispatch = typeof store.dispatch;
