import React from "react";
import {
  DataGrid,
  GridPaginationModel,
  GridColDef,
  GridEventListener,
} from "@mui/x-data-grid";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";

interface DataTableProps {
  rows: any[];
  columns: GridColDef[];
  searchPlaceholder?: string;
  filterable?: boolean;
  pageSizeOptions?: number[];
  onRowClick?: (row: any) => void;
  filterComponent?: React.ReactNode;
  customAction?: React.ReactNode;
  masterToggle?: React.ReactNode;
  onSearch?: (searchKey: string) => void;
  loading?: boolean;
  // Pagination props
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  rowCount: number;
  paginationMode?: "server" | "client";
  disableRowSelectionOnClick
  
}

const DataTable: React.FC<DataTableProps> = ({
  rows,
  columns,
  searchPlaceholder = "Search...",
  filterable = false,
  pageSizeOptions = [10, 25, 50],
  filterComponent,
  onRowClick,
  customAction,
  masterToggle,
  onSearch,
  loading = false,
  paginationModel,
  onPaginationModelChange,
  rowCount,
  paginationMode = "server",
  disableRowSelectionOnClick
}) => {
  const [search, setSearch] = React.useState<string>("");

  const debouncedSearch = React.useMemo(
    () =>
      debounce((searchKey: string) => {
        if (onSearch) {
          onSearch(searchKey);
        }
      }, 300),
    [onSearch]
  );

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKey = event.target.value;
    setSearch(searchKey);
    debouncedSearch(searchKey);
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    if (onRowClick) onRowClick(params.row);
  };

  return (
    <div style={{ height: 700 }}>
      <div className="flex flex-col-reverse lg:flex-row mb-4 justify-between items-end md:items-center gap-3">
        <div className="flex w-full md:w-[70%]">
          <TextField
            variant="outlined"
            placeholder={searchPlaceholder}
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                border: "1px solid #C4C4C4",
                borderRadius: "10px",
                padding: "0px 14px",
                background: "#FFF",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              input: {
                color: "#333333",
                padding: "10px 0px",
              },
            }}
            style={{ paddingRight: "10px", width: "100%", maxWidth: "450px" }}
          />
          {filterable && filterComponent}
        </div>
        {masterToggle}
        {customAction}
      </div>

      <DataGrid
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={pageSizeOptions}
        rowCount={rowCount}
        paginationMode={paginationMode}
        loading={loading}
        disableColumnMenu
        disableColumnSorting
        disableRowSelectionOnClick
        sx={{
          borderRadius: "14px",
          overflow: "hidden",
          "& .MuiDataGrid-columnHeaders": {
            borderTopLeftRadius: "14px",
            borderTopRightRadius: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            borderBottomLeftRadius: "14px",
            borderBottomRightRadius: "14px",
          },
        }}
        localeText={{ 
          noRowsLabel: "No Data Found",
          footerTotalRows: "Total Records:",
        }}
      />
    </div>
  );
};

export default DataTable;