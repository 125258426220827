import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../../utils/apiClient.tsx";

interface ToggleStatusState {
  pageStatus: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: ToggleStatusState = {
  pageStatus: false,
  loading: false,
  error: null,
};

interface UpdateToggleParams {
  pageStatus: boolean;
  type: string;
}

export const fetchToggleStatus = createAsyncThunk(
  "roomStatus/fetchStatus",
  async (type: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/content-management/page-status`, {
        params: { type },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch room status"
      );
    }
  }
);

export const updateToggleStatus = createAsyncThunk(
  "toggleStatus/updateStatus",
  async ({ pageStatus, type }: UpdateToggleParams, { rejectWithValue }) => {
    try {
      await apiClient.patch(`content-management/page-status`, {
        type,
        pageStatus,
      });
      // Return the pageStatus we sent to update the state
      return { pageStatus };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update room status"
      );
    }
  }
);

const masterToggleSlice = createSlice({
  name: "toggleStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch toggle status
    builder.addCase(fetchToggleStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchToggleStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.pageStatus = action.payload.data.pageStatus;
    });
    builder.addCase(fetchToggleStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // Update toggle status
    builder.addCase(updateToggleStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateToggleStatus.fulfilled, (state, action) => {
      state.loading = false;
      // Update state with the value we sent
      state.pageStatus = action.payload.pageStatus;
    });
    builder.addCase(updateToggleStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default masterToggleSlice.reducer;
