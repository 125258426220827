import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../dataTable/index.ts";
import {
  Switch,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GridColDef } from "@mui/x-data-grid";
import { useMediaQuery, useTheme } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AppDispatch, RootState } from "../../../redux/store.tsx";
import {
  fetchSubAdmins,
  updateSubAdminStatus,
} from "../../../redux/features/subAdmin/subAdminSlice.tsx";
import dayjs from "dayjs";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CommonConfirmationModal from "../../commonComponents/CommonConfirmationModal.tsx";
import CommonFilter from "../../commonComponents/CommonFilter.tsx";
import { FetchSubAdminsParams } from "../../../redux/features/subAdmin/subAdminSlice.tsx";
import { toast } from "react-toastify";

interface FilterParams {
  selectedStatuses: string[];
  fromDate?: string;
  toDate?: string;
}

const SubAdminManagement = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [sortedColumn, setSortedColumn] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch<AppDispatch>();
  const {
    data: fetchedSubAdmin,
    loading,
    count,
    error,
  } = useSelector((state: RootState) => state.subAdmin);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    selectedStatuses: [],
    fromDate: undefined,
    toDate: undefined,
  });

  const subAdminStatusOptions = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
  ];

  const handlePaginationModelChange = async (model: {
    page: number;
    pageSize: number;
  }) => {
    const newPage = model.page + 1;
    const newPageSize = model.pageSize;

    setPage(newPage);
    setPageSize(newPageSize);

    const fetchParams: FetchSubAdminsParams = {
      page: newPage,
      limit: newPageSize,
      searchKey: searchKey || undefined,
      status: filterParams.selectedStatuses,
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }

    await dispatch(fetchSubAdmins(fetchParams));
  };

  // Function to handle filter submission
  const handleFilterSubmit = async (filters: FilterParams) => {
    setFilterParams(filters);

    const fetchParams: FetchSubAdminsParams = {
      page: 1,
      limit: pageSize,
      searchKey: searchKey || undefined,
      status: filters.selectedStatuses,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    };

    if (sortedColumn && sortOrder) {
      fetchParams.sortBy = sortedColumn;
      fetchParams.sortOrder = sortOrder;
    }
    console.log(fetchParams, "fetchParams");

    await dispatch(fetchSubAdmins(fetchParams));
    setPage(1);
  };

  const rows = useMemo(() => {
    return fetchedSubAdmin.map((user, index) => ({
      ...user,
      id: user.adminId,
      serialNumber: (page - 1) * pageSize + index + 1, // Calculate the serial number
    }));
  }, [fetchedSubAdmin]); // This will update whenever fetchedUsers changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchSubAdmins({ page, limit: pageSize, searchKey }));
      } catch (error) {
        console.error("Error fetching SubAdmin list:", error);
      }
    };
    fetchData();
  }, [dispatch, page, pageSize, searchKey]);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleSearch = (newSearchKey: string) => {
    setSearchKey(newSearchKey);
    setPage(1); // Reset to first page when searching
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = (event, params: any) => {
    handleMenuClose();
    navigate(`/subadmin-management/edit-subadmin/${params.adminId}`, {
      state: { subAdminDetails: params.adminId },
    });
  };

  const handleDelete = (event, params: any) => {
    event.stopPropagation();
    setSelectedRow(params);
    setAnchorEl(null);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!selectedRow) return;
    setDeleting(true);
    try {
      await dispatch(
        updateSubAdminStatus({
          adminId: selectedRow.adminId,
          status: "DELETED",
        })
      ).unwrap();
      toast.success("Sub-admin Deleted successfully");
    } catch (error) {
      console.error("Failed to delete subadmin:", error);
      toast.error(error?.message);
    } finally {
      await dispatch(fetchSubAdmins({ page, limit: pageSize, searchKey }));

      setDeleting(false);
      setModalOpen(false);
    }
  };

  const handleToggleChange = async (row: any) => {
    const newStatus = row.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    try {
      await dispatch(
        updateSubAdminStatus({
          adminId: row.adminId,
          status: newStatus,
        })
      ).unwrap();
      // Re-fetch data after updating status
      dispatch(fetchSubAdmins({ page, limit: pageSize, searchKey }));
    } catch (error) {
      toast.error(error.message)
      console.error("Failed to update status:", error);
    }
  };

  const handleHeaderIconClick = (columnField: string) => {
    const nextSortOrder =
      columnField === sortedColumn
        ? sortOrder === "ASC"
          ? "DESC"
          : sortOrder === "DESC"
          ? ""
          : "ASC"
        : "ASC";

    setSortedColumn(nextSortOrder === "" ? "" : columnField);
    setSortOrder(nextSortOrder as "ASC" | "DESC" | "");

    // Create properly typed sort parameters
    const sortParams = nextSortOrder
      ? {
          sortBy: columnField,
          sortOrder: nextSortOrder as "ASC" | "DESC",
        }
      : {};

    dispatch(
      fetchSubAdmins({
        page,
        limit: pageSize,
        ...sortParams,
        searchKey: searchKey || undefined,
      })
    );
  };

  const getSortIcon = (
    columnField: string,
    sortedColumn: string,
    sortOrder: string
  ) => {
    if (columnField === sortedColumn) {
      if (sortOrder === "ASC") return <NorthIcon />;
      if (sortOrder === "DESC") return <SouthIcon />;
    }
    return <ImportExportIcon />;
  };

  const handleNameClick = (params) => {
    navigate(`/subadmin-management/edit-subadmin/${params.adminId}`, {
      state: { subAdminDetails: params.adminId },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: "S.no",
      flex: isMobileScreen ? 1 : 0.5,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 80 : undefined, // Ensure minimum width on mobile
      valueGetter: (params) => params, // Use the calculated serial number
      renderCell: (params) => <span>{params.row.serialNumber}</span>, // Display the serial number
    },
    {
      field: "name",
      headerName: "Name",
      flex: isMobileScreen ? 1.5 : 1, // Slightly expanded on mobile
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 120 : undefined,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>User Name</span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleHeaderIconClick("name");
            }}
            aria-label="sort-toggle"
            size="small"
            sx={{
              color:
                sortedColumn === "name" && sortOrder !== "" ? "black" : "black",
              "&:hover": {
                backgroundColor: "#3bc1cd",
                color: "white",
              },
            }}
          >
            {getSortIcon("name", sortedColumn, sortOrder)}
          </IconButton>
        </div>
      ),
      renderCell: (params) => {
        const isDeleted = params.row.status === "DELETED";

        return (
          <span
            style={{
              textDecoration: isDeleted ? "none" : "underline",
              fontSize: "14px",
              fontWeight: "700",
              cursor: isDeleted ? "default" : "pointer",
              color: isDeleted ? "gray" : "#3BC1CD",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              if (!isDeleted) {
                handleNameClick(params.row);
              }
            }}
          >
            {params.value}
          </span>
        );
      },
    },

    {
      field: "email",
      headerName: "Email Address",
      flex: isMobileScreen ? 2 : 1.5, // More space on mobile
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 180 : undefined,
    },
    {
      field: "createdAt",
      headerName: "Added on",
      flex: isMobileScreen ? 1.5 : 1, // Slightly expanded on mobile
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 110 : undefined,
      valueGetter: (params) => {
        return params;
      },
      renderCell: (params) => {
        try {
          if (!params.value) return "";
          // Format the date using dayjs
          const formattedDate = dayjs(params.value).format("MMM DD, YYYY");
          return formattedDate;
        } catch (error) {
          console.error("Error formatting date:", error);
          return params.value; // Return raw value if formatting fails
        }
      },
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Joined On</span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleHeaderIconClick("createdAt");
            }}
            aria-label="sort-toggle"
            size="small"
            sx={{
              color:
                sortedColumn === "createdAt" && sortOrder !== ""
                  ? "black"
                  : "black",
              "&:hover": {
                backgroundColor: "#3bc1cd",
                color: "white",
              },
            }}
          >
            {getSortIcon("createdAt", sortedColumn, sortOrder)}
          </IconButton>
        </div>
      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: isMobileScreen ? 1.5 : 1, // Slightly expanded on mobile
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 100 : undefined,
    },
    {
      field: "status",
      headerName: "Status",
      flex: isMobileScreen ? 1.5 : 1, // Slightly expanded on mobile
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 100 : undefined,
      renderCell: (params) => (
        <span
          style={{
            borderRadius: "24px",
            padding: "4px 8px",
            backgroundColor: params.value === "ACTIVE" ? "#52F0BE" : "#F0F0F0",
            color: params.value === "ACTIVE" ? "#0B6067" : "#9DA4AE",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      flex: isMobileScreen ? 2 : 1,
      headerClassName: "column-header-bg-green",
      minWidth: isMobileScreen ? 150 : undefined,
      renderCell: (params) => (
        <div className="flex items-center gap-4">
          {/* Toggle Switch */}
          <Switch
            checked={params.row.status === "ACTIVE"}
            onChange={() => handleToggleChange(params.row)}
            color="primary"
            disabled={params.row.status === "DELETED"} // Disable switch if status is DELETED
          />
          {/* Three-dot Button */}
          <IconButton
            onClick={(event) => handleMenuOpen(event, params.row)}
            className="focus:outline-none"
            disabled={params.row.status === "DELETED"}
          >
            <MoreVertIcon />
          </IconButton>
          {/* Popover Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && selectedRow?.adminId === params.row.id}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem onClick={(event) => handleEdit(event, params.row)}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem onClick={(event) => handleDelete(event, params.row)}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" style={{ color: "red" }} />
              </ListItemIcon>
              <ListItemText style={{ color: "red" }}>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 ">
      <DataTable
        rows={rows}
        columns={columns}
        searchPlaceholder="Search by Name or Email"
        onSearch={handleSearch}
        filterable
        filterComponent={
          <CommonFilter
            statusOptions={subAdminStatusOptions}
            isMobileScreen={isMobileScreen}
            onFilterSubmit={handleFilterSubmit}
            currentFilters={filterParams}
          />
        }
        loading={loading}
        // Pagination props
        paginationModel={{
          page: page - 1, // Convert to 0-based for DataGrid
          pageSize,
        }}
        pageSizeOptions={[10, 25, 50]}
        rowCount={count || 0}
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        disableRowSelectionOnClick
        customAction={
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/subadmin-management/add-subadmin")}
          >
            {!isMobileScreen ? "Add New Subadmin" : <PersonAddIcon />}
          </Button>
        }
      />
      <CommonConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Confirm Deletion"
        description="Are you sure you want to delete this Sub-Admin?"
        confirmButtonText="Yes, Delete"
        onConfirm={confirmDelete}
        loading={deleting}
      />
    </div>
  );
};

export default SubAdminManagement;
