import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

interface ProtectedRouteProps {
  element: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  return token ? <>{element}</> : <Navigate to="/signin" />;
};

export default ProtectedRoute;