import axios from "axios";
import { toast } from "react-toastify";

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  try {
    const response = await axios.get(
      "https://aria-vacation-dev.appskeeper.in/api/v1/admin/refresh/token",
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );

    // Check if the response indicates an unauthorized error
    if (response.data.statusCode === 401) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      setTimeout(() => {
        window.location.href = "/signin"; // Redirect to the sign-in page
      }, 3000); // 3 seconds delay
      return;
    }

    const { accessToken, refreshToken: newRefreshToken } = response.data.data;

    // Update tokens in localStorage
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return accessToken;
  } catch (error) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    toast.success("Your session has expired, please login");

    setTimeout(() => {
      window.location.href = "/signin"; // Redirect to the sign-in page
    }, 3000); // 3 seconds delay
    throw error; // Optionally rethrow the error if needed
  }
};

export default refreshAccessToken;
