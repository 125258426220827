import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../../utils/apiClient.tsx";

export interface FAQ {
  contentId: number;
  questionEn: string;
  answerEn: string;
  type: number;
}

interface FAQState {
  faqs: FAQ[];
  isLoading: boolean;
  error: string | null;
}

const initialState: FAQState = {
  faqs: [],
  isLoading: false,
  error: null,
};

// Thunk to fetch FAQs based on type
export const fetchFAQs = createAsyncThunk(
  "faqs/fetchFAQs",
  async (type: number, thunkAPI) => {
    try {
      const response = await apiClient.get(`/faqs?type=${type}`);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch FAQs");
    }
  }
);

// Thunk to add a new FAQ
export const addFAQ = createAsyncThunk(
  "faqs/addFAQ",
  async ({ type, questionEn, answerEn }: Omit<FAQ, "contentId">, thunkAPI) => {
    try {
      const response = await apiClient.post(`/faqs`, {
        type,
        questionEn,
        answerEn,
      });
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to add FAQ");
    }
  }
);

// Thunk to update a specific FAQ
export const updateFAQ = createAsyncThunk(
  "faqs/updateFAQ",
  async ({ contentId, questionEn, answerEn }: FAQ, thunkAPI) => {
    try {
      await apiClient.patch(`/faqs`, {
        contentId,
        questionEn,
        answerEn,
      });
      return { contentId, questionEn, answerEn };
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to update FAQ");
    }
  }
);

// Thunk to delete a specific FAQ
export const deleteFAQ = createAsyncThunk(
  "faqs/deleteFAQ",
  async (contentId: number, thunkAPI) => {
    try {
      await apiClient.delete(`/faqs/${contentId}`);
      return contentId;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to delete FAQ");
    }
  }
);

const faqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch FAQs
      .addCase(fetchFAQs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFAQs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faqs = action.payload;
      })
      .addCase(fetchFAQs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Add FAQ
      .addCase(addFAQ.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addFAQ.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faqs.push(action.payload);
      })
      .addCase(addFAQ.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Update FAQ
      .addCase(updateFAQ.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateFAQ.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedFaq = action.payload as FAQ;
        state.faqs = state.faqs.map((faq) =>
          faq.contentId === updatedFaq.contentId ? updatedFaq : faq
        );
      })
      .addCase(updateFAQ.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Delete FAQ
      .addCase(deleteFAQ.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteFAQ.fulfilled, (state, action) => {
        state.isLoading = false;
        state.faqs = state.faqs.filter(
          (faq) => faq.contentId !== action.payload
        );
      })
      .addCase(deleteFAQ.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default faqSlice.reducer;
