// testimonialListSlice.tsx (or similar slice file)
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from "../../../utils/apiClient.tsx";


interface Testimonial {
    testimonialId: number;
    title: string;
    userName: string;
    status: string;
  }
  

export const deleteTestimonial = createAsyncThunk(
  'testimonials/deleteTestimonial',
  async (testimonialId: number, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        `/testimonial-management/${testimonialId}`
      );
      if (response.data.statusCode !== 200) {
        return rejectWithValue(response.data.message);
      }
      return testimonialId;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);
const testimonialsSlice = createSlice({
    name: 'testimonials',
    initialState: {
      testimonials: [] as Testimonial[],
      loading: false,
      error: null as string | null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(deleteTestimonial.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(deleteTestimonial.fulfilled, (state, action) => {
          state.loading = false;
          state.testimonials = state.testimonials.filter(
            (testimonial) => testimonial.testimonialId !== action.payload
          );
        })
        .addCase(deleteTestimonial.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string; // Type assertion if needed
        });
    },
  });
  
export default testimonialsSlice.reducer;