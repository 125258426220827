import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://aria-vacation-dev.appskeeper.in/api/v1", // Base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an Axios request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");

    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export default apiClient;
