import apiClient from "../../../utils/apiClient.tsx";

interface LoginData {
  email: string;
  password: string;
}

const login = async (data: LoginData) => {
  const auth = process.env.REACT_APP_BASIC_AUTH;
  const response = await apiClient.post("/admin/login", data, {
    headers: {
      Authorization: `Basic ${auth}`,
    },
  });
  return response.data;
};

export default {
  login,
};
