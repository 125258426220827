import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Switch,
  styled,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchTestimonialDetails } from "../../../redux/features/testimonialManagement/testimonialDetailsSlice.tsx";
import { updateTestimonialStatus } from "../../../redux/features/testimonialManagement/testimonialStatusSlice.tsx";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 2,
    transform: "translateX(0)",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#3BC1CD",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18,
    height: 18,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 12,
    backgroundColor: "#E4E7EB",
    opacity: 1,
    position: "relative",
  },
}));

const TestimonialDetails: React.FC = () => {
  const [status, setStatus] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { details, loading, error } = useSelector(
    (state: RootState) => state.testimonialDetails
  );

  const testimonialId = location?.state?.testimonial;

  useEffect(() => {
    if (testimonialId) {
      dispatch(fetchTestimonialDetails(testimonialId));
    }
  }, [dispatch, testimonialId]);

  useEffect(() => {
    if (details?.status) {
      setStatus(details.status === "ACTIVE");
    }
  }, [details]);

  const handleToggleStatus = async () => {
    const newStatus = status ? "INACTIVE" : "ACTIVE";
    try {
      await dispatch(
        updateTestimonialStatus({ testimonialId, status: newStatus })
      ).unwrap();
      setStatus(!status); // Update local state only if the API call is successful
    } catch (error) {
      console.error("Failed to update testimonial status:", error);
    }
  };

  const handleGoBack = () => {
    navigate("/testimonial-management");
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth(); // Months are zero-indexed
    const year = date.getUTCFullYear();

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[monthIndex];

    return `${day} ${month} ${year}`;
  }

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (error) return <p>{error}</p>;
  if (!details) return <p>No details found</p>;

  return (
    <div className="p-6">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: "15px", md: "5px" },
          justifyContent: { xs: "flex-start", md: "space-between" },
          alignItems: { xs: "flex-start", md: "center" },
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "500", fontSize: "24px", color: "#004135" }}
        >
          <Button
            variant="text"
            onClick={handleGoBack}
            sx={{ fontWeight: "normal" }}
          >
            <ArrowBackIosIcon />
          </Button>
          Testimonial Details
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "#3D3A3A", fontSize: "16px", fontWeight: "400" }}
          >
            Mark As {status ? "Inactive" : "Active"}
          </Typography>
          <CustomSwitch
            checked={status}
            onChange={handleToggleStatus}
            color="error"
          />
        </Box>
      </Box>
      <Paper
        sx={{
          padding: "20px",
          borderRadius: "16px",
          border: "1px solid #E0E0E0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              variant="body2"
              sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
            >
              Title{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "400",
                color: "#3D3A3A",
                fontSize: { xs: "19px", md: "24px" },
              }}
            >
              {details?.title}{" "}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: status ? "#52F0BE" : "#F0F0F0",
              color: status ? "#0B6067" : "#9DA4AE",
              fontWeight: "700",
              borderRadius: "24px",
              padding: "8px 12px",
              fontSize: "14px",
            }}
          >
            {status ? "Active" : "Inactive"}
          </Box>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            User Name
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "400",
              color: "#3D3A3A",
              fontSize: { xs: "19px", md: "24px" },
            }}
          >
            {details?.name}{" "}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            Unit Name
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "400",
              color: "#3D3A3A",
              fontSize: { xs: "19px", md: "24px" },
            }}
          >
            1 Studio Apartment
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            Associated With
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "400",
              color: "#3D3A3A",
              fontSize: { xs: "19px", md: "24px" },
            }}
          >
            {details?.propertyName}{" "}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            Added On
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "400",
              color: "#3D3A3A",
              fontSize: { xs: "19px", md: "24px" },
            }}
          >
            {formatDate(details?.createdAt)}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography
            variant="body2"
            sx={{ color: "#979797", marginBottom: "5px", fontSize: "16px" }}
          >
            Description
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "400",
              color: "#3D3A3A",
              fontSize: { xs: "19px", md: "24px" },
              wordWrap: "break-word", // Ensures long words are broken and wrapped
              overflowWrap: "break-word", // For older browsers
              width: "100%", // Ensure the Typography takes the full width of its container
            }}
          >
            {details?.description}
          </Typography>
        </Box>
      </Paper>
    </div>
  );
};

export default TestimonialDetails;
