// amenitiesSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiClient from '../../../utils/apiClient.tsx';

interface Amenity {
  amenityId: number;
  title: string;
}

interface AmenitiesState {
  amenities: Amenity[];
  loading: boolean;
  error: string | null;
}

const initialState: AmenitiesState = {
  amenities: [],
  loading: false,
  error: null,
};

export const fetchAmenities = createAsyncThunk(
  'amenities/fetchAmenities',
  async ({ page, limit }: { page: number; limit: number }) => {
    const response = await apiClient.get(`/room-management/amenity-list?page=${page}&limit=${limit}`
    );
    return response.data.data; // Adjust based on actual API response structure
  }
);

const amenitiesSlice = createSlice({
  name: 'amenities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmenities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAmenities.fulfilled, (state, action) => {
        state.loading = false;
        state.amenities = action.payload.data; // Adjust based on API response structure
      })
      .addCase(fetchAmenities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch amenities';
      });
  },
});

export default amenitiesSlice.reducer;