import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiClient from "../../../utils/apiClient.tsx";

interface EditUnitPayload {
  id: number;
  amenityIds: number[];
  description: string;
  images: string[];
  features: string[];
}

export const editUnit = createAsyncThunk(
  "roomManagement/editUnit",
  async (unitDetails: EditUnitPayload, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(
        `/room-management/edit`,
        unitDetails
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);
