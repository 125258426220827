import { createSlice } from "@reduxjs/toolkit";
import { fetchUserDetails } from "./userManagementThunk.tsx";

interface UserDetails {
  user: any;
  membership?: any;
  nameId: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  nationality: string;
  status: string;
  address: string | null;
  createdDate: string;
}

interface ErrorDetails {
  status?: number;
  message: string;
}

interface UserManagementState {
  users: any[];
  userDetails: UserDetails | null;
  loading: boolean;
  error: ErrorDetails | null; // Update the type here
}

// Adjust the initial state
const initialState: UserManagementState = {
  users: [],
  userDetails: null,
  loading: false,
  error: null,
};

const userManagementSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload.data;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.error = action.payload as ErrorDetails;
        } else {
          state.error = {
            message: action.error.message || "Failed to fetch booking details",
          };
        }
      });
  },
});

export default userManagementSlice.reducer;